<template>
  <span>
    <template v-for="translation in translations" :key="translation.locale">
      <form-row :title="`${fieldLabel} ${LOCALE_FLAGS[translation.locale] || translation.locale} `">
        <nice-input
          size="small"
          v-model="translation[fieldName]"
          @input="updateRecord($event, translation)"
          @update:model-value="toggleDestroyMark(translation)"
        />
      </form-row>
    </template>
  </span>
</template>

<script setup lang="ts">
import { useAvailableShopLocales, LOCALE_FLAGS, TranslatableRecord, Translation } from "@/core/i18n"
import { watch, ref } from "vue"

const shopLocales = useAvailableShopLocales()

const { record, fieldName, fieldLabel } = defineProps<{
  record: TranslatableRecord
  fieldName: string
  fieldLabel: string
}>()

const toggleDestroyMark = (translation: Translation) => {
  if (!translation.id) return

  if (translation[fieldName] === "") {
    translation._destroy = true
  } else {
    delete translation._destroy
  }
}

const translations = ref([]) as any

const updateRecord = (value, item) => {
  const temp = record.translations.filter(t => t.locale == item.locale)
  temp[fieldName] = value
  record.translations = translations.value
}

watch(
  record.translations,
  newVal => {
    shopLocales.forEach(locale => {
      if (newVal.length && !translations.value.length) translations.value = newVal
      else translations.value = translations.value.concat({ locale, [fieldName]: "" } as Translation)
    })
  },
  { immediate: true }
)
</script>
