<template>
  <span v-if="today">{{ $customFilters.time(datetime) }}</span>
  <nice-tooltip v-else :content="$customFilters.time(datetime) + ' Uhr'">
    <span>{{ $customFilters.date(datetime) }}</span>
  </nice-tooltip>
</template>

<script setup lang="ts">
import { computed } from "vue"
import customFilters from "@/config/filters"

const { datetime } = defineProps<{
  datetime: string | null
}>()

const today = computed(() => {
  return datetime ? customFilters.date(datetime) == customFilters.date(moment(Date.now())) : false
})
</script>
