<template>
  <form-dialog
    :title="formLabels.title"
    :visible="visible"
    width="450px"
    :show-footer="false"
    @close="emit('update:visible', false)"
  >
    <form ref="form" class="mb-4 -mt-2">{{ `${formLabels.desc} ${formLabels.desc2}` }}</form>
    <div class="text-right -mb-4">
      <nice-button @click="emit('update:visible', false)">{{ formLabels.backBtn }}</nice-button>
      <nice-button type="danger" :loading="deleting" @click="massDeleteActivites">
        {{ formLabels.submitBtn }}
      </nice-button>
    </div>
  </form-dialog>
</template>

<script lang="ts" setup>
import { ref, computed } from "vue"
import { useI18n } from "vue-i18n"
import useCore from "@/plugins/use-core"

type Activity = {
  id: number
  activatable_type: string
}
type ActivitiesMassDeleteDialogProps = {
  selected: Activity[]
  visible: boolean
}
const { selected, visible } = defineProps<ActivitiesMassDeleteDialogProps>()
const emit = defineEmits(["update:visible", "submitted"])
const deleting = ref(false)
const { t } = useI18n()
const { api, axios } = useCore()

function removePolicyActivities(activityList: Activity[]): Activity[] {
  return activityList.filter(o => o.activatable_type !== "Policy")
}

function massDeleteActivites() {
  deleting.value = true
  if (!nonPolicyActivities.value.length) {
    deleting.value = false
    emit("update:visible", false)
    return App.alert(t("activities.deleteWarning"))
  }

  api
    .mutation("massDelete", {
      resourceType: "activity",
      resourceIds: nonPolicyActivities.value.map(o => o.id),
    })
    .then(() => {
      App.flashy(t("activities.massDelete.success"))
    })
    .catch(axios.handleError)
    .finally(() => {
      deleting.value = false
      emit("submitted")
    })
}

const nonPolicyActivities = computed<Activity[]>(() => removePolicyActivities(selected))
const totalPolicyActivities = computed<number>(() => selected.length - nonPolicyActivities.value.length)
const formLabels = computed<Record<string, string>>(() => ({
  title: t("activities.massDelete.title", { length: nonPolicyActivities.value.length }),
  desc: t("activities.massDelete.desc"),
  desc2:
    totalPolicyActivities.value > 0
      ? t("activities.massDelete.desc2", {
          length: totalPolicyActivities.value,
        })
      : "",
  backBtn: t("formDialog.backBtn"),
  submitBtn: t("activities.massDelete.submit"),
}))
</script>
