<template>
  <div>
    <settings-container :description="$t(`admin.${extensionName}.description`)">
      <template #title>
        <div class="flex flex-col">
          <router-link to="/admin/extensions" v-if="showBackButton">
            <fa-icon name="long-arrow-left" />
            {{ $t(`admin.extensions.detail.back`) }}
          </router-link>
          <div class="mt-3 mb-2">
            <slot name="logo">
              <img v-if="logo" :src="logo" :alt="extensionName" style="height: 40px" />
            </slot>
          </div>
        </div>
      </template>
      <footer class="absolute top-4 right-4 z-10" v-if="link">
        <ps-button v-external-link="link">
          {{ $t("admin.extensions.detail.visit") }}
        </ps-button>
      </footer>
      <slot name="form">
        <settings-service
          ref="settingsService"
          :fields="[`extension(id: ${extensionName}) { enabled }`, ...fields]"
          :omitted-keys="['extension']"
          v-slot="{ shop, updating, update }"
        >
          <div class="mt-4 max-w-3xl">
            <nice-checkbox v-if="requiresConsentToConnect && !shop.extension.enabled" v-model="consentGiven">
              <span v-html="consentText" />
            </nice-checkbox>
            <slot name="before" v-if="!shop.extension.enabled"></slot>
            <template v-if="shop.extension.enabled">
              <section class="flex mb-3">
                <nice-alert class="mr-3 w-full text-green-600">
                  {{ $t(`admin.extensions.detail.connected`) }}
                </nice-alert>
                <nice-button type="danger" @click="disconnect">
                  {{ $t("admin.extensions.detail.disconnect") }}
                </nice-button>
              </section>
            </template>
            <footer v-else class="flex justify-end mt-2">
              <nice-button type="primary" @click="connect" :disabled="buttonDisabled">
                {{ $t("admin.extensions.detail.connect") }}
              </nice-button>
            </footer>
            <form v-if="$slots.settings && shop.extension.enabled" class="pt-6 mt-6 border-t">
              <header class="mb-2">
                <p class="uppercase text-xs font-semibold">{{ $t("adminSidebar.settings") }}</p>
              </header>
              <slot name="settings" :shop="shop" />
              <footer v-if="showSaveButton" class="flex justify-end">
                <nice-button type="primary" @click="update(shop, updated)" :loading="updating">
                  {{ $t("admin.components.shopForm.saveChanges") }}
                </nice-button>
              </footer>
            </form>
          </div>
        </settings-service>
      </slot>
    </settings-container>
    <slot name="extra"></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue"
import { EXTENSIONS_LOGO_MAPPING } from "@/config/extensions"

export default defineComponent({
  emits: ["connected", "disconnected"],
  props: {
    extensionName: {
      type: String,
      required: true,
    },
    showBackButton: {
      type: Boolean,
      required: false,
      default: true,
    },
    connectButtonDisabled: {
      type: Boolean,
      default: false,
    },
    showSaveButton: {
      type: Boolean,
      default: true,
    },
    noWarn: {
      type: Boolean,
      default: false,
    },
    link: {
      type: String,
      required: false,
    },
    fields: {
      type: Array,
      required: false,
      default: () => [],
    },
    requiresConsentToConnect: {
      type: Boolean,
      required: false,
      default: false,
    },
    consentText: {
      type: String,
      required: false,
      default: "",
    },
  },
  setup() {
    const consentGiven = ref<boolean>(false)

    return {
      consentGiven,
    }
  },
  computed: {
    logo(): any {
      return EXTENSIONS_LOGO_MAPPING[this.extensionName]
    },
    buttonDisabled(): boolean {
      return this.connectButtonDisabled || (this.requiresConsentToConnect && !this.consentGiven)
    },
  },
  methods: {
    disconnect() {
      if (this.noWarn) {
        this.$api.mutation(`disconnectExtension`, { extensionId: this.extensionName }).then(_ => {
          this.$refs.settingsService?.fetch()
          this.$emit("disconnected")
        })
      } else {
        this.$warn(
          {
            title: this.$t("admin.components.extensionDisconnect.title"),
            desc: this.$t("admin.components.extensionDisconnect.description"),
            confirmButtonText: this.$t("admin.components.extensionDisconnect.submit"),
            redButton: true,
          },
          () => {
            this.$api.mutation(`disconnectExtension`, { extensionId: this.extensionName }).then(_ => {
              this.$refs.settingsService?.fetch()
              this.$emit("disconnected")
            })
          }
        )
      }
      if (this.requiresConsentToConnect) {
        this.consentGiven = false
      }
    },
    connect() {
      this.$api
        .mutation(`connectExtension`, { extensionId: this.extensionName })
        .then(_ => {
          this.$refs.settingsService?.fetch()
          this.$emit("connected")
        })
        .catch(this.$axios.handleError)
    },
    updated() {
      App.flashy(this.$t("admin.components.shopForm.successfullySavedChanges"))
    },
  },
  mounted() {
    const extension = this.$db.shopData.extensions.find(ext => ext.id === this.extensionName)
    this.consentGiven = extension?.enabled || false
  },
})
</script>
