<template>
  <nice-select
    :model-value="modelValue"
    @update:model-value="emit('update:modelValue', $event)"
    :options="options"
    :clearable="clearable"
    :allow-create="allowCreate"
    fixed-position
  />
</template>

<script setup lang="ts">
import FormFields from "@/config/client/form-fields"
import { getDefaultFields } from "@/config/client/default-client-fields"
import detailViewForm from "@/config/detail-view-form"
import useCore from "@/plugins/use-core"
import { computed } from "vue"
import { useI18n } from "vue-i18n"

const { db } = useCore()
const { t } = useI18n()

const emit = defineEmits(["update:modelValue"])

const {
  modelValue,
  type = "client",
  allowCreate = false,
  clearable = false,
} = defineProps<{
  modelValue?: string
  type?: "client"
  allowCreate?: boolean
  clearable?: boolean
}>()

const options = computed(() => {
  if (type !== "client") return []

  const customDetailViewGroups = db.shopData.clientDetailViewGroups
  const clientDetailViewGroups = customDetailViewGroups.length > 0 ? customDetailViewGroups : getDefaultFields(db)

  const groups = detailViewForm(FormFields, clientDetailViewGroups, false, db, db.shopData.customFieldGroupsForClients)

  return groups
    .map(g => g.fields)
    .flat()
    .filter(f => ["string", "link", "phone", undefined].includes(f.formField.type))
    .map(f => ({
      id: f.fieldName.startsWith("cf_") ? f.fieldName.replace("cf_", "partial_custom_fields__") : f.fieldName,
      name: f.detailViewField.title || t(`clients.formFields.${f.fieldName}`),
    }))
})
</script>
