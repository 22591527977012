<template>
  <nice-select
    grouped
    ref="select"
    v-bind="$attrs"
    :allow-create="allowCreate"
    :clearable="clearable"
    :options="groupedOptions"
    :modelValue="modelValue"
    @update:modelValue="emit('update:modelValue', $event)"
    @change="emit('change', $event)"
    @blur="emit('blur', $event)"
    @visible-change="emit('visible-change', $event)"
  />
</template>

<script lang="ts" setup>
import { ref, onMounted, computed } from "vue"
import { getVariables } from "@/utils/get-variables"
import useCore from "@/plugins/use-core"
import { useI18n } from "vue-i18n"

const { t } = useI18n()
const { graphql, db } = useCore()

const emit = defineEmits(["input", "change", "blur", "visible-change", "update:modelValue"])
defineExpose({ focus })

export type NiceVariableSelectProps = {
  modelValue?: string
  excludeButtons?: boolean
  allowCreate?: boolean
  clearable?: boolean
  group?: string
}
const {
  modelValue,
  excludeButtons = true,
  allowCreate = false,
  clearable = false,
  group,
} = defineProps<NiceVariableSelectProps>()

const options = ref([])
let cache: typeof options.value | null = null

const select = ref<HTMLElement | null>(null)

function focus() {
  select?.value?.focus()
}

async function fetchData() {
  const { shop } = await graphql(`
    query variables {
      shop {
        folders {
          id
          name
          sendable
        }
        bookingCalendars {
          id
          name
        }
      }
    }
  `)

  // why is this in here? what does bookingCalendars have to do anything?
  options.value = getVariables(db.shopData, {
    folders: shop.folders.filter(f => f.sendable),
    bookingCalendars: shop.bookingCalendars,
  }).filter(grp => {
    if (group) return grp?.[0] === group
    if (excludeButtons) return !["Landing Page", "Funktionen", "Docusign"].includes(grp?.[0])

    return true
  })
  cache = options.value
}

const groupedOptions = computed(() =>
  options.value.map(grp => ({
    id: grp[0],
    name: t(`variableSelect.titles.${grp[0]}`),
    children: grp[1]?.map(option => ({
      id: option[1],
      name: option[2] === "custom" ? option[0] : t(`variableSelect.names.${option[0]}`),
    })),
  }))
)

onMounted(() => {
  if (cache) {
    options.value = cache
  } else {
    fetchData()
  }
})
</script>
