<template>
  <form-dialog
    :close-on-click-modal="false"
    :visible="visible"
    @update:visible="updateVisible"
    :title="title"
    :saving="saving"
    @submit="resolve"
    :disabled="radio === null"
    width="500px"
    :submitButtonTitle="$t('settings.confirmAndDelete')"
  >
    <form-section>
      <form-row class="break-normal">
        <nice-alert class="mb-2">
          {{ text }}
        </nice-alert>
      </form-row>
      <form-row :title="$t('settings.dataDecision')" direction="column">
        <nice-radio-group
          v-model="radio"
          :options="[
            { id: 0, name: option1 },
            { id: 1, name: option2 },
          ]"
        />
      </form-row>
      <form-row :title="option2" v-if="radio === 1">
        <nice-select v-model="selected" :options="filteredItems" />
      </form-row>
    </form-section>
  </form-dialog>
</template>
<script lang="ts">
import { defineComponent } from "vue"

export default defineComponent({
  emits: ["update:items", "update:id"],
  props: {
    id: {
      type: Number,
    },
    items: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    mutationName: {
      type: String,
      required: true,
    },
    propertyName: {
      type: String,
      default: "name",
    },
  },
  data() {
    return {
      selected: undefined as string | undefined,
      saving: false,
      radio: null as number | null,
    }
  },
  watch: {
    radio(newValue: number): any {
      if (newValue !== 0) return
      this.selected = undefined
    },
  },
  methods: {
    async resolve() {
      this.saving = true
      try {
        await this.$api.mutation(this.mutationName, { id: this.id, nextId: this.selected })
        this.selected = undefined
        this.radio = 0
        this.$emit("update:items", this.filteredItems)
        this.$emit("update:id", undefined)
      } catch (e) {
        this.$axios.handleError(e)
      } finally {
        this.saving = false
      }
    },
    updateVisible(value: boolean) {
      if (value) return
      this.$emit("update:id", undefined)
    },
  },
  computed: {
    visible(): boolean {
      return !!this.id
    },
    title(): string {
      return this.$t("settings.stillInUse", { label: this.label }) as string
    },
    text(): string {
      return this.$t("settings.resolveItemDelete.stillInUse", {
        label: this.label,
        property: this.propertyName,
      }) as string
    },
    option1(): string {
      return this.$t("settings.deleteEverywhere", { label: this.label }) as string
    },
    option2(): string {
      return this.$t("settings.resolveItemDelete.reassign", { label: this.label }) as string
    },
    filteredItems(): any[] {
      return this.id ? this.items.filter(d => (d as any).id !== this.id) : []
    },
    item(): any {
      return this.id ? this.items.find(d => (d as any).id === this.id) : {}
    },
  },
})
</script>
