<template>
  <div>
    <div class="relative bg-white rounded border shadow-sm">
      <span
        v-if="sortable"
        class="handle absolute bottom-0 top-0 flex items-center text-gray-600 cursor-move"
        style="right: -20px"
      >
        <fa-icon name="sort"></fa-icon>
      </span>
      <div
        @click="clicked"
        class="px-3 py-2 text-dark standard-hover d-flex align-items-center"
        :class="{ pointer: showActions && actions.includes('edit') }"
      >
        <span v-if="$slots.icon" class="mr-3 text-gray-600 text-xl">
          <slot name="icon" />
        </span>
        <span class="txt-overflow w-full">
          <slot name="title">
            <div class="flex items-center">
              <span class="block font-medium">{{ title }}</span>
              <fa-icon name="lock" class="ml-2 text-green-700" v-if="showLockIcon" />
            </div>
          </slot>
          <span class="block text-sm text-muted">
            <slot name="subtitle" />
          </span>
        </span>
      </div>
      <hover-actions>
        <slot name="actions" />
        <hover-action v-if="showActions && actions.includes('edit')" @click="$emit('edit')" icon="pencil" />
        <hover-action
          v-if="showActions && actions.includes('delete')"
          @click="$emit('delete')"
          icon="trash-alt"
          class="text-danger"
        />
      </hover-actions>
    </div>
    <div v-if="$slots.after"><slot name="after" /></div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue"
export default defineComponent({
  emits: ["edit", "delete"],
  props: {
    title: {
      type: String,
      required: false,
    },
    sortable: {
      type: Boolean,
      default: false,
    },
    showActions: {
      type: Boolean,
      default: true,
    },
    actions: {
      type: Array,
      default: ["edit", "delete"],
    },
    showLockIcon: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    clicked() {
      if (this.showActions && this.actions.includes("edit")) {
        this.$emit("edit")
      }
    },
  },
})
</script>
