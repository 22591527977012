<template>
  <el-dropdown :trigger="trigger" ref="dropdownRef" @command="handleCommand" :placement="placement">
    <a href="#" @click.prevent="toggleOpen">
      <slot name="trigger">
        <fa-icon name="ellipsis-h" />
      </slot>
    </a>

    <template #dropdown>
      <slot name="dropdown"></slot>
    </template>
  </el-dropdown>
</template>

<script setup lang="ts">
import { ref } from "vue"

const dropdownRef = ref()

const {
  trigger = "click",
  hideOnClick = true,
  placement = "bottom",
} = defineProps<{
  hideOnClick?: boolean
  placement?: string
  trigger?: string
}>()

const emit = defineEmits(["command"])

const toggleOpen = () => {
  dropdownRef.value?.handleOpen()
}

const handleCommand = command => {
  emit("command", command)
  if (hideOnClick) {
    dropdownRef.value?.handleClose()
  }
}
</script>
