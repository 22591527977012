<template>
  <form-dialog
    v-if="editable"
    :name="$t('fieldForm.popupTitle')"
    :id="editable.id"
    :visible="visible"
    @update:visible="$emit('update:visible', $event)"
    :saving="saving"
    @submit="saveField"
  >
    <form-section>
      <form-row :title="$t('fieldForm.fieldName')">
        <nice-input size="small" ref="fieldName" v-model="editable.prettyName" />
      </form-row>
      <form-row v-if="translatable" :title="`${$t('fieldForm.translation')} 🇩🇪`">
        <nice-input size="small" v-model="editable.titleDe" />
      </form-row>
      <form-row v-if="translatable" :title="`${$t('fieldForm.translation')} 🇬🇧`">
        <nice-input size="small" v-model="editable.titleEn" />
      </form-row>
      <form-row
        v-if="translatable && (locales.includes('es') || isSpanish)"
        :title="`${$t('fieldForm.translation')} 🇪🇸`"
      >
        <nice-input size="small" v-model="editable.titleEs" />
      </form-row>
      <form-row v-if="translatable && locales.includes('hu')" :title="`${$t('fieldForm.translation')} 🇭🇺`">
        <nice-input size="small" v-model="editable.titleHu" />
      </form-row>
      <form-row
        v-if="group.entity == 'for_properties'"
        :title="$t('fieldForm.publish')"
        :hint="$t('fieldForm.publishHint')"
      >
        <nice-switch v-model="editable.exportToOpenimmo" active-text inactive-text />
      </form-row>
      <form-row
        v-if="editable.exportToOpenimmo || group.entity == 'for_brokers'"
        :title="$t('fieldForm.customExportName')"
        :hint="$t('fieldForm.customExportNameHint')"
      >
        <nice-input
          size="small"
          v-model="editable.customExportName"
          :placeholder="$t('fieldForm.customExportNamePlaceholder')"
        />
      </form-row>
      <form-row
        v-if="canBeInSearchProfile"
        :title="$t('fieldForm.useInSearchProfile')"
        :hint="$t('fieldForm.useInSearchProfileHint')"
      >
        <nice-switch v-model="editable.useInSearchProfile" active-text inactive-text />
      </form-row>
      <form-row :title="$t('fieldForm.fieldType')">
        <div class="w-100">
          <nice-select
            clearable
            filterable
            v-model="editable.fieldType"
            fixed-position
            :placeholder="$t('fieldForm.fieldTypePlaceholder')"
            :disabled="!!editable.id"
          >
            <template #default>
              <el-option v-for="(label, key) in fieldTypes" :key="key" :value="key" :label="label"></el-option>
            </template>
          </nice-select>
          <p :class="{ invisible: !editable.id }" class="text-xs text-gray-500 ml-1 mt-1">
            {{ $t("fieldForm.fieldTypeWarning") }}
          </p>
        </div>
      </form-row>
      <form-row v-if="!!editable.id" :title="$t('fieldForm.group')">
        <nice-select fixed-position clearable filterable v-model="newGroup" :options="activeGroups" label-key="name" />
      </form-row>
      <form-row v-if="editable.fieldType == 'Number'" :title="$t('fieldForm.unit')">
        <nice-select
          fixed-position
          clearable
          filterable
          v-model="editable.unit"
          :placeholder="$t('fieldForm.unitPlaceholder')"
        >
          <template #default>
            <el-option v-for="(label, key) in units" :key="key" :value="key" :label="label"></el-option>
          </template>
        </nice-select>
      </form-row>
      <form-row
        v-if="group.entity == 'for_projects' && !!editable.id"
        :title="$t('fieldForm.inheritToUnits')"
        :hint="$t('fieldForm.inheritToUnitsHint')"
      >
        <nice-switch v-model="editable.bequest" active-text inactive-text :disabled="!bequestable" />
      </form-row>
      <form-row
        v-if="editable.fieldType == 'Number'"
        :title="$t('fieldForm.formula')"
        direction="column"
        :hint="$t('fieldForm.formulaHint')"
      >
        <nice-textarea
          v-model="editable.formula"
          placeholder='feld("price") + feld("parking_space_price")'
          :minRows="1"
          class="font-mono"
        />
      </form-row>
      <form-row
        v-if="group.entity == 'for_brokers'"
        :title="$t('customFields.permittedBrokers')"
        :hint="$t('customFields.permittedBrokersHint')"
      >
        <db-select collection="unarchivedBrokers" v-model="editable.permittedBrokerIds" multiple />
      </form-row>
      <form-row
        v-if="isTagsByCategory"
        :title="$t('customFields.tagsByCategory')"
        :hint="$t('customFields.tagsByCategoryHint')"
      >
        <nice-select
          clearable
          filterable
          multiple
          fixed-position
          v-model="editable.tagsSuperCategoryIds"
          :options="tagCategories"
          label-key="name"
        />
      </form-row>
      <form-row v-if="customOptionsVisible" :title="$t('fieldForm.options')" :hint="optionsHint">
        <div class="w-100">
          <div
            class="mb-2"
            v-for="(item, $idx) in editable.customOptions"
            :key="$idx"
            v-show="!item._destroy"
            :data-id="item.id"
          >
            <div class="fluid-item">
              <div class="fluid">
                <nice-input
                  size="small"
                  class="js-option-name-input"
                  v-model="item.name"
                  @keypress.enter.prevent="addOption"
                />
              </div>
              <div class="fluid" v-if="hasCondition">
                <nice-input
                  size="small"
                  class="js-option-name-input font-mono"
                  v-model="item.condition"
                  @keypress.enter.prevent="addOption"
                  placeholder='feld("option")'
                />
              </div>
              <a
                href="#"
                class="text-danger"
                :title="$t('fieldForm.removeOption')"
                tabindex="-1"
                @click.prevent="removeOption($idx)"
              >
                <fa-icon name="times" />
              </a>
            </div>
            <div class="fluid-item" v-if="isTags">
              <form-row :title="$t('fieldForm.tagCategory')">
                <nice-select
                  fixed-position
                  clearable
                  filterable
                  v-model="item.groupId"
                  :options="tagCategories"
                  label-key="name"
                />
              </form-row>
            </div>
            <p class="mt-1 fs-12" v-if="translatable">
              <a href="#" @click.prevent="toggleCustomOptionTranslationFor(item.id)" class="light">
                {{ $t("fieldForm.translation") }}
                <fa-icon :name="customTranslationOptionVisible == item.id ? 'angle-up' : 'angle-down'" class="ml-1" />
              </a>
            </p>
            <div
              v-if="translatable && customTranslationOptionVisible == item.id"
              class="mt-2 taskForm-massViewingFields"
            >
              <div class="mb-2">
                <label>{{ $t("fieldForm.translation") + " 🇩🇪" }}</label>
                <nice-input size="small" v-model="item.titleDe" />
              </div>
              <div class="mb-2">
                <label>{{ $t("fieldForm.translation") + " 🇬🇧" }}</label>
                <nice-input size="small" v-model="item.titleEn" />
              </div>
              <div class="mb-2" v-if="locales.includes('es') || isSpanish">
                <label>{{ $t("fieldForm.translation") + " 🇪🇸" }}</label>
                <nice-input size="small" v-model="item.titleEs" />
              </div>
              <div class="mb-2" v-if="locales.includes('hu')">
                <label>{{ $t("fieldForm.translation") + " 🇭🇺" }}</label>
                <nice-input size="small" v-model="item.titleHu" />
              </div>
            </div>
          </div>
          <p>
            <a href="#" class="link" @click.prevent="addOption">
              <fa-icon name="plus" class="mr-2" />
              {{ $t("fieldForm.addOption") }}
            </a>
          </p>
        </div>
      </form-row>
      <form-row
        v-if="customOptionsVisible && $db.shopData.activeFeatures.includes('conditional_options')"
        :title="$t('fieldForm.conditional')"
      >
        <div class="w-100">
          <p>
            <nice-checkbox class="mb-1" v-model="hasCondition" />
          </p>
        </div>
      </form-row>
    </form-section>
  </form-dialog>
</template>

<script lang="ts">
import useShop from "@/plugins/use-shop"
import { PropType } from "vue"

export default {
  props: {
    group: Object as PropType<any>,
    activeGroups: Array as PropType<any>,
    field: Object as PropType<any>,
    visible: Boolean,
  },
  watch: {
    visible(newValue) {
      if (newValue) {
        this.initializeForm()
        if (!this.$route.query.cf_id) this.$router.push({ query: { cf_id: this.editable.id } })
      } else {
        if (this.$route.query.cf_id) this.$router.push({ query: { cf_id: undefined } })
      }
    },
  },
  setup() {
    const { isSpanish } = useShop()
    return { isSpanish }
  },
  data() {
    return {
      editable: null as any,
      saving: false,
      deleting: false,
      customTranslationOptionVisible: null,
      newGroup: this.group.id,
      hasCondition: false,
    }
  },
  methods: {
    initializeForm() {
      const field = JSON.parse(JSON.stringify(this.field || {}))

      this.editable = {
        id: field.id,
        name: field.name,
        prettyName: field.prettyName,
        titleDe: field.titleDe,
        titleEn: field.titleEn,
        titleEs: field.titleEs,
        titleHu: field.titleHu,
        exportToOpenimmo: field.exportToOpenimmo,
        useInSearchProfile: field.useInSearchProfile,
        bequest: field.bequest,
        formula: field.formula,
        permittedBrokerIds: field.permittedBrokerIds,
        tagsSuperCategoryIds: field.tagsSuperCategoryIds,
        customOptions: (field.customOptions || []).map(o => ({
          ...o,
          _destroy: false,
        })),
        fieldType: field.fieldType || "String",
        unit: field.unit,
        customExportName: field.customExportName,
      }

      this.hasCondition = this.editable.customOptions.some(c => !!c.condition)
      this.customTranslationOptionVisible = null
      if (this.$refs.fieldName) {
        this.$nextTick(() => (this.$refs.fieldName as any)?.focus?.())
      }
    },

    addOption() {
      this.editable.customOptions.push({ name: "" })
      // this.$nextTick(() => {
      //   const nodes = this.$el.querySelectorAll(".js-option-name-input")
      //   ;(nodes[nodes.length - 1] as any).focus()
      // })
    },

    removeOption(index: number) {
      const option = this.editable.customOptions[index]

      if (option.id) {
        option._destroy = true
      } else {
        this.editable.customOptions.splice(index, 1)
      }
    },

    toggleCustomOptionTranslationFor(id) {
      if (id == this.customTranslationOptionVisible) {
        this.customTranslationOptionVisible = null
      } else {
        this.customTranslationOptionVisible = id
      }
    },
    saveField() {
      this.saving = true
      const payload = {
        ..._.omit(this.editable, ["id", "customOptions"]),
        customFieldGroupId: this.newGroup,
        entity: this.group.entity,
        customOptionsAttributes: this.editable.customOptions.filter(o => o.name),
      }
      const request = this.editable.id
        ? this.$api.update("CustomField", this.editable.id, payload)
        : this.$api.create("CustomField", payload)
      request
        .then(_result => {
          this.$emit("success")
          App.flashy(this.editable.id ? this.$t("fieldForm.editSuccess") : this.$t("fieldForm.createSuccess"))
        })
        .catch(this.$axios.handleError)
        .finally(() => (this.saving = false))
    },
  },
  computed: {
    translatable(): boolean {
      return this.group.onLandingPage
    },
    locales(): string[] {
      return [this.$db.shopData.language].concat(this.$db.shopData.supportedPropertyLocales || [])
    },
    customOptionsVisible(): boolean {
      return ["Dropdown", "Multiselect", "Tags"].includes(this.editable.fieldType)
    },
    isTags(): boolean {
      return this.editable.fieldType === "Tags"
    },
    isTagsByCategory(): boolean {
      return this.editable.fieldType === "TagsByCategory"
    },
    optionsHint(): any {
      if (this.isTags) {
        return this.$t("customFields.tagsHint")
      } else {
        return null
      }
    },
    tagCategories(): string[] {
      let entity = this.group.entity == "for_tasks" ? "for_activities" : this.group.entity
      return this.$db.get("superGroups").filter(sg => sg.entity === entity)
    },
    fieldTypes(): any {
      const fieldTypes = {
        String: this.$t("fieldForm.fieldTypes.string"),
        Text: this.$t("fieldForm.fieldTypes.text"),
        Number: this.$t("fieldForm.fieldTypes.number"),
        Dropdown: this.$t("fieldForm.fieldTypes.dropdown"),
        Multiselect: this.$t("fieldForm.fieldTypes.multiselect"),
        Boolean: this.$t("fieldForm.fieldTypes.boolean"),
        CountrySelect: this.$t("fieldForm.fieldTypes.countrySelect"),
        Date: this.$t("fieldForm.fieldTypes.date"),
        DateTime: this.$t("fieldForm.fieldTypes.dateTime"),
        Broker: this.$t("fieldForm.fieldTypes.broker"),
        Tags: this.$t("fieldForm.fieldTypes.tags"),
        TagsByCategory: this.$t("fieldForm.fieldTypes.tagsByCategory"),
      }
      return fieldTypes
    },
    units(): any {
      const units = {
        currency: this.$t("fieldForm.units.currency"),
        euro: this.$t("fieldForm.units.euro"),
        percent: this.$t("fieldForm.units.percent"),
        area: this.$t("fieldForm.units.area"),
        sqm: this.$t("fieldForm.units.sqm"),
        km: this.$t("fieldForm.units.km"),
        minute: this.$t("fieldForm.units.minute"),
        chf: this.$t("fieldForm.units.chf"),
        huf: this.$t("fieldForm.units.huf"),
        aed: this.$t("fieldForm.units.aed"),
        year: this.$t("fieldForm.units.year"),
      }
      return units
    },
    canBeInSearchProfile(): boolean {
      const allowedFieldTypes = ["Dropdown", "Multiselect", "Boolean", "Number"]
      return this.group.entity == "for_properties" && allowedFieldTypes.includes(this.editable.fieldType)
    },
    bequestable(): boolean {
      const disallowedFieldTypes = ["Dropdown", "Multiselect"]
      return (
        !disallowedFieldTypes.includes(this.editable.fieldType) &&
        this.$db.shopData.customFieldGroupsForProperties
          .map(g => g.customFields)
          .flat()
          .some(cf => cf.name == this.editable.name)
      )
    },
  },
}
</script>
