<template>
  <div class="app-panel">
    <nice-page-header>
      <template v-slot:leftMenu>
        <a href="#" @click.prevent="close" class="pane-button">
          <fa-icon name="times" />
        </a>
      </template>
      <template v-slot:rightButtons>
        <nice-tooltip :content="$t('client.queries.form.copy')" v-if="queryId">
          <ps-button @click="copy"><fa-icon name="clone" /></ps-button>
        </nice-tooltip>
        <a
          href="#"
          data-toggle="error-popover"
          @click.prevent="submit"
          class="ml-2 pane-button"
          :class="{ disabled: submitting }"
        >
          <span v-if="submitting" class="spinner spinner-sm mr-2"></span>
          {{ $t("client.queries.form.save") }}
        </a>
      </template>
    </nice-page-header>
    <spinner centered v-if="loading" />
    <div v-else class="app-panel-body">
      <form ref="queryForm" id="query-form">
        <div>
          <p v-if="massClientIds" class="mb-2 text-primary">
            <strong>
              {{ $t("client.queries.form.selected") }}
              {{ massClientIds.length }}
            </strong>
          </p>
          <div v-if="copyClientId" class="mb-6">
            <form-row :title="$t('client.queries.form.assign')">
              <client-select v-model="copyClientId" :clearable="false" :include-children="false" />
            </form-row>
            <div class="strike w-100 bg-gray-300 my-3" style="height: 1px" v-if="copyClientId"><span></span></div>
          </div>
          <div class="d-flex justify-content-between mb-3">
            <nice-checkbox v-model="sq.active">{{ $t("client.queries.form.active") }}</nice-checkbox>
            <div class="inline-flex">
              <nice-checkbox v-model="sq.guarded">
                <fa-icon name="lock" class="mr-1" />
                {{ $t("client.queries.form.guarded") }}
              </nice-checkbox>
              <tip class="ml-2" :hint="$t('client.queries.form.guardedDesc')" />
            </div>
          </div>
          <section v-for="(group, idx) in visibleGroups" :key="idx" class="mb-4">
            <base-field v-for="field in group.fields" :key="field.fieldName" :field="field" :record="sq" />
          </section>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import eventBus from "@/config/event-bus"
import { snakeCase } from "@/utils/with-case"
import ClientSelect from "@/components/ClientSelect.vue"
import { DEFAULT_FIELDS } from "@/config/saved-query/default-fields"
import detailViewForm, { formatPayload, formatRecord } from "@/config/detail-view-form"
import FormFields from "@/config/saved-query/form-fields"

const resourceFields = `active guarded
  locationIds cities regions
  marketingType rsTypes rsTypeCategories
  price priceTo baseRent baseRentTo
  livingSpace livingSpaceTo
  numberOfRooms numberOfRoomsTo
  numberOfBedRooms numberOfBedRoomsTo
  groupIds note publicNote customFields
  numberOfBathRooms numberOfBathRoomsTo
  floor floorTo
  plotArea plotAreaTo
  totalFloorSpace totalFloorSpaceTo
  constructionYear constructionYearTo
  condition
  rented
  lift
  balcony
  garden
  builtInKitchen
  cellar
  rented
  parkingSpace
  barrierFree
  monument
  shortTermConstructible
  bgf
  recommendedUseTypes
  siteDevelopmentType
  buildingPermission
  preliminaryEnquiry
  pricePerSqm pricePerSqmTo
  netFloorSpace netFloorSpaceTo
  priceMultiplier priceMultiplierTo
  yieldActual yieldActualTo
  investmentCategory
  purchaseForm
  industrialArea industrialAreaTo
  tenantStructure
  walt
  singleRoomsQuota
  occupancyRate occupancyRateTo
  conservationAreas
  numberOfParkingSpaces numberOfParkingSpacesTo
  polygon
  lat
  lng
  radius
  numberOfApartments numberOfApartmentsTo
  numberOfCommercials numberOfCommercialsTo
  clientGroupIds swimmingPool
  energyEfficiencyClass
  demolition
  numberOfUnits numberOfUnitsTo
  gfz gfzTo
  grz grzTo
`
const FORBIDDEN_FIELDS = ["custom_fields", "partial_custom_fields", "geometry", "client_group_ids"]

export default {
  components: { ClientSelect },

  props: ["id", "massAction", "clientId", "copyFormOnLoad"],

  data() {
    return {
      submitting: false,
      loadingData: true,
      sq: this.getDefault(),
      copyClientId: null,
      queryId: this.id,
    }
  },

  watch: {
    "sq.location_ids": {
      handler(newValue) {
        if (!newValue || !newValue.length) return
        this.sq.polygon = null
        this.sq.lng = null
        this.sq.lat = null
        this.sq.radius = null
      },
      deep: true,
    },
    "sq.regions": {
      handler(newValue) {
        if (!newValue || !newValue.length) return
        this.sq.polygon = null
        this.sq.lng = null
        this.sq.lat = null
        this.sq.radius = null
      },
    },
    "sq.cities": {
      handler(newValue) {
        if (!newValue || !newValue.length) return
        this.sq.polygon = null
        this.sq.lng = null
        this.sq.lat = null
        this.sq.radius = null
      },
      deep: true,
    },
    "sq.geometry": {
      handler(newValue) {
        if (!newValue || !newValue.values || !newValue.values.length) {
          this.sq.polygon = null
          this.sq.lng = null
          this.sq.lat = null
          this.sq.radius = null
        } else {
          this.sq.location_ids = null
          this.sq.regions = null
          this.sq.cities = null
          if (newValue.type === "polygon") {
            this.sq.polygon = newValue.values
            this.sq.lng = null
            this.sq.lat = null
            this.sq.radius = null
          } else if (newValue.type === "circle") {
            this.sq.lng = newValue.values[0][0]
            this.sq.lat = newValue.values[0][1]
            this.sq.radius = newValue.values[1]
            this.sq.polygon = null
          }
        }
      },
    },
  },

  methods: {
    getDefault() {
      return {
        marketing_type: null,
      }
    },
    close() {
      this.$emit("close")
    },
    async fetchData() {
      const { savedQuery } = await this.$graphql(`query getQueryById {
        savedQuery(id: ${this.queryId}) { ${resourceFields} }
      }`)
      savedQuery.geometry = null
      if (savedQuery.radius) {
        savedQuery.geometry = { values: [[savedQuery.lng, savedQuery.lat], savedQuery.radius], type: "circle" }
      }
      if (savedQuery.polygon) {
        savedQuery.geometry = { values: savedQuery.polygon, type: "polygon" }
      }

      this.sq = formatRecord(savedQuery)
      this.loadingData = false
    },
    async buildData() {
      const { savedQuery } = await this.$api.mutation(
        "buildSavedQuery",
        { clientId: this.clientId },
        `savedQuery { ${resourceFields} }`
      )
      this.sq = formatRecord(savedQuery)
      this.loadingData = false
    },
    submit() {
      this.submitting = true
      const request = this.queryId ? this.$axios.put : this.$axios.post

      request(this.url, { saved_query: formatPayload(this.sq, FORBIDDEN_FIELDS), client_ids: this.massClientIds })
        .then(_ => {
          if (this.clientIdProp) {
            App.flashy(
              this.queryId ? this.$t("client.queries.form.editSuccess") : this.$t("client.queries.form.createSuccess")
            )
            this.close()
            if (this.copyClientId) this.$router.push(`/contacts/clients/${this.copyClientId}/queries`)
            else {
              this.$router.push({ query: { query: undefined } })
            }
          } else if (this.massClientIds) {
            App.flashy(this.$t("client.queries.form.success"))
            this.close()
          }

          eventBus.$emit("saved-query-saved", this.sq)
        })
        .catch(this.$axios.handleError)
        .finally(_ => {
          this.submitting = false
        })
    },
    async copy() {
      this.loadingData = true
      const { savedQuery } = await this.$api.mutation(
        "buildSavedQuery",
        { copyId: this.queryId },
        `savedQuery { ${resourceFields} }`
      )
      this.queryId = null
      this.copyClientId = this.clientId
      this.sq = formatRecord(savedQuery)
      this.loadingData = false
    },
  },

  computed: {
    clientIdProp() {
      if (this.clientId) return this.clientId
      if (this.$route.params.id) return Number(this.$route.params.id)
    },
    loading() {
      return this.loadingData
    },
    url() {
      if (this.clientIdProp)
        return `/contacts/clients/${this.copyClientId || this.clientIdProp}/queries/${this.queryId || ""}`
      if (this.massClientIds) return `/services/mass_edit/create_saved_query`
    },
    massClientIds() {
      return this.massAction ? this.massAction.clientIds : null
    },
    detailViewGroups() {
      const customDetailViewGroups = this.$db.shopData.savedQueryDetailViewGroups
      if (customDetailViewGroups.length > 0) return customDetailViewGroups
      return DEFAULT_FIELDS.concat([
        {
          slot: "left",
          detailViewFields: this.$db.shopData.customFieldGroupsForProperties
            .flatMap(g => g.customFields.filter(cf => cf.forSavedQueries))
            .map(cf => ({
              fieldName: `cf_${cf.name}`,
              readBrokerIds: [],
              writeBrokerIds: [],
              readDepartmentIds: [],
              writeDepartmentIds: [],
            })),
        },
      ])
    },
    formFields() {
      return _.mapValues(FormFields, (value, key) => {
        let translated = { ...value, label: this.$t(`properties.formFields.${snakeCase(value.filterName || key)}`) }
        if (!!value.options && Array.isArray(value.options)) {
          translated.options = value.options.map(o =>
            o.translate ? { id: o.id, name: this.$t(`properties.formFieldOptions.${o.name}`) } : o
          )
        }
        return translated
      })
    },
    groups() {
      return detailViewForm(
        this.formFields,
        this.detailViewGroups,
        false,
        this.$db,
        this.$db.shopData.customFieldGroupsForProperties,
        undefined,
        undefined,
        undefined,
        undefined,
        "saved_query"
      )
    },
    visibleGroups() {
      const allowedToSee = ({ allowedMarketingType = null, allowedRsTypes = [], clientGroupIds = [] }) => {
        const rsType =
          this.sq.rs_type_categories && this.sq.rs_type_categories.length ? this.sq.rs_type_categories[0][0] : null
        return (
          (!this.sq.marketing_type || !allowedMarketingType || allowedMarketingType == this.sq.marketing_type) &&
          (allowedRsTypes.length <= 0 || allowedRsTypes.includes(rsType)) &&
          (!clientGroupIds ||
            clientGroupIds.length <= 0 ||
            clientGroupIds.some(id => this.sq.client_group_ids.includes(id)))
        )
      }

      return this.groups
        .filter(group => allowedToSee(group))
        .map(group => {
          return {
            ...group,
            fields: group.fields.filter(field => allowedToSee(field.detailViewField)),
          }
        })
    },
  },

  handleSubmit(e) {
    e.preventDefault()
    this.submit()
  },

  mounted() {
    this.$refs.queryForm?.addEventListener("submit", this.handleSubmit)

    if (this.copyFormOnLoad) this.copy()

    this.queryId ? this.fetchData() : this.buildData()
  },
  beforeUnmount() {
    this.$refs.queryForm?.removeEventListener("submit", this.handleSubmit)
  },
}
</script>

<style>
.strike {
  display: block;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
}

.strike > span {
  position: relative;
  display: inline-block;
}

.strike > span:before,
.strike > span:after {
  content: "";
  position: absolute;
  top: 50%;
  width: 9999px;
  height: 1px;
  background-color: rgba(209, 213, 219, 1);
}

.strike > span:before {
  right: 100%;
  margin-right: 15px;
}

.strike > span:after {
  left: 100%;
  margin-left: 15px;
}
</style>
