<template>
  <nice-popover
    ref="popoverRef"
    placement="bottom"
    :show-arrow="false"
    trigger="click"
    width="240"
    transition="none"
    v-model:visible="visible"
    @show="$emit('open')"
  >
    <template #reference>
      <avatar :broker="resource.broker" class="cursor-pointer" />
    </template>
    <main @click="visible = false">
      <dropdown-item v-if="db.shopData.brokers.length > 20">
        <nice-input ref="input" :placeholder="$t('brokerDropdownSearch.search')" v-model="searchBroker" />
      </dropdown-item>
      <dropdown-section style="max-height: 250px">
        <dropdown-item
          v-for="broker in activeBrokers"
          :key="broker.id"
          :title="broker.internalName"
          @click="$emit('broker-change', broker)"
          style="padding: 0 10px"
        >
          <avatar :broker="broker" size="20px" style="margin-right: 10px" />
          {{ broker.internalName }}
        </dropdown-item>
        <p v-if="!activeBrokers.length" class="text-gray-500" style="padding: 5px 10px">
          {{ $t("brokerDropdownSearch.empty") }}
        </p>
      </dropdown-section>
    </main>
  </nice-popover>
</template>

<script lang="ts" setup>
import useCore from "@/plugins/use-core"
import { type NicePopover } from "@nice-ui"
import { computed, nextTick, ref, watch } from "vue"

defineProps<{
  resource: any
}>()

const popoverRef = ref<typeof NicePopover | null>(null)
const visible = ref(false)
const searchBroker = ref("")

const { db } = useCore()

watch(visible, newValue => {
  if (newValue) {
    searchBroker.value = ""
    if (db.shopData.brokers.length > 20) focus()
  }
})

const focus = () => {
  nextTick(() => {
    popoverRef.value?.$refs.input.focus()
  })
}

const activeBrokers = computed(() => {
  let filteredBrokers = db.shopData.brokers.filter(b => b.active)

  if (searchBroker.value) {
    filteredBrokers = filteredBrokers.filter(b =>
      b.internalName.toLowerCase().includes(searchBroker.value.toLowerCase())
    )
  }

  return filteredBrokers
})
</script>

<style scoped>
.el-popper main {
  max-height: 80vh;
  overflow: auto;
}
</style>
