<template>
  <form-dialog
    :title="t('admin.createBrokerModal.title')"
    :visible="visible"
    @update:visible="emits('update:visible', $event)"
    @close="emits('update:visible', false)"
    width="660px"
    footerType="bigButton"
    :submitButtonTitle="t('admin.createBrokerModal.submit')"
    :disabled="disabled"
    :submitFunc="submit"
    :autofocus="false"
  >
    <form-section>
      <form-row :title="t('admin.createBrokerModal.emailAddress')">
        <nice-input size="small" v-model="email" placeholder="test@beispiel.de" />
      </form-row>
      <form-row :title="t('admin.createBrokerModal.senderName')">
        <nice-input size="small" v-model="senderName" />
      </form-row>
      <form-row :title="t('admin.createBrokerModal.lastname')">
        <nice-input size="small" v-model="lastName" disabled />
      </form-row>
    </form-section>
  </form-dialog>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue"
import { EmailValidation } from "@/config/regex"
import { useI18n } from "vue-i18n"
import useCore from "@/plugins/use-core"

const { api } = useCore()
const { t } = useI18n()

const emits = defineEmits(["update:visible", "success"])

const { visible } = defineProps<{
  visible: boolean
}>()

const email = ref("")
const senderName = ref("")

const lastName = computed(() => `${email.value.split("@")?.[0]}@`)
const disabled = computed(() => !EmailValidation.test(email.value))

const submit = () => {
  return api
    .create("Broker", {
      email: email.value,
      emailSenderName: senderName.value,
      lastName: lastName.value,
      hideInDropdowns: true,
      role: "technical",
    })
    .then(data => {
      App.flashy(t("admin.createBrokerModal.createSuccessful"))
      email.value = ""
      emits("update:visible", false)
      emits("success", data)
    })
}
</script>
