<template>
  <div class="sq-item" @click="canEdit ? $emit('click', sq) : $router.push(detailURL)">
    <main>
      <p class="sq-title">
        <span class="sq-activeIndicator" :class="{ 'sq-activeIndicator--active': sq.active }"></span>
        <span :title="types.join(', ')">{{ types.join(", ") || $t("client.queries.item.all") }}</span>
      </p>

      <section class="sq-infos">
        <info-row :label="$t('client.queries.item.marketingType')">
          <nice-label v-if="forBuy" :label="$t('client.queries.item.buy')" color="#22bb11" />
          <nice-label v-else-if="sq.marketingType == 'RENT'" :label="$t('client.queries.item.rent')" color="#1155dd" />
          <nice-label v-else :label="$t('client.queries.item.both')" />
        </info-row>
        <info-row v-if="locationNames.length > 0" :label="$t('client.queries.item.location')">
          <span :title="locationNames.join(', ')">
            {{ locationNames.slice(0, 2).join(", ") }}
            <span v-if="locationNames.length > 2">+{{ locationNames.length - 2 }}</span>
          </span>
        </info-row>
        <info-row
          v-if="sq.categoryLabels && sq.categoryLabels.length > 0"
          :label="$t('client.queries.item.propertytype')"
        >
          <span :title="sq.categoryLabels.join(', ')">
            {{ sq.categoryLabels.slice(0, 2).join(", ") }}
            <span v-if="sq.categoryLabels.length > 2">+{{ sq.categoryLabels.length - 2 }}</span>
          </span>
        </info-row>
        <info-row v-if="sq.cities && sq.cities.length > 0" :label="$t('client.queries.item.city')">
          <span :title="sq.cities.join(', ')">{{ sq.cities.join(", ") }}</span>
        </info-row>
        <info-row v-if="sq.livingSpace" :label="$t('client.queries.item.livingSpace')">
          <span>ab {{ $customFilters.prettySpace(sq.livingSpace, $db.shopData.areaUnit) }}</span>
        </info-row>
        <info-row v-if="sq.numberOfRooms" :label="$t('client.queries.item.numberOfRooms')">
          <span>ab {{ $customFilters.prettyNumber(sq.numberOfRooms) }}</span>
        </info-row>
        <info-row v-if="sq.numberOfBedrooms" :label="$t('client.queries.item.numberOfBedrooms')">
          <span>ab {{ $customFilters.prettyNumber(sq.numberOfBedrooms) }}</span>
        </info-row>
        <info-row v-if="sq.priceTo && sq.marketingType != 'RENT'" :label="$t('client.queries.item.price')">
          <span>bis {{ $customFilters.numberToCurrency(sq.priceTo, $db.shopData.currency) }}</span>
        </info-row>
        <info-row v-if="sq.baseRentTo && sq.marketingType != 'BUY'" :label="$t('client.queries.item.baseRent')">
          <span>bis {{ $customFilters.numberToCurrency(sq.baseRentTo, $db.shopData.currency) }}</span>
        </info-row>
        <info-row v-if="sq.rented" :label="$t('client.queries.item.rented')">
          <span>{{ sq.rented == "true" ? $t("client.queries.item.y") : $t("client.queries.item.n") }}</span>
        </info-row>
        <info-row :label="$t('client.queries.item.note')" v-if="sq.note">
          <p class="sq-note">{{ sq.note }}</p>
        </info-row>
        <info-row :label="$t('client.queries.item.externalNote')" v-if="sq.publicNote">
          <p class="sq-note">{{ sq.publicNote }}</p>
        </info-row>
      </section>
    </main>

    <footer class="mt-2">
      <p class="text-muted fs-12">
        {{ $t("client.queries.item.createdAt") }}
        <span>{{ $customFilters.datetime(sq.createdAt) }}</span>
        <nice-tooltip v-if="sq.creator">
          <span>
            {{ $t("client.queries.item.creator", { creator: sq.creator.initials }) }}
            <fa-icon v-if="sq.fromTipster" class="ml-1" name="lightbulb" />
          </span>

          <template #content>
            {{ sq.creator.name }}
            <small v-if="sq.fromTipster" class="text-muted">
              {{ $t("client.queries.item.fromTipster") }}
            </small>
          </template>
        </nice-tooltip>
        <span v-else>{{ $t("client.queries.item.system") }}</span>
      </p>
      <p class="text-muted mt-1 fs-12">
        {{ $t("client.queries.item.updatedAt") }}
        <span>{{ $customFilters.datetime(sq.updatedAt) }}</span>
        <span v-if="updater" :title="updater.name">
          {{ $t("client.queries.item.creator", { creator: updater.initials }) }}
        </span>
      </p>
      <p v-if="sq.clientTouchedAt" class="text-muted mt-1 fs-12">
        {{ $t("client.queries.item.clientUpdatedAt") }}
        <span>{{ $customFilters.datetime(sq.clientTouchedAt) }}</span>
      </p>
    </footer>
    <template v-if="canEdit">
      <a @click.prevent.stop="$emit('click', sq)" class="sq-cta sq-cta--edit">
        <fa-icon name="pencil" class="fs-14" />
      </a>
      <a @click.prevent.stop="$emit('copy', sq)" class="sq-cta sq-cta--copy">
        <fa-icon name="clone" class="fs-14" />
      </a>
      <a href="#" @click.prevent.stop="$emit('delete', sq)" class="sq-cta sq-cta--delete">
        <fa-icon name="trash-alt" class="fs-14" />
      </a>
    </template>
    <router-link :to="detailURL" class="sq-cta" @click.stop>
      {{ $t("client.queries.item.similar") }}
      <fa-icon name="long-arrow-right" class="ml-1 fs-14 text-primary" style="position: relative; margin-top: 1px" />
    </router-link>
  </div>
</template>

<script>
import InfoRow from "./InfoRow"

export default {
  emits: ["click", "copy", "delete"],
  props: ["sq", "canEdit"],
  components: {
    InfoRow,
  },
  computed: {
    locationNames() {
      return this.sq.locations.map(o => o.name)
    },
    types() {
      return this.sq.rsTypeLabels
    },
    detailURL() {
      return `/contacts/clients/${this.$parent.resourceId}/queries/${this.sq.id}`
    },
    editURL() {
      return this.detailURL + "/edit"
    },
    forBuy() {
      return this.sq.marketingType == "BUY"
    },
    forRent() {
      return this.sq.marketingType == "RENT"
    },
    updater() {
      return this.$db.get("brokers").find(b => b.id == this.sq.updaterId)
    },
  },
}
</script>

<style scoped>
.sq-item {
  padding: 8px 10px;
  border-radius: 3px;
  box-shadow: rgba(15, 15, 15, 0.1) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 2px 4px;
  background: white;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.sq-item:hover {
  background: rgba(55, 54, 47, 0.03);
}
.sq-title {
  font-weight: 500;
  margin-bottom: 8px;
}
.sq-activeIndicator {
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: #dddddd;
  margin-right: 5px;
  display: inline-flex;
}
.sq-activeIndicator--active {
  background: #13ce66;
}

.sq-cta {
  color: inherit;
  font-size: 12px;
  background: white;
  box-shadow: rgba(15, 15, 15, 0.1) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 2px 4px;
  display: flex;
  align-items: center;
  border-radius: 3px;
  position: absolute;
  right: 8px;
  z-index: 2;
  bottom: 8px;
  padding: 4px 10px 5px;
}
.sq-item:hover .sq-cta {
  visibility: visible;
}
.sq-cta:hover {
  background: rgb(240, 239, 237);
}
.sq-cta.sq-cta--edit {
  top: 8px;
  bottom: initial;
  visibility: hidden;
}
.sq-cta.sq-cta--copy {
  top: 36px;
  bottom: initial;
  visibility: hidden;
}
.sq-cta--delete {
  visibility: hidden;
  color: red;
  bottom: initial;
  top: 64px;
}

.sq-note {
  white-space: normal;
  overflow: hidden;
  max-height: 67px; /* 4 Zeilen maximal */
  font-size: 12px;
}
</style>
