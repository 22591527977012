<template>
  <form-dialog
    :title="$t('admin.broker.notifications.notificationSettingsLabel')"
    :visible="Boolean(resourceId)"
    @close="close"
    width="660px"
    footerType="bigButton"
    :submitButtonTitle="$t('admin.broker.notifications.notificationSettingsSubmitButton')"
    :submitFunc="submit"
    :autofocus="false"
    :disabled="!this.modelDiffersDb"
  >
    <div v-if="initialized">
      <form-section class="multi-switches" id="notifications-settings">
        <form-row :title="label('notification_enabled')">
          <nice-switch v-model="notification_enabled" />
        </form-row>
        <form-row :title="label('notify_only_during_work_time')">
          <nice-switch v-model="notify_only_during_work_time" />
        </form-row>
        <div class="expandable-container">
          <div class="expand-target" @click="toggleExpand('working_hours')">
            <form-row :title="$t('admin.broker.notifications.generalSettings.working_hours')" class="label-headline">
              <a class="toggle-chevron" href="#">
                <i
                  class="el-collapse-item__arrow el-icon-arrow-right"
                  :class="{ 'is-active': isExpanded('working_hours') }"
                ></i>
              </a>
            </form-row>
          </div>
          <div v-if="isExpanded('working_hours')">
            <form-row
              v-for="day in working_hours"
              :key="day.title"
              :title="label(day.title, 'generalSettings.working_days')"
              class="working_hours_row"
            >
              <nice-input v-model="day.from" size="small" placeholder="18:00" />
              <span class="mx-3 whitespace-nowrap">{{ $t("admin.bookingCalendars.form.to") }}</span>
              <nice-input v-model="day.to" size="small" placeholder="20:00" />
            </form-row>
          </div>
        </div>
        <hr />

        <div class="group expandable-container" v-for="(events, category) in schema" :key="category">
          <div class="expand-target" @click="toggleExpand(category)">
            <form-row
              :title="$t(`admin.broker.notifications.notification_category.${category}`)"
              v-bind="hintAttributes(category)"
              class="label-headline"
            >
              <a class="toggle-chevron" href="#">
                <i
                  class="el-collapse-item__arrow el-icon-arrow-right"
                  :class="{ 'is-active': isExpanded(category) }"
                ></i>
              </a>
            </form-row>
          </div>
          <table v-if="isExpanded(category)">
            <tr>
              <td></td>
              <td v-for="channel in availableChannels" :key="channel" class="sub-text switch-column">
                <span v-if="!hideColumn(channel)">
                  {{ label(channel, "generalSettings.channels") }}
                </span>
              </td>
            </tr>
            <tr v-for="(_, eventname) in events" :key="eventname">
              <td>
                {{ label(eventname, category) }}
                <nice-tooltip v-if="fetchHint(eventname)" :content="fetchHint(eventname)">
                  <fa-icon name="question-circle" />
                </nice-tooltip>
              </td>
              <td v-for="channel in availableChannels" :key="`${eventname}.${channel}`" class="switch-column">
                <nice-switch
                  v-model="model[eventname][channel]"
                  :disabled="disable_toggle(category, channel)"
                  v-if="!hideColumn(channel)"
                />
              </td>
            </tr>
          </table>
        </div>
      </form-section>
    </div>
  </form-dialog>
</template>

<script lang="ts">
import { PropType, defineComponent } from "vue"
import _, { entries } from "lodash"

const defaultConfig = {
  notification_enabled: null,
  notify_only_during_work_time: null,
  working_hours: {},
  notificationSettings: {},
}

export default defineComponent({
  props: {
    resourceId: {
      type: Number,
      required: false,
    },
  },

  data() {
    return {
      availableChannels: ["desktop", "mobile", "web", "email"],
      model: {},
      notification_enabled: defaultConfig.notification_enabled,
      notify_only_during_work_time: defaultConfig.notify_only_during_work_time,
      working_hours: defaultConfig.working_hours,
      storedInDb: {},
      defaultConfig,
      initialized: false,
      expand_working_hours: false,
      expand: {
        working_hours: false,
        message: false,
        reminder: false,
        note: false,
        event: false,
        property: false,
        client: false,
        information: false,
      },
    }
  },

  methods: {
    label(key, category = "generalSettings") {
      return this.$t(`admin.broker.notifications.${category}.${key}`)
    },

    async fetchData() {
      const { broker } = await this.$graphql(`{
        broker(id: ${this.resourceId}) {
          id
          notificationSettings
          notificationEnabled
          notifyOnlyDuringWorkTime
          workingHours
        }}`)

      await this.$axios
        .get("/api/v1/notifications/default-settings")
        .then(response => {
          return response
        })
        .then(defaultsSettings => {
          defaultConfig.notification_enabled = defaultsSettings.data.notification_enabled
          defaultConfig.working_hours = defaultsSettings.data.working_hours
          defaultConfig.notificationSettings = defaultsSettings.data.notification_settings
          this.model = _.merge(defaultConfig.notificationSettings, broker.notificationSettings)
          this.notification_enabled = broker.notificationEnabled ?? false
          this.notify_only_during_work_time = broker.notifyOnlyDuringWorkTime ?? false
          this.working_hours = broker.workingHours ?? defaultConfig.working_hours
          this.storedInDb = {
            notificationSettings: JSON.parse(JSON.stringify(this.model)),
            notificationEnabled: this.notification_enabled,
            notifyOnlyDuringWorkTime: this.notify_only_during_work_time,
            workingHours: JSON.parse(JSON.stringify(this.working_hours)),
          }
          this.initialized = true
        })
        .catch(error => {
          console.log(error)
        })
    },

    deconstructKey(key) {
      const category = key.substring(0, key.indexOf("_"))
      const event = key.substring(key.indexOf("_") + 1, key.length)
      return [category, event]
    },

    submit() {
      return this.$api
        .update("Broker", this.resourceId, {
          notificationSettings: this.model,
          notificationEnabled: this.notification_enabled,
          notifyOnlyDuringWorkTime: this.notify_only_during_work_time,
          workingHours: this.working_hours,
        })
        .then(() => {
          this.$emit("save")
          this.close()
          App.flashy(this.$t("admin.broker.notifications.notificationSettingsUpdated"))
        })
    },
    close() {
      this.$emit("update:resourceId", null)
    },
    toggleExpand(category) {
      this.expand[category] = !this.expand[category]
    },
    isExpanded(category) {
      return this.expand[category]
    },
    hideColumn(channel) {
      const safariDetected = globalThis.safari !== undefined
      if (safariDetected && channel === "desktop") {
        return true
      } else {
        return false
      }
    },
    hintAttributes(category) {
      const hint = this.fetchHint(category)
      if (hint) {
        return {
          hint,
          stopHintClickPropagation: true,
        }
      } else return undefined
    },
    fetchHint(key) {
      const hintKey = `admin.broker.notifications.notification_hint.${key}`
      const hint = this.$t(hintKey)
      if (hint === hintKey) {
        return undefined
      } else {
        return hint
      }
    },
    disable_toggle(category, channel) {
      if (
        (category === "message" && channel === "email") ||
        (category === "information" && channel === "email") ||
        (category === "partner" && channel === "mobile")
      ) {
        return true
      } else return false
    },
  },

  computed: {
    schema(): Record<string, any> {
      return Object.entries(this.model as Record<string, any>).reduce((agg, [key, val]) => {
        const group = key.split("_")[0]
        agg[group] = agg[group] ?? {}
        agg[group][key] = val
        return agg
      }, {} as Record<string, any>)
    },
    get_button_text: function (): String {
      if (!this.expand_working_hours) {
        return "" + this.$t("admin.broker.notifications.expand")
      } else {
        return "" + this.$t("admin.broker.notifications.collapse")
      }
    },
    modelDiffersDb: function (): Boolean {
      let current = {
        notificationSettings: JSON.parse(JSON.stringify(this.model)),
        notificationEnabled: this.notification_enabled,
        notifyOnlyDuringWorkTime: this.notify_only_during_work_time,
        workingHours: JSON.parse(JSON.stringify(this.working_hours)),
      }
      return !_.isEqual(this.storedInDb, current)
    },
  },

  mounted() {
    this.fetchData()
  },
})
</script>

<style scoped>
a.toggle-chevron,
a.toggle-chevron:visited,
a.toggle-chevron:hover,
a.toggle-chevron:active {
  color: #272828;
}

div.group {
  margin: 0;
}
div.group table,
.working_hours_row:last-child {
  margin-bottom: 25px !important;
}

li.label-headline {
  margin: 0;
}
.expandable-container {
  border: solid 1px rgb(228, 228, 228);
  border-radius: 6px;
  margin-bottom: 10px !important;
  padding: 10px 10px 10px 20px;
}

.expand-target:hover {
  cursor: pointer;
}
hr {
  border-color: rgb(228, 228, 228);
  margin: 15px 0;
}
</style>
