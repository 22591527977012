import i18n from "../i18n"

const LOG_TEXT_MAP = {
  decision_option(l) {
    if (!l.new_value) {
      return i18n.global.t("logs.removeDecision", { value: l.old_value })
    }
    if (!l.old_value) {
      return i18n.global.t("logs.addDecision", { value: l.new_value })
    }
    return i18n.global.t("logs.changeDecision", { value1: l.old_value, value2: l.new_value })
  },
  message_category(l) {
    if (!l.new_value) {
      return i18n.global.t("logs.removeCategory", { value: l.old_value })
    }
    if (!l.old_value) {
      return i18n.global.t("logs.addCategory", { value: l.new_value })
    }
    return i18n.global.t("logs.changeCategory", { value1: l.old_value, value2: l.new_value })
  },
  client_source(l) {
    if (!l.new_value) {
      return i18n.global.t("logs.removeSource", { value: l.old_value })
    }
    if (!l.old_value) {
      return i18n.global.t("logs.addSource", { value: l.new_value })
    }
    return i18n.global.t("logs.changeSource", { value1: l.old_value, value2: l.new_value })
  },
  clients(l) {
    if (l.new_value) {
      return i18n.global.t("logs.addClient", { value: l.new_value })
    } else {
      return i18n.global.t("logs.removeClient", { value: l.old_value })
    }
  },
  properties(l) {
    if (l.new_value) {
      return i18n.global.t("logs.addProperty", { value: l.new_value })
    } else {
      return i18n.global.t("logs.removeProperty", { value: l.old_value })
    }
  },
  projects(l) {
    if (l.new_value) {
      return i18n.global.t("logs.addProject", { value: l.new_value })
    } else {
      return i18n.global.t("logs.removeProject", { value: l.old_value })
    }
  },
  brokers(l) {
    if (l.new_value) {
      return i18n.global.t("logs.addBrokers", { value: l.new_value })
    } else {
      return i18n.global.t("logs.removeBrokers", { value: l.old_value })
    }
  },
  archived(l) {
    if (l.new_value) {
      return i18n.global.t("logs.archived")
    } else {
      return i18n.global.t("logs.unarchived")
    }
  },
  title(l) {
    if (!l.new_value) {
      return i18n.global.t("logs.removeTitle", { value: l.old_value })
    }
    if (!l.old_value) {
      return i18n.global.t("logs.addTitle", { value: l.new_value })
    }
    return i18n.global.t("logs.changeTitle", { value1: l.old_value, value2: l.new_value })
  },
  due_date(l) {
    if (!l.new_value) {
      return i18n.global.t("logs.removeDuedate", { value: moment(l.old_value).format("l HH:mm") })
    }
    if (!l.old_value) {
      return i18n.global.t("logs.addDuedate", { value: moment(l.new_value).format("l HH:mm") })
    }
    return i18n.global.t("logs.changeDuedate", {
      value1: moment(l.old_value).format("l HH:mm"),
      value2: moment(l.new_value).format("l HH:mm"),
    })
  },
  starts_at(l) {
    return i18n.global.t("logs.changeStartsAt", {
      value1: moment(l.old_value).format("l HH:mm"),
      value2: moment(l.new_value).format("l HH:mm"),
    })
  },
  ends_at(l) {
    return i18n.global.t("logs.changeEndsAt", {
      value1: moment(l.old_value).format("l HH:mm"),
      value2: moment(l.new_value).format("l HH:mm"),
    })
  },
  done(l) {
    if (l.new_value) {
      return i18n.global.t("logs.done")
    } else {
      return i18n.global.t("logs.undone")
    }
  },
  review_state(l) {
    if (l.new_value) {
      return i18n.global.t("logs.review_state", { value: i18n.global.t(`task.todoDetails.review.${l.new_value}`) })
    } else {
      return i18n.global.t("logs.reset_review_state")
    }
  },
  done_at(l) {
    if (l.new_value) {
      return i18n.global.t("logs.doneAt", { value: l.name })
    } else {
      return i18n.global.t("logs.undoneAt", { value: l.name })
    }
  },
  broker(l) {
    if (!l.new_value) {
      return i18n.global.t("logs.removeBroker", { value: l.old_value })
    }
    if (!l.old_value) {
      return i18n.global.t("logs.addBroker", { value: l.new_value })
    }
    return i18n.global.t("logs.changeBroker", { value1: l.old_value, value2: l.new_value })
  },
  note_type(l) {
    if (!l.new_value) {
      return i18n.global.t("logs.removeNoteType", { value: l.old_value })
    }
    if (!l.old_value) {
      return i18n.global.t("logs.addNoteType", { value: l.new_value })
    }
    return i18n.global.t("logs.changeNoteType", { value1: l.old_value, value2: l.new_value })
  },
  task_stage(l) {
    if (!l.new_value) {
      return i18n.global.t("logs.removeTaskStage", { value: l.old_value })
    }
    if (!l.old_value) {
      return i18n.global.t("logs.addTaskStage", { value: l.new_value })
    }
    return i18n.global.t("logs.changeTaskStage", { value1: l.old_value, value2: l.new_value })
  },
  location(l) {
    if (!l.new_value) {
      return i18n.global.t("logs.removeLocation", { value: l.old_value })
    }
    if (!l.old_value) {
      return i18n.global.t("logs.addLocation", { value: l.new_value })
    }
    return i18n.global.t("logs.changeLocation", { value1: l.old_value, value2: l.new_value })
  },
  body(l) {
    return i18n.global.t("logs.changeBody")
  },
  state(l) {
    const stateMap = {
      neutral: i18n.global.t("logs.states.neutral"),
      confirmed: i18n.global.t("logs.states.confirmed"),
      took_place: i18n.global.t("logs.states.tookPlace"),
      cancelled: i18n.global.t("logs.states.canceled"),
    }
    return i18n.global.t("logs.changeState", { value: stateMap[l.new_value] })
  },
  note(l) {
    return i18n.global.t("logs.changeBody")
  },
  deal_stage(l) {
    if (!l.new_value) {
      return i18n.global.t("logs.removeDealStage", { value: l.old_value })
    }
    if (!l.old_value) {
      return i18n.global.t("logs.addDealStage", { value: l.new_value })
    }
    return i18n.global.t("logs.changeDealStage", { value1: l.old_value, value2: l.new_value })
  },
  price(l) {
    return i18n.global.t("logs.changePrice", { value1: l.old_value || 0, value2: l.new_value || 0 })
  },
  feeling(l) {
    return i18n.global.t("logs.changeFeeling", { value1: l.old_value, value2: l.new_value })
  },
  start_date(l) {
    if (!l.new_value) {
      return i18n.global.t("logs.removeStartDate", { value: moment(l.old_value).format("l") })
    }
    if (!l.old_value) {
      return i18n.global.t("logs.addStartDate", { value: moment(l.new_value).format("l") })
    }
    return i18n.global.t("logs.changeStartDate", {
      value1: moment(l.old_value).format("l"),
      value2: moment(l.new_value).format("l"),
    })
  },
  deleted_at(l) {
    if (l.new_value) {
      return i18n.global.t("logs.deleted")
    } else {
      return i18n.global.t("logs.restored")
    }
  },
  viewings(l) {
    if (l.new_value) {
      return i18n.global.t("logs.addViewing", { value: l.new_value })
    } else {
      return i18n.global.t("logs.removeViewing", { value: l.old_value })
    }
  },
  viewings_attendance(l) {
    if (l.new_value) {
      return i18n.global.t("logs.addViewingAttendance." + (l.new_value.attendance == true ? "confirmed" : "canceled"), {
        value: l.new_value.participant,
      })
    }
  },
}

export const formatLog = (resource, brokers) => {
  let prettyText
  try {
    prettyText = LOG_TEXT_MAP[resource.changed_attribute](resource)
  } catch (error) {
    prettyText = ""
  }

  return {
    ...resource,
    isLog: true,
    broker: brokers.find(o => o.id === resource.broker_id),
    prettyText,
  }
}
