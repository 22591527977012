import { FormFieldType as FFT, FormField, FormFieldScope } from "@/interfaces"

const pLabelOptions = (fieldName: string) => {
  return ({ db }) => {
    const mapping = db.shopData.propertyOptionsMapping[fieldName]
    return Object.keys(mapping).map(key => ({ id: key, name: mapping[key] }))
  }
}

const FORM_FIELDS: Record<string, FormField> = {
  brokerId: {
    dbOptions: "activeBrokers",
    type: FFT.dropdown,
    notForLp: true,
    group: "general",
    filterable: true,
  },
  brokerIds: {
    type: FFT.multiselect,
    dbOptions: "brokers",
    notForLp: true,
    group: "general",
    filterable: true,
  },
  departmentIds: {
    type: FFT.multiselect,
    dbOptions: "departments",
    notForLp: true,
    group: "general",
    filterable: ({ db }) => db.hasRight("rightToSeeAllClients") && db.shopData.departments.length,
  },
  projectId: {
    dbOptions: "projects",
    type: FFT.dropdown,
    notForLp: true,
    group: "general",
    filterable: true,
  },
  teamId: {
    dbOptions: "teams",
    type: FFT.dropdown,
    notForLp: true,
    group: "general",
    filterable: true,
  },
  groupIds: {
    options: ({ db }) => {
      const superGroups = db.get("superGroups")
      return db
        .get("propertyGroups")
        .map(g => {
          const superGroup = superGroups.find(sg => sg.id == g.superGroupId)
          return {
            id: g.id,
            name: superGroup ? `${superGroup.name}: ${g.name}` : g.name,
          }
        })
        .sort((a, b) => (a.name > b.name ? 1 : -1))
    },
    type: FFT.multiselect,
    notForLp: true,
    group: "general",
    filterable: true,
    filterName: "property_group_ids",
  },

  category: { component: "property-category" },
  propertyStatusId: {
    type: FFT.dropdown,
    dbOptions: "propertyStatuses",
    notForLp: true,
    group: "general",
    filterable: true,
  },
  unitId: { type: FFT.string, group: "general", filterable: true },
  exposeeId: { type: FFT.string, group: "general" },
  note: { type: FFT.text, notForLp: true, group: "general", filterable: true },
  warningNotice: { type: FFT.string, notForLp: true },
  archived: { type: FFT.boolean, notForLp: true, group: "general", filterable: true },

  scoutContactId: {
    type: FFT.dropdown,
    dbOptions: "scoutContacts",
    notForLp: true,
    group: "general",
    filterable: true,
    refetchable: true,
  },
  scoutId: { type: FFT.string, notForLp: true },
  scoutGroupNumber: { type: FFT.string, notForLp: true },

  inquiryDepartmentId: {
    type: FFT.dropdown,
    notForLp: true,
    filterable: true,
    group: "general",
    dbOptions: "departmentsForUseInInquiryDistribution",
  },

  address: { component: "property-address-fields", notForLp: true },
  // region: { label: "Region", type: FFT.dropdown, notForLp: true },
  // country: { label: "Land", type: FFT.dropdown, notForLp: true },
  lat: { type: FFT.number, precision: 7, notForLp: true },
  lng: { type: FFT.number, precision: 7, notForLp: true },
  locationId: {
    type: FFT.dropdown,
    dbOptions: "groupedLocations",
    group: "address",
    filterable: true,
    filterName: "location_ids",
  },
  hideAddress: { type: FFT.boolean, notForLp: true },
  cadastralDistrict: { type: FFT.string },
  plotNumber: { type: FFT.string },
  district: { type: FFT.string },

  courtage: { type: FFT.string, group: "general", filterable: true },
  internalBrokerage: { type: FFT.string, group: "general", filterable: true },
  courtageNote: { type: FFT.text },
  totalCommission: { type: FFT.number, filterable: true, unit: "currency", group: "general" },
  commission: { component: "property-commission", notForLp: true },

  title: { type: FFT.string, limit: 100 },
  descriptionNote: { type: FFT.text, snippets: "description_note", notForLp: true },
  locationNote: { type: FFT.text, snippets: "location_note", notForLp: true },
  furnishingNote: { type: FFT.text, snippets: "furnishing_note", notForLp: true },
  otherNote: { type: FFT.text, snippets: "other_note", notForLp: true },

  longDescriptionNote: {
    type: FFT.richtext,
    snippets: "description_note",
    notForLp: true,
  },
  longLocationNote: { type: FFT.richtext, snippets: "location_note", notForLp: true },
  longFurnishingNote: { type: FFT.richtext, snippets: "furnishing_note", notForLp: true },
  longOtherNote: { type: FFT.richtext, snippets: "other_note", notForLp: true },

  distanceToPt: { type: FFT.number, unit: "Min." },
  distanceToFm: { type: FFT.number, unit: "Min." },
  distanceToMrs: { type: FFT.number, unit: "Min." },
  distanceToAirport: { type: FFT.number, unit: "Min." },
  distanceToPtInKm: { type: FFT.number, unit: "km" },
  distanceToFmInKm: { type: FFT.number, unit: "km" },
  distanceToMrsInKm: { type: FFT.number, unit: "km" },
  distanceToAirportInKm: { type: FFT.number, unit: "km" },

  livingSpace: { type: FFT.number, unit: "area", group: "area", filterable: true },
  totalFloorSpace: { type: FFT.number, unit: "area" },
  plotArea: { type: FFT.number, unit: "area", group: "area", filterable: true },
  numberOfRooms: { type: FFT.number, precision: 1, group: "area", filterable: true },
  numberOfBedRooms: { type: FFT.number, group: "area", filterable: true },
  numberOfBathRooms: { type: FFT.number },
  floor: { type: FFT.number, group: "general", filterable: true },
  numberOfFloors: { type: FFT.number },
  floorPosition: { type: FFT.string, group: "general", filterable: true },
  usableFloorSpace: { type: FFT.number, unit: "area" },
  netFloorSpace: { type: FFT.number, unit: "area" },
  cellarSpace: { type: FFT.number, unit: "area" },
  balconySpace: { type: FFT.number, unit: "area" },
  gardenSpace: { type: FFT.number, unit: "area" },
  minDivisible: { type: FFT.number, unit: "area", group: "area", filterable: true },
  numberOfBalconies: { type: FFT.number },
  numberOfTerraces: { type: FFT.number },
  cubature: { type: FFT.number, unit: "m³" },
  numberSeats: { type: FFT.number },
  lodgerFlat: { type: FFT.boolean },

  price: { component: "exchange-field", type: FFT.number, unit: "currency", group: "general", filterable: true },
  parkingSpacePrice: { type: FFT.number, unit: "currency", group: "general", filterable: true },
  priceOnInquiry: { type: FFT.boolean },
  forBidding: { type: FFT.boolean },
  baseRent: { component: "exchange-field", type: FFT.number, unit: "currency", group: "general", filterable: true },
  totalRent: { type: FFT.number, unit: "currency", group: "general", filterable: true },
  heatingCosts: { type: FFT.number, unit: "currency" },
  serviceCharge: { type: FFT.number, unit: "currency" },
  heatingCostsInServiceCharge: { type: FFT.boolean },
  rentSubsidy: { type: FFT.number, unit: "currency" },
  otherCosts: { type: FFT.number, unit: "currency" },
  maintenanceReserve: { type: FFT.number, unit: "currency" },
  vat: { type: FFT.number, unit: "currency" },
  plusVat: { type: FFT.boolean },
  priceType: { type: FFT.dropdown, options: pLabelOptions("price_type") },
  currency: {
    type: FFT.dropdown,
    group: "general",
    filterable: ({ db }) => {
      return db.shopData.selectedCurrencies?.length
    },
    options: ({ db }) => {
      return db.shopData.selectedCurrencies?.map(g => ({ id: g, name: g })) || []
    },
  },

  areaUnit: {
    type: FFT.dropdown,
    options: () => [
      { id: "sqm", name: "m²" },
      { id: "sqft", name: "ft²" },
    ],
  },
  rented: { type: FFT.boolean, group: "equipment", filterable: true },
  freeFrom: { type: FFT.string, group: "general" },
  numberOfParkingSpaces: { type: FFT.number, group: "general", filterable: true },
  parkingSpaceTypes: {
    type: FFT.multiselect,
    options: pLabelOptions("parking_space_type"),
    group: "general",
    filterable: true,
    filterName: "parking_space_types.keyword"
  },
  condition: {
    type: FFT.dropdown,
    options: pLabelOptions("condition"),
    group: "general",
    filterable: true,
  },
  interiorQuality: {
    type: FFT.dropdown,
    options: pLabelOptions("interior_quality"),
  },
  lastRefurbishment: { type: FFT.number, unit: "year" },

  tenancy: { type: FFT.number, unit: "Jahre" },
  commercializationType: {
    type: FFT.dropdown,
    options: pLabelOptions("commercialization_type"),
  },
  shortTermConstructible: { type: FFT.boolean },
  utilizationTradeSite: {
    type: FFT.dropdown,
    options: pLabelOptions("utilization_trade_site"),
  },
  recommendedUseTypes: {
    type: FFT.multiselect,
    options: pLabelOptions("recommended_use_types"),
    group: "general",
    filterable: true,
  },
  siteDevelopmentType: {
    type: FFT.dropdown,
    options: pLabelOptions("site_development_type"),
  },
  siteConstructibleType: {
    type: FFT.dropdown,
    options: pLabelOptions("site_constructible_type"),
  },
  grz: { type: FFT.number },
  gfz: { type: FFT.number },
  bgf: { type: FFT.number },
  bmz: { type: FFT.number },
  buildingPermission: { type: FFT.boolean },
  preliminaryEnquiry: { type: FFT.boolean },
  demolition: { type: FFT.boolean },
  constructionPhase: { type: FFT.dropdown, options: pLabelOptions("construction_phase") },

  widthGarage: { type: FFT.number, unit: "m" },
  heightGarage: { type: FFT.number, unit: "m" },
  lengthGarage: { type: FFT.number, unit: "m" },
  customPricePerSqm: { type: FFT.number, unit: "currency" },
  numberOfApartments: { type: FFT.number },
  numberOfCommercials: { type: FFT.number },
  numberOfUnits: { type: FFT.number },
  rentalIncome: { type: FFT.number, unit: "currency" },
  rentalIncomeTarget: { type: FFT.number, unit: "currency" },
  rentalIncomeActual: { type: FFT.number, unit: "currency" },
  priceMultiplierTarget: {
    type: FFT.number,
    precision: 1,
  },
  priceMultiplier: {
    type: FFT.number,
    precision: 1,
  },
  yieldTarget: {
    type: FFT.number,
    unit: "%",
  },
  yieldActual: {
    type: FFT.number,
    unit: "%",
  },
  investmentCategory: {
    type: FFT.multiselect,
    options: pLabelOptions("investment_category"),
  },
  purchaseForm: { type: FFT.multiselect, options: pLabelOptions("purchase_form") },
  industrialArea: { type: FFT.number, unit: "area" },
  additionalArea: { type: FFT.number, unit: "area" },
  tenantStructure: {
    type: FFT.dropdown,
    options: pLabelOptions("tenant_structure"),
  },
  walt: { type: FFT.dropdown, options: pLabelOptions("walt") },
  numberOfSingleRooms: { type: FFT.number },
  singleRoomsQuota: { type: FFT.number, unit: "%" },
  occupancyRate: { type: FFT.number, unit: "%" },
  numberOfVacancies: { type: FFT.number },
  conservationAreas: { type: FFT.boolean },

  locationClassificationType: {
    type: FFT.dropdown,
    options: pLabelOptions("location_classification_type"),
  },
  shopWindowLength: { type: FFT.number, unit: "m" },
  goodsLift: { type: FFT.boolean },
  goodsLiftLoad: { type: FFT.number, unit: "kg" },
  floorLoad: { type: FFT.number, unit: "kg/m²" },
  supplyType: { type: FFT.dropdown, options: pLabelOptions("supply_type") },
  lanCables: { type: FFT.dropdown, options: pLabelOptions("lan_cables") },
  hasCanteen: { type: FFT.boolean },
  ramp: { type: FFT.boolean },
  autoLift: { type: FFT.boolean },
  hallHeight: { type: FFT.number, unit: "m" },
  connectedLoad: { type: FFT.number, unit: "kVA" },
  craneRunwayLoad: { type: FFT.number, unit: "Tonnen" },

  rentDurations: { type: FFT.multiselect, options: pLabelOptions("rent_durations") },
  priceIntervalType: { type: FFT.dropdown, options: pLabelOptions("price_interval_type") },
  startRentalDate: { type: FFT.date },
  endRentalDate: { type: FFT.date },
  minRentalTime: { type: FFT.number, unit: "Monate" },
  maxRentalTime: { type: FFT.number, unit: "Monate" },
  maxNumberOfPersons: { type: FFT.number },
  hasFurniture: { type: FFT.boolean },
  gender: { type: FFT.dropdown, options: pLabelOptions("gender") },

  lift: { type: FFT.boolean, group: "equipment", filterable: true },
  barrierFree: { type: FFT.boolean, group: "equipment", filterable: true },
  cellar: { type: FFT.boolean },
  guestToilet: { type: FFT.boolean },
  builtInKitchen: { type: FFT.boolean, group: "equipment", filterable: true },
  balcony: { type: FFT.boolean, group: "equipment", filterable: true },
  loggia: { type: FFT.boolean },
  garden: { type: FFT.boolean, group: "equipment", filterable: true },
  monument: { type: FFT.boolean },
  summerResidencePractical: {
    type: FFT.boolean,
    group: "equipment",
    filterable: true,
  },
  storeroom: { type: FFT.boolean },
  chimney: { type: FFT.boolean },
  swimmingPool: {
    type: FFT.boolean,
    group: "equipment",
    filterable: true,
  },
  sauna: { type: FFT.boolean },
  alarmSystem: { type: FFT.boolean },
  winterGarden: { type: FFT.boolean },
  airConditioning: { type: FFT.boolean },
  bathroom: { type: FFT.multiselect, options: pLabelOptions("bathroom") },
  flooringType: { type: FFT.multiselect, options: pLabelOptions("flooring_type") },
  outsideView: { type: FFT.dropdown, options: pLabelOptions("outside_view") },
  petsAllowed: { type: FFT.dropdown, options: pLabelOptions("pets_allowed") },
  nonSmoker: { type: FFT.boolean },
  certificateOfEligibilityNeeded: { type: FFT.boolean },
  flatShareSuitable: { type: FFT.boolean, group: "equipment", filterable: true },

  energyCertificateAvailability: {
    options: pLabelOptions("energy_certificate_availability"),
    component: "property-energy-certificate",
  },
  energyCertificateCreationDate: {
    type: FFT.dropdown,
    options: pLabelOptions("energy_certificate_creation_date"),
  },
  energyCertificateStartDate: { type: FFT.string },
  energyCertificateEndDate: { type: FFT.string },
  buildingEnergyRatingType: {
    type: FFT.dropdown,
    options: pLabelOptions("building_energy_rating_type"),
  },
  energyEfficiencyClass: {
    type: FFT.dropdown,
    options: pLabelOptions("energy_efficiency_class"),
  },
  thermalCharacteristic: { type: FFT.string },
  thermalCharacteristicElectricity: { type: FFT.number },
  thermalCharacteristicHeating: { type: FFT.number },
  coEmission: { type: FFT.number },
  energyConsumptionContainsWarmWater: {
    type: FFT.boolean,
  },
  heatingType: { type: FFT.dropdown, options: pLabelOptions("heating_type") },
  firingTypes: {
    type: FFT.dropdown,
    options: pLabelOptions("firing_types"),
  },
  constructionYear: { type: FFT.number, unit: "year", group: "general", filterable: true },
  equipmentTechnologyConstructionYear: { type: FFT.number, unit: "year", group: "general", filterable: true },
  constructionYearUnknown: { type: FFT.boolean },
  autHwb: { type: FFT.number },
  autHwbClass: { type: FFT.dropdown, options: pLabelOptions("aut_hwb_class") },
  autFgee: { type: FFT.number },
  autFgeeClass: { type: FFT.dropdown, options: pLabelOptions("aut_fgee_class") },
  autMainRent: { type: FFT.number, unit: "currency" },
  autCadastralCommunity: { type: FFT.string },

  owners: {
    component: "owner-field",
    scope: FormFieldScope.owner,
    type: FFT.relationship,
    group: "links",
    filterable: true,
    filterName: "owners.id",
  },
  partners: {
    component: "owner-field",
    scope: FormFieldScope.partner,
    type: FFT.relationship,
    notForLp: true,
  },

  portals: { component: "property-portal-export", notForLp: true },
  translations: { component: "property-translations", notForLp: true },
  commissionSplits: { component: "property-commission-splits", notForLp: true },

  valuationPriceFrom: { type: FFT.number, unit: "currency", column: { width: 100 } },
  valuationPriceTo: { type: FFT.number, unit: "currency", column: { width: 100 } },
  valuationPrice: { type: FFT.number, unit: "currency" },
  soldPrice: { type: FFT.number, unit: "currency", group: "general", filterable: true },
  soldDate: { type: FFT.date, group: "general", filterable: true },
  costBalcony: { type: FFT.number },
  costLift: { type: FFT.number },
  costOther: { type: FFT.number },
  parkingSpaceNumber: { type: FFT.string },
  apartmentNumber: { type: FFT.string, filterable: true, group: "general" },
  teId: { type: FFT.string },
  coOwnershipShare: { type: FFT.string },
  localCourt: { type: FFT.string },
  registerNumber: { type: FFT.string, filterable: true, group: "general" },
  landRegistry: { type: FFT.string },
  contractType: {
    type: FFT.dropdown,
    options: pLabelOptions("contract_type"),
    group: "general",
    filterable: true,
  },
  duration: { type: FFT.dropdown, options: pLabelOptions("duration") },
  durationFrom: { type: FFT.date, group: "general", filterable: true },
  durationUntil: { type: FFT.date, group: "general", filterable: true },
  deposit: { type: FFT.string, group: "general", filterable: true },
  renter: { type: FFT.string },

  customLpAddress: { type: FFT.string, notForLp: true },
  landingPageBlocked: { type: FFT.boolean, notForLp: true },

  openimmoFirstname: { type: FFT.string, notForLp: true },
  openimmoLastname: { type: FFT.string, notForLp: true },
  openimmoEmail: { type: FFT.string, notForLp: true },
  openimmoPhone: { type: FFT.string, notForLp: true },

  compensationCosts: { type: FFT.number, unit: "currency" },
  recurringCosts: { type: FFT.number, unit: "currency" },
  recurringCostsNet: { type: FFT.number, unit: "currency" },
  recurringCostsVat: { type: FFT.number, unit: "currency" },
  heatingCostsNet: { type: FFT.number, unit: "currency" },
  heatingCostsVat: { type: FFT.number, unit: "currency" },
  serviceChargeNet: { type: FFT.number, unit: "currency" },
  serviceChargeVat: { type: FFT.number, unit: "currency" },
  otherCostsNet: { type: FFT.number, unit: "currency" },
  otherCostsVat: { type: FFT.number, unit: "currency" },
  priceNet: { type: FFT.number, unit: "currency" },
  priceVat: { type: FFT.number, unit: "currency" },
  baseRentNet: { type: FFT.number, unit: "currency" },
  baseRentVat: { type: FFT.number, unit: "currency" },
  totalRentNet: { type: FFT.number, unit: "currency" },
  totalRentVat: { type: FFT.number, unit: "currency" },
  otherRentNet: { type: FFT.number, unit: "currency" },
  otherRentVat: { type: FFT.number, unit: "currency" },
  financialContribution: { type: FFT.number, unit: "currency" },
  constructionType: { type: FFT.dropdown, options: pLabelOptions("construction_type") },
  facebookPostDescription: { type: FFT.text },
  instagramPostDescription: { type: FFT.text },
  autoPortalExportConnectionIds: { type: FFT.multiselect, dbOptions: "connections" },
  marketingStartDate: { type: FFT.date },
}

export default FORM_FIELDS
