import { Broker, CustomFieldGroup } from "@/interfaces/db"

export const ACTIVITY_TYPES = [
  { value: "message" },
  { value: "note" },
  { value: "todo" },
  { value: "event" },
  { value: "decision" },
]

export const ACTION_TYPES = [{ value: "browser" }, { value: "background" }]

export const MOMENTS: Moments[] = [
  { label: "now", value: "now" },
  { label: "3h", value: "3 hours from now" },
  { label: "12h", value: "12 hours from now" },
  { label: "24h", value: "24 hours from now" },
  { label: "tmr", value: "tomorrow at 9am" },
  { label: "afterTmr", value: "2 days from now at 9am" },
  ...Array.from({ length: 7 }, (_v, days) => ({
    label: "customDays",
    value: `${days + 3} days from now at 9am`,
    days: days + 3,
  })),
  { label: "monday", value: "Monday at 9am" },
  { label: "2weeks", value: "14 days from now at 9am" },
  { label: "3weeks", value: "21 days from now at 9am" },
  { label: "1month", value: "1 month from now at 9am" },
  { label: "3months", value: "3 months from now at 9am" },
  { label: "6months", value: "6 months from now at 9am" },
]

export const EVENT_MOMENTS = [
  { label: "2daysBefore", value: "{{ zwei_werktage_vor_termin }}" },
  { label: "1dayBefore", value: "{{ werktag_vor_termin }}" },
  { label: "today", value: "{{ termin_start }}" },
]

export interface BrokerOption {
  id: string | number
  name: string
  translate?: boolean
}

export interface Moments {
  label: string
  value: string
  days?: number
}

export const getBrokerOptions = (
  {
    brokers,
    customFieldGroupsForProperties,
    customFieldGroupsForBrokers,
  }: {
    brokers: Broker[]
    customFieldGroupsForProperties: CustomFieldGroup[]
    customFieldGroupsForBrokers: CustomFieldGroup[]
  },
  t
) => {
  const variableBrokers: BrokerOption[] = [
    { name: "propertyBroker", id: "{{ property__broker_id }}", translate: true },
    { name: "projectBroker", id: "{{ project__broker_id }}", translate: true },
    { name: "clientBroker", id: "{{ client__broker_id }}", translate: true },
  ]

  const propertyCustomFieldBrokers = customFieldGroupsForProperties.reduce((acc: BrokerOption[], group) => {
    return acc.concat(
      group.customFields
        .filter(cf => cf.fieldType == "Broker")
        .map(cf => ({ name: cf.prettyName, id: `{{ property__custom_fields__${cf.name} }}` }))
    )
  }, [])

  const brokerCustomFieldBrokers = customFieldGroupsForBrokers.reduce((acc: BrokerOption[], group) => {
    return acc.concat(
      group.customFields
        .filter(cf => cf.fieldType == "Broker")
        .map(cf => ({
          name: `${t("deals.form.user")} → ${cf.prettyName}`,
          id: `{{ broker__custom_fields__${cf.name} }}`,
        }))
    )
  }, [])

  const activeBrokers = brokers.filter(b => b.active).map(b => ({ id: b.id, name: b.internalName }))

  return variableBrokers.concat(propertyCustomFieldBrokers).concat(brokerCustomFieldBrokers).concat(activeBrokers)
}
