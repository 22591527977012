<template>
  <div>
    <form-row :title="labels.type">
      <nice-select
        :placeholder="labels.typePlaceholder"
        v-model="fields.activity_type"
        :options="selectableItemTypes"
        @change="fields.categories = []"
      />
    </form-row>

    <form-row v-if="isMessageActivity" :title="labels.messageDirection.title">
      <nice-select
        :placeholder="labels.messageDirection.senderTypePlaceholder"
        v-model="fields.sender_type"
        :options="messageDirectionOptions"
      />
    </form-row>

    <form-row v-if="selectableCategories.length" :title="labels.category.title">
      <nice-select
        multiple
        v-model="fields.categories"
        :placeholder="labels.category.placeholder"
        :options="selectableCategories"
      />
    </form-row>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue"
import { useI18n } from "vue-i18n"
import useCore from "@/plugins/use-core"
import useActivityTypes from "@/composables/use-activity-types"

const { fields } = defineProps<{
  fields: {
    activity_type: string | undefined
    sender_type: string | undefined
    categories: string[] | undefined
  }
}>()

const isMessageActivity = computed(() => fields.activity_type === "message")

const { t } = useI18n()
const selectableItemTypes = useActivityTypes()

const labels = {
  type: t("activityTypes.activitiesFilter.type"),
  typePlaceholder: t("activityTypes.activitiesFilter.selectTypePlaceholder"),
  messageDirection: {
    title: t("activityTypes.activitiesFilter.messageDirection.title"),
    senderTypePlaceholder: t("activityTypes.activitiesFilter.messageDirection.senderTypePlaceholder"),
  },
  category: {
    title: t("activityTypes.activitiesFilter.category.title"),
    placeholder: t("activityTypes.activitiesFilter.category.placeholder"),
  },
}
const messageDirectionOptions = {
  "1": t("activityTypes.activitiesFilter.messageDirection.senderType.incoming"),
  "2": t("activityTypes.activitiesFilter.messageDirection.senderType.outgoing"),
}

const categoriesDictionary = {
  message: ["messageCategories"],
  event: ["eventCategories"],
  reminder: ["todoCategories"],
  note: ["noteCategories"],
  cancelation: ["cancelationReasons"],
  inquiry: ["clientSources"],
  none: ["messageCategories", "eventCategories", "todoCategories", "noteCategories", "letterCategories"],
}

const { db } = useCore()
const selectableCategories = computed(() => {
  let categoryOptions = []
  const categoryOptionsList = !fields.activity_type
    ? categoriesDictionary.none
    : categoriesDictionary[fields.activity_type] || []
  for (const category of categoryOptionsList) {
    categoryOptions = categoryOptions.concat(db.get(category))
  }
  return categoryOptions
})
</script>
