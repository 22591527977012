<script setup lang="ts">
import { Integration, IntegrationMcGrundriss } from "@/integrations/types/integration-service"
import { get, toRefs } from "@vueuse/core"
import { computed } from "vue"
import { IntegrationMapping } from "@/integrations/types/integration-page"
import { IntegrationOrderMcGrundriss } from "@/integrations/types/integration-mcgrundriss"
import { IntegrationOrderStepAction, IntegrationOrderStepDescriptor } from "@/integrations/types/integration-order"
import { Image } from "@/integrations/types/propstack"
import useCore from "@/plugins/use-core"
import { useI18n } from "vue-i18n"
import { useOneSystem } from "@/integrations/use-one-system"

const props = defineProps<{
  integration: Integration
  mapping: IntegrationMapping<IntegrationMcGrundriss>
  isEnabled: boolean
  currentOrder?: IntegrationOrderMcGrundriss
  currentOrderStep?: IntegrationOrderStepDescriptor<IntegrationOrderStepAction>
  floorPlans?: Image[]
  maxVisibleOrderSteps?: number
}>()

const emit = defineEmits(["configure", "delete", "goto"])
const { t } = useI18n()
const { db } = useCore()
const { isOneSystem } = useOneSystem()

const { mapping, isEnabled, currentOrder, currentOrderStep, floorPlans, maxVisibleOrderSteps } = toRefs(props)
const isActiveOrder = computed(() => get(isEnabled) && !!get(currentOrder))
const isAdmin = computed(() => db.broker.admin)

const buttonLabelMapping = {
  started: "edit",
  completed: "requestChange",
  confirmed: "edit",
  "in-progress": "edit",
  "clarification-required": "feedback",
  "changes-reported": "edit",
  "changes-in-progress": "edit",
}
const buttonLabel = computed(() => {
  const orderNowKey = get(isOneSystem) ? "orderNowOneSystem" : "orderNow"
  if (!get(isActiveOrder)) return t(`integrations.actions.${get(isEnabled) ? orderNowKey : "setupNow"}`)
  const _currentOrderStep = get(currentOrderStep)
  if (!_currentOrderStep || _currentOrderStep.stepNumber === 0) return
  return t(`integrations.integration-mcgrundriss.order.actions.${buttonLabelMapping[_currentOrderStep.id]}`)
})

const buttonTooltip = computed(() => {
  if (!get(isEnabled) && !get(isAdmin)) return t("integrations.configuration.forbidden")

  return t("integrations.integration-mcgrundriss.order.tooltip.imageRequired")
})

const isButtonDisabled = computed(() => {
  return get(isEnabled) ? !get(isActiveOrder) && !get(floorPlans)?.length : !get(isAdmin)
})

const buttonClicked = () => {
  if (get(isActiveOrder)) {
    emit("goto")
    return
  }
  emit("configure")
}

const percentage = computed(() => {
  const _currentOrderStep = get(currentOrderStep)
  const _maxVisibleOrderSteps = get(maxVisibleOrderSteps)
  if (!_currentOrderStep || !_maxVisibleOrderSteps || !_currentOrderStep.stepNumber) return
  return (_currentOrderStep.stepNumber / _maxVisibleOrderSteps) * 100
})

const isValidProgressStep = computed(() => {
  const _currentOrderStep = get(currentOrderStep)
  return !!_currentOrderStep?.stepNumber && _currentOrderStep.isProgressStep && _currentOrderStep.stepNumber !== 0
})
</script>
<template>
  <div
    class="disabled-hover relative flex flex-col border border-gray-300 p-3 rounded text-left mb-3 max-w-72"
    style="column-break-inside: avoid; break-inside: avoid"
  >
    <div class="flex justify-center w-full py-4">
      <img :src="mapping.imageUrl" :alt="mapping.name" class="max-h-10 max-w-32" />
    </div>
    <div v-if="isEnabled && !!currentOrder">
      <div v-if="isValidProgressStep">
        <div class="mt-3">
          <span class="w-100 text-sm">
            {{
              t("integrations.orders.progress.steps", {
                x: currentOrderStep?.stepNumber,
                max: maxVisibleOrderSteps,
              })
            }}
          </span>
          <nice-progress
            :percentage="percentage"
            :show-text="false"
            style="flex: 1"
            :status="percentage === 100 ? 'success' : null"
          />
        </div>
        <div v-if="currentOrderStep?.description" class="mt-3">{{ $t(currentOrderStep.description) }}</div>
      </div>
      <div v-else class="flex justify-center w-full mt-3">
        <fa-icon name="info-circle" class="mt-2" />
        <span v-if="currentOrderStep?.description" class="ml-3">{{ t(currentOrderStep.description) }}</span>
      </div>
      <!-- <div class="d-flex justify-content-center mt-3">
        <nice-button type="info" @click="emit('delete')">Bestellung löschen (DEBUG)</nice-button>
      </div> -->
    </div>
    <div v-else>
      <span v-if="isOneSystem">
        {{ $t("integrations.integration-mcgrundriss.widget.oneSystem") }}
      </span>
      <span v-else>
        {{ $t("integrations.integration-mcgrundriss.widget.text") }}
      </span>
    </div>
    <div class="d-flex justify-content-center mt-3" v-if="buttonLabel">
      <nice-tooltip :disabled="!isButtonDisabled" :content="buttonTooltip">
        <div>
          <nice-button :disabled="isButtonDisabled" @click="buttonClicked">
            {{ buttonLabel }}
          </nice-button>
        </div>
      </nice-tooltip>
    </div>
  </div>
</template>
