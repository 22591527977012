<script setup lang="ts">
import { IntegrationMapping } from "@/integrations/types/integration-page"
import { useI18n } from "vue-i18n"

const props = defineProps<{
  mapping: IntegrationMapping<any>
  consentGiven: boolean
  consentRequired: boolean
  // isAdmin: boolean
  allowEnable?: boolean
  enabled?: boolean
  hasPendingChanges?: boolean
  isConfigured?: boolean
  showDeactivateButton?: boolean
}>()

const emit = defineEmits(["disable", "enable", "save", "cancel", "deactivate"])

const { t } = useI18n()
</script>
<template>
  <div v-if="props.enabled" class="flex mb-3">
    <nice-alert class="mr-3 w-full text-green-600">
      {{ $t(`integrations.actions.connected`, { name: props.mapping.name }) }}
    </nice-alert>
    <nice-button type="danger" @click="emit('disable')">
      {{ $t("integrations.actions.disable") }}
    </nice-button>
  </div>
  <div v-else class="pull-right">
    <div class="inline-block mr-2" v-if="showDeactivateButton">
      <nice-button type="info" @click="emit('deactivate')">
        {{ $t("integrations.actions.deactivate") }}
      </nice-button>
    </div>
    <nice-tooltip
      :disabled="props.consentGiven"
      :content="!props.consentGiven ? t('integrations.consent.missing') : ''"
    >
      <div class="inline-block">
        <nice-button type="primary" @click="emit('enable')" :disabled="!props.consentGiven || hasPendingChanges">
          {{ $t("integrations.actions.enable") }}
        </nice-button>
      </div>
    </nice-tooltip>
  </div>
</template>
