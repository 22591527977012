<template>
  <div class="empty-wrapper">
    <div class="empty-content">
      <img v-if="imageName" :src="'/onboarding/' + imageName" class="empty-content-icon" />
      <h5 class="empty-content-header">
        {{ title }}
      </h5>

      <p v-html="description" v-if="description" class="empty-content-desc text-center"></p>
      <p v-if="moreInfoLink" class="empty-content-desc text-center">
        <a :href="moreInfoLink" target="_blank" class="text-gray-600 text-underline">
          {{ $t(moreInfoText) }}
        </a>
      </p>
      <nice-button v-if="button" type="primary" :loading="loading" class="mt-3 w-80 text-lg" @click="emits('click')">
        {{ button }}
      </nice-button>
    </div>
  </div>
</template>

<script setup lang="ts">
const emits = defineEmits(["click"])
const {
  imageName,
  title,
  description,
  moreInfoLink,
  moreInfoText = "admin.emptyState.moreInfo",
  button,
  loading,
} = defineProps<{
  title: string
  imageName?: string
  description?: string
  moreInfoLink?: string
  moreInfoText?: string
  button?: string
  loading?: boolean
}>()
</script>
