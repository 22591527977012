<template>
  <div class="app-panel">
    <div class="tableActions" v-if="items.length">
      <div class="tableActions-group">
        <router-link v-if="isAdminPage" :to="'/admin/settings'">
          <fa-icon name="long-arrow-left" />
          {{ translationKeys.backBtn }}
        </router-link>
        <header :class="isAdminPage ? 'ml-4' : ''">
          <h1 class="text-xl">{{ translationKeys.title }}</h1>
        </header>
      </div>
      <div class="tableActions-group">
        <dropdown>
          <dropdown-section>
            <dropdown-item v-if="!editMode" @click="editMode = true">
              <span>
                <fa-icon name="pencil" class="pl-3 mr-2" />
                {{ translationKeys.edit.start }}
              </span>
            </dropdown-item>
            <dropdown-item v-if="hasChartsAndMarketingAccess && !isAdminPage" @click="addPreset">
              <fa-icon name="desktop" class="pl-3 mr-2" />
              {{ translationKeys.preset.add }}
            </dropdown-item>
            <dropdown-item v-if="isAdminPage" @click="onApplyToAll">
              <fa-icon name="check-double" class="pl-3 mr-2" />
              {{ translationKeys.applyToAll.title }}
            </dropdown-item>
            <dropdown-item @click="onReset">
              <fa-icon name="undo" class="pl-3 mr-2" />
              {{ translationKeys.reset.title }}
            </dropdown-item>
          </dropdown-section>
        </dropdown>

        <ps-button v-if="editMode" @click="saveGrid">
          <span>
            <fa-icon name="save" class="mr-2" />
            {{ translationKeys.edit.end }}
          </span>
        </ps-button>
        <a href="#" class="ml-2 btn btn-primary" @click.prevent="onAddWidget">
          <fa-icon name="plus" class="mr-2" />
          {{ translationKeys.addWidget }}
        </a>
      </div>
    </div>
    <div v-else class="flex flex-col justify-center items-center h-100">
      <h3 class="mb-3">{{ translationKeys.emptyState.text }}</h3>
      <p>
        <a @click="onAddWidget">{{ translationKeys.emptyState.addWidget1 }}</a>
        {{ translationKeys.emptyState.addWidget2 }}
      </p>
      <ps-button @click="addPreset" class="mt-5" v-if="hasChartsAndMarketingAccess && !isAdminPage">
        <span>
          <fa-icon name="desktop" class="mr-2" />
          {{ translationKeys.preset.add }}
        </span>
      </ps-button>
    </div>
    <dashboard-widget-grid
      v-if="items.length"
      :items="items"
      :config="config"
      :edit-mode="editMode"
      @layoutUpdate="items = $event"
      @resizeItem="resize"
      @editItem="editItem"
      @deleteItem="deleteItem"
    />
    <form-resource-dialog
      :label="translationKeys.preset.label"
      :submit-func="savePreset"
      @complete="presetSaved"
      :editing="preset"
      @update:editing="preset = $event"
      :autofocus="false"
      v-if="hasChartsAndMarketingAccess && preset && !isAdminPage"
    >
      <template v-slot:form="{ item }">
        <form-section>
          <form-row :title="translationKeys.preset.select" direction="column">
            <nice-select v-model="item.name" :options="presets" :fixed-position="true" />
          </form-row>
        </form-section>
        <a :href="translationKeys.preset.linkUrl" target="_blank" class="text-underline text-sm text-gray-600">
          {{ translationKeys.preset.link }}
          <fa-icon name="external-link" class="ml-1" />
        </a>
      </template>
    </form-resource-dialog>
    <widget-form-dialog
      :editing-widget="editable"
      :config="config"
      @widget-saved="itemSaved"
      @update:editing-widget="editable = $event"
      v-if="editable"
    />
  </div>
</template>

<script>
import i18n from "@/i18n"
import DashboardWidgetGrid from "@/components/dashboard/DashboardWidgetGrid.vue"
import WidgetFormDialog, { formatWidget } from "@/components/dashboard/WidgetFormDialog.vue"
import DashboardPresets from "@/config/dashboard/presets.json"
import { useTurboStorage } from "@/composables/use-turbo-storage"
import { isProxy, toRaw } from "vue"

export default {
  props: {
    isAdminPage: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      items: [],
      config: null,
      editMode: false,
      editable: null,
      preset: null,
    }
  },
  setup() {
    useTurboStorage("dashboard", ["config", "items"])
  },
  methods: {
    onAddWidget() {
      this.editable = {
        category: null,
        title: null,
        width: 1,
        height: 2,
        positionX: 0,
        positionY: (Math.max(0, ...this.items.map(o => o.positionY)) || 1) + 2,
        global: this.isAdminPage,
      }
    },
    onReset() {
      if (confirm(this.translationKeys.reset.hint)) {
        this.$api
          .mutation(this.apiConfig.reset.mutation, undefined, this.apiConfig.reset.responseQuery)
          .then(response => {
            App.flashy(this.translationKeys.reset.success)
            this.items = this.isAdminPage ? [] : response.dashboardWidgets.map(formatWidget)
          })
          .catch(this.$axios.handleError)
      }
    },
    onApplyToAll() {
      if (confirm(this.translationKeys.applyToAll.hint)) {
        this.$api
          .mutation(this.apiConfig.applyToAll.mutation)
          .then(() => App.flashy(this.translationKeys.applyToAll.success))
          .catch(this.$axios.handleError)
      }
    },
    async fetchData() {
      const { dashboardWidgetsConfig, broker, shop } = await this.$graphql(this.apiConfig.fetchData.query)
      this.config = dashboardWidgetsConfig

      const widgets = this.isAdminPage ? shop.globalDashboardWidgets : broker.dashboardWidgets

      const formattedItems = widgets
        .filter(({ category }) => category !== "charts" || this.hasChartsAndMarketingAccess)
        .map(formatWidget)

      this.items = formattedItems
    },
    saveGrid() {
      this.$api
        .mutation(
          this.apiConfig.saveGrid.mutation,
          { items: this.items, global: this.isAdminPage },
          this.apiConfig.saveGrid.responseQuery
        )
        .then(() => {
          App.flashy(this.translationKeys.saveGrid)
          this.editMode = false
        })
        .catch(this.$axios.handleError)
    },
    itemSaved({ id }) {
      if (this.editable.id) {
        this.items = this.items.map(o => (o.id == this.editable.id ? this.editable : o))
      } else {
        this.items.push(formatWidget({ ...this.editable, id }))
        this.$nextTick(_ => {
          document.querySelector(`.js-dashboard-grid-item[data-id='${id}']`).scrollIntoView(false)
        })
      }
    },
    editItem(item) {
      this.editable = structuredClone(isProxy(item) ? toRaw(item) : item)
    },
    deleteItem(item) {
      this.$warn(
        {
          title: this.translationKeys.delete.title,
          desc: this.translationKeys.delete.desc,
          confirmButtonText: this.translationKeys.delete.confirmButtonText,
          redButton: true,
        },
        () => {
          this.items = this.items.filter(o => o.id !== item.id)
          App.flashy(this.translationKeys.delete.success)
          if (item.id.startsWith?.("t")) return
          this.$api.destroy(this.apiConfig.delete.mutation, item.id).catch(this.$axios.handleError)
        }
      )
    },
    addPreset() {
      this.preset = {}
    },
    async savePreset() {
      if (!this.preset.name) return
      const defaultHeight = 3
      const basePositionY = Math.max(0, ...this.items.map(o => o.positionY + defaultHeight))
      const maxWidth = 3
      let items = DashboardPresets[this.preset.name].map((p, i) => ({
        ...p,
        title: i18n.global.t(p.title),
        subTitle: p.subTitle ? i18n.global.t(p.subTitle) : undefined,
        positionX: (i * p.width) % (maxWidth / p.width),
        positionY: basePositionY + ~~((i * p.width) / (maxWidth / p.width)),
      }))
      const results = await Promise.all(items.map(i => this.$api.create("DashboardWidget", i)))
      items = items.map((e, i) => ({ ...e, id: results[i].id }))
      this.items.push(...items.map(formatWidget))
    },
    presetSaved() {
      this.preset = undefined
    },
    resize(item) {
      item.key = Date.now()
    },
  },
  computed: {
    hasChartsAndMarketingAccess() {
      return this.$db.shopData.activeFeatures.includes("dashboard_charts") && this.$db.hasRight("rightToSeeMarketing")
    },
    translationKeys() {
      return {
        title: this.$t(
          this.isAdminPage ? "settings.defaultDashboardConfigurationPage.title" : "dashboard.title",
          this.isAdminPage
            ? {}
            : {
                name: this.$db.broker.firstName || this.$db.broker.name,
              }
        ),
        backBtn: this.$t("settings.back"),
        emptyState: {
          text: this.$t("dashboard.emptystate.text"),
          addWidget1: this.$t("dashboard.emptystate.addWidget1"),
          addWidget2: this.$t("dashboard.emptystate.addWidget2"),
        },
        save: this.$t("settings.defaultDashboardConfigurationPage.save"),
        addWidget: this.$t(
          this.isAdminPage ? "settings.defaultDashboardConfigurationPage.addWidget" : "dashboard.addWidget"
        ),
        edit: {
          start: this.$t("dashboard.startEdit"),
          end: this.$t("dashboard.endEdit"),
        },
        preset: {
          add: this.$t("dashboard.addPreset"),
          label: this.$t("dashboard.presets.label"),
          select: this.$t("dashboard.presets.select"),
          link: this.$t("dashboard.presets.link"),
          linkUrl: "https://www.youtube.com/watch?v=jxs-aaeN_OA",
        },
        saveGrid: this.$t("settings.defaultDashboardConfigurationPage.saveSuccess"),
        applyToAll: {
          title: this.$t("settings.defaultDashboardConfigurationPage.applyToAll"),
          hint: this.$t("settings.defaultDashboardConfigurationPage.applyToAllConfirmation"),
          success: this.$t("settings.defaultDashboardConfigurationPage.applyToAllSuccess"),
        },
        reset: {
          title: this.$t(
            this.isAdminPage ? "settings.defaultDashboardConfigurationPage.reset" : "dashboard.reset.title"
          ),
          hint: this.$t(
            this.isAdminPage ? "settings.defaultDashboardConfigurationPage.resetHint" : "dashboard.reset.hint"
          ),
          success: this.$t(
            this.isAdminPage ? "settings.defaultDashboardConfigurationPage.resetSuccess" : "dashboard.reset.success"
          ),
        },
        delete: {
          title: this.$t("dashboard.deleteWidget.title"),
          desc: this.$t("dashboard.deleteWidget.desc"),
          confirmButtonText: this.$t("dashboard.deleteWidget.confirmButtonText"),
          success: this.$t("dashboard.deleteWidget.success"),
        },
      }
    },
    apiConfig() {
      const widgetsQuery = `dashboardWidgets {
        id category title subTitle color
        width height positionX positionY
        brokerIds departmentIds categoryIds
        daysStart daysEnd hideWithoutDueDate location
        chartConfig source inboxBrokerIds global
      }`
      const brokerDashboardWidgetsQuery = `broker { ${widgetsQuery} }`
      const globalDashboardWidgetsQuery = `shop {
        ${widgetsQuery.replace("dashboardWidgets", "globalDashboardWidgets")}
      }`
      const fetchDashboardWidgetsQuery = `query dashboardWidgets {
          dashboardWidgetsConfig
          ${this.isAdminPage ? globalDashboardWidgetsQuery : brokerDashboardWidgetsQuery}
        }
      `
      return {
        saveGrid: {
          mutation: "sortDashboardWidget",
          responseQuery: `dashboardWidgets { id }`,
        },
        applyToAll: {
          mutation: "applyDefaultDashboardWidget",
        },
        reset: {
          mutation: this.isAdminPage ? "resetGlobalDashboardWidgets" : "resetPersonalDashboardWidgets",
          responseQuery: this.isAdminPage ? undefined : widgetsQuery,
        },
        delete: {
          mutation: "DashboardWidget",
        },
        fetchData: {
          query: fetchDashboardWidgetsQuery,
        },
      }
    },
    presets() {
      return Object.keys(DashboardPresets).map(key => ({
        id: key,
        name: i18n.global.t(`dashboard.presets.${key}.label`),
      }))
    },
  },
  created() {
    if (!this.config || !this.items.length) {
      this.fetchData()
    }
  },

  components: {
    DashboardWidgetGrid,
    WidgetFormDialog,
  },
}
</script>
