<template>
  <dropdown button-class="details-action-link">
    <dropdown-section v-if="resourceEditable">
      <dropdown-item :title="$t('client.detailDropdown.copy')" :link="`/contacts/clients/${client.id}/copy`" />
      <dropdown-item :title="$t('client.detailDropdown.merge')" @click="clientMergeVisible = true" />
    </dropdown-section>
    <dropdown-section>
      <dropdown-item v-if="resourceEditable" :title="$t('client.detailDropdown.sms')" @click="openTextMessageForm" />
      <dropdown-item v-if="resourceEditable" :title="$t('client.detailDropdown.cancel')" @click="quickviewCancel" />
      <dropdown-item
        :title="$t('client.detailDropdown.requestContactPermissions')"
        @click="requestContactPermission"
        v-if="!client.acceptContact && $db.shopData.contactAcceptanceRequestSnippetId"
      />
      <dropdown-item
        v-if="client.brokerId && client.brokerId != $db.broker.id"
        :title="$t('client.detailDropdown.msgSupervisor')"
        @click="notifyBroker"
      />
      <dropdown-item
        v-if="$db.hasRight('rightToSyncContacts')"
        has-toggle
        :title="$t('client.detailDropdown.sync')"
        :toggle-active="hasAddressBookSync"
        @update:toggle-active="saveToAddressBook"
      />
      <dropdown-item v-if="$db.broker.admin" :title="$t('client.detailDropdown.email')" @click="resyncEmails" />
      <dropdown-item v-if="$db.shopData.id == 9" :title="$t('client.detailDropdown.check')" @click="runValidators" />
    </dropdown-section>
    <worklist-actions :selected="[client.id]" entity="for_contacts" />
    <dropdown-section>
      <dropdown-item
        sensitive
        :title="$t('client.detailDropdown.copyData')"
        @click="() => this.$refs.clipboardCopy.execute()"
        v-if="resourceEditable"
      />
      <dropdown-item
        sensitive
        :title="$t('client.detailDropdown.vcard')"
        :link="`/contacts/clients/${client.id}.vcf`"
        new-tab
      />
    </dropdown-section>
    <dropdown-section v-if="resourceEditable">
      <dropdown-item
        :title="client.archived ? $t('client.detailDropdown.unarchive') : $t('client.detailDropdown.archive')"
        @click="toggleArchive"
      />
      <dropdown-item
        sensitive
        v-if="$db.hasRight('rightToDeleteClients')"
        :title="$t('client.detailDropdown.del')"
        @click="deleteClient(false)"
        class="text-danger"
      />
      <dropdown-item
        sensitive
        v-if="$db.hasRight('rightToDeleteClients')"
        :title="$t('client.detailDropdown.delWithActivities')"
        @click="deleteClient(true)"
        class="text-danger"
      />
    </dropdown-section>
    <dropdown-section v-if="$db.featureActive('tipster_portal') && resourceEditable">
      <dropdown-item>
        <template #title>
          <toggle-tipster :client="client" />
        </template>
      </dropdown-item>
    </dropdown-section>
    <dropdown-section v-if="isDevBackdoor && client.oldCrmId && resourceEditable">
      <nice-tooltip :delay="500">
        <dropdown-item title="Copy old CRM-ID" @click="copyOldCrmId" />
        <template v-slot:content>
          <span>
            ID:
            <span class="font-mono">{{ client.oldCrmId }}</span>
          </span>
        </template>
      </nice-tooltip>
    </dropdown-section>
    <clipboard-copy ref="clipboardCopy" :value="client.formattedClientData" />
    <client-merge-modal v-model:visible="clientMergeVisible" :client="client" />
    <contact-acceptance-request-dialog v-model:visible="contactAcceptanceRequestVisible" :client="client" />
  </dropdown>
</template>

<script>
import eventBus from "@/config/event-bus"
import { defineComponent } from "vue"
import ClientMergeModal from "./MergeModal.vue"
import WorklistActions from "./WorklistActions.vue"
import ContactAcceptanceRequestDialog from "@/components/message/ContactAcceptanceRequestDialog"
import Dropdown from "../Dropdown.vue"
import ToggleTipster from "./ToggleTipster.vue"
import useEnvironment from "@/plugins/use-environment"
import useShop from "@/plugins/use-shop"

export default defineComponent({
  props: {
    client: Object,
  },
  components: {
    ClientMergeModal,
    WorklistActions,
    ContactAcceptanceRequestDialog,
    Dropdown,
    ToggleTipster,
  },
  setup() {
    const { isDevBackdoor } = useEnvironment()
    return { isDevBackdoor }
  },
  data() {
    return {
      clientMergeVisible: false,
      contactAcceptanceRequestVisible: false,
    }
  },
  computed: {
    hasAddressBookSync() {
      return this.client.clientSyncBrokerIds.includes(this.$db.broker.id)
    },
  },
  methods: {
    copyOldCrmId() {
      this.$util.copy(this.client.oldCrmId)
      App.flashy("ID successfully copied!")
    },
    quickviewCancel() {
      eventBus.$emit("quick-view", {
        type: "reason",
        mode: "edit",
        params: {
          source: {
            clientIds: [this.client.id],
          },
        },
      })
    },
    toggleArchive() {
      this.$axios.put(`/contacts/clients/${this.client.id}/archive`).then(_ => {
        this.client.archived = !this.client.archived
        if (this.client.archived) {
          App.flashy(this.$t("client.detailDropdown.archiveSuccess"))
        } else {
          App.flashy(this.$t("client.detailDropdown.unarchiveSuccess"))
        }
      })
    },
    deleteClient(withActivities) {
      this.$warn(
        {
          title: withActivities
            ? this.$t("client.detailDropdown.delWithActivities")
            : this.$t("client.detailDropdown.del"),
          desc: this.$t("client.detailDropdown.delConfirm", { name: this.client.name }),
          confirmButtonText: this.$t("client.detailDropdown.delBtn"),
          redButton: true,
        },
        () => {
          this.$axios
            .delete(`/contacts/clients/${this.client.id}`, { data: { delete_with_activities: withActivities } })
            .then(_ => {
              Turbolinks.visit("/contacts/clients")
              App.flashy(this.$t("client.detailDropdown.delSuccess", { name: this.client.name }))
            })
            .catch(this.$axios.handleError)
        }
      )
    },
    saveToAddressBook(value) {
      if (value) {
        this.client.clientSyncBrokerIds.push(this.$db.broker.id)
      } else {
        this.client.clientSyncBrokerIds = this.client.clientSyncBrokerIds.filter(o => o !== this.$db.broker.id)
      }
      this.$axios.put(`/contacts/syncs/${this.client.id}`).then(_ => {
        App.flashy(
          this.$t("client.detailDropdown.syncSuccess", {
            action: value ? this.$t("client.detailDropdown.syncAction1") : this.$t("client.detailDropdown.syncAction2"),
          })
        )
      })
    },
    resourceEditable() {
      return (
        (this.$db.hasRight("rightToEditClients") ||
          (this.$db.hasRight("rightToEditSharedClients") &&
            (this.resource.brokerId === this.$db.broker.id ||
              this.resource.brokerIds?.includes(this.$db.broker.id) ||
              this.resource.departmentIds?.some(id => this.$db.broker.departmentIds.includes(id))))) ??
        false
      )
    },
    resyncEmails() {
      this.$axios.post(`/contacts/clients/${this.client.id}/resync_mails`).then(_ => {
        App.flashy(this.$t("client.detailDropdown.emailSuccess"))
      })
    },
    runValidators() {
      App.flashy(this.$t("client.detailDropdown.checkProcess"))
      this.$axios.get(`/contacts/clients/${this.client.id}/validate`).then(_ => {
        App.flashy(this.$t("client.detailDropdown.checkSuccess"))
      })
    },

    notifyBroker() {
      this.$api
        .mutation("createTask", {
          payload: {
            title: this.$t("client.detailDropdown.assign"),
            brokerId: this.client.brokerId,
            isReminder: true,
            clientIds: [this.client.id],
          },
        })
        .then(_ => App.flashy(this.$t("client.detailDropdown.assignSuccess")))
        .catch(this.$axios.handleError)
    },
    openTextMessageForm() {
      eventBus.$emit("quick-view", {
        type: "sms",
        mode: "edit",
        params: { client: this.client },
      })
    },
    requestContactPermission() {
      this.contactAcceptanceRequestVisible = true
    },
  },
})
</script>
