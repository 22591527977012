<template>
  <dropdown>
    <template #reference>
      <button @click.prevent class="details-action-link">
        <nice-tooltip :content="$t('followupsDropdown.tooltip')">
          <fa-icon name="bell" />
        </nice-tooltip>
      </button>
    </template>
    <dropdown-section>
      <dropdown-item
        v-for="date in translatedDates"
        :key="date.value"
        :title="date.label"
        @click="setFollowupDate(date)"
      />
    </dropdown-section>
    <dropdown-section v-if="followupDate">
      <dropdown-item :title="$t('followupsDropdown.reset')" @click="reset" />
    </dropdown-section>
  </dropdown>
</template>

<script setup lang="ts">
import { computed } from "vue"
import useCore from "@/plugins/use-core"
import { FOLLOWUP_DATES } from "../config/client"
import { useI18n } from "vue-i18n"

const { axios } = useCore()
const { t } = useI18n()

const emit = defineEmits(["followup"])

const props = defineProps<{
  clientId: number
  followupDate: number
}>()

const translatedDates = computed(() =>
  FOLLOWUP_DATES.map(d => ({ ...d, label: t(`followupsDropdown.dates.${d.label}`) }))
)

const setFollowupDate = date => {
  axios.post(`/services/contacts/${props.clientId}/set_followup_date`, {
    days: date.value,
  })

  if (date.value > 0) {
    App.flashy(t("followupsDropdown.successDate", { date: date.label }))
  } else {
    App.flashy(t("followupsDropdown.success"))
  }

  emit("followup", props.clientId)
}

const reset = () => {
  axios.post(`/services/contacts/${props.clientId}/set_followup_date`, { reset: true })
  App.flashy(t("followupsDropdown.successReset"))
}
</script>
