<template>
  <div>
    <nice-popover ref="searchfilterspopover" placement="bottom" width="320" trigger="click" transition="none">
      <template #reference>
        <ps-button
          :active="!!selectedSmartView"
          icon="th-list"
          :title="selectLabel"
          :disabled="disabled || undefined"
        />
      </template>

      <div class="el-popover-inner">
        <div class="standardList-wrap">
          <list-item :title="$t('smartViews.default')" @click="reset" :selected="!selectedSmartView" />
          <list-item
            v-for="item in smartViews"
            :key="item.id"
            :item="item"
            :selected="item.id == selectedSmartViewId"
            :editable="item.brokerId === $db.broker.id || $db.broker.admin"
            @edit="editSmartView(item)"
            @click="clicked(item)"
          />
          <slot name="list" />
        </div>
        <footer class="text-right mt-2">
          <a v-if="smartViews.length > 0" href="#" class="light m-l-15" @click.prevent="filterSettingsVisible = true">
            {{ $t("smartViews.edit") }}
          </a>
        </footer>
      </div>
    </nice-popover>
    <nice-dialog
      :title="$t('smartViews.editModalTitle')"
      :model-value="filterSettingsVisible"
      @update:model-value="filterSettingsVisible = $event"
      width="450px"
    >
      <p class="text-right mb-2 mr-3">
        {{ $t("smartViews.setDefault") }}:
        <fa-icon name="desktop" class="text-l ml-2 mr-2" />
        <fa-icon name="mobile" class="text-l ml-2" />
      </p>
      <div class="standardList-wrap">
        <editable-search-filter-item
          v-for="item in smartViews"
          :key="item.id"
          :filter="item"
          :entity="entity"
          :selected="item.id == defaultSmartViewId"
          :selectedForMobile="item.id == defaultSmartViewIdForMobile"
          @delete="deleteSmartView(item)"
          @change="updateDefaultSmartView"
          @changeForMobile="updateDefaultSmartViewForMobile"
        />
      </div>
      <template #footer>
        <nice-button type="primary" @click="filterSettingsVisible = false">{{ $t("smartViews.editDone") }}</nice-button>
      </template>
    </nice-dialog>
  </div>
</template>

<script>
import EditableSearchFilterItem from "@/components/search-filters/EditableSearchFilterItem"
import { useI18n } from "vue-i18n"

const DESKTOP_BROKER_FIELD_MAPPING = {
  for_contacts: "defaultClientsFilter",
  for_properties: "defaultPropertiesFilter",
  for_activities: "defaultActivitiesFilter",
  for_todos: "defaultTodosFilter",
  for_deals: "defaultDealsFilter",
}

const MOBILE_BROKER_FIELD_MAPPING = {
  for_contacts: "defaultClientsFilterForMobile",
  for_properties: "defaultPropertiesFilterForMobile",
  for_activities: "defaultActivitiesFilterForMobile",
  for_todos: "defaultTodosFilterForMobile",
  for_deals: "defaultDealsFilterForMobile",
}

export default {
  emits: ["change", "delete", "edit", "reset", "update:smartViews"],
  props: {
    smartViews: {
      type: Array,
    },
    disabled: {
      type: Boolean,
    },
    placeholder: {
      type: String,
      default: "Smart-Views",
    },
    selectedSmartViewId: {
      type: Number,
    },
    entity: {
      type: String,
    },
  },
  data() {
    const desktopBrokerField = DESKTOP_BROKER_FIELD_MAPPING[this.entity]
    const mobileBrokerField = MOBILE_BROKER_FIELD_MAPPING[this.entity]
    return {
      filterSettingsVisible: false,
      defaultSmartViewId: this.$db.broker[desktopBrokerField] && parseInt(this.$db.broker[desktopBrokerField]),
      defaultSmartViewIdForMobile: this.$db.broker[mobileBrokerField] && parseInt(this.$db.broker[mobileBrokerField]),
    }
  },
  components: {
    EditableSearchFilterItem,
  },
  methods: {
    reset() {
      this.$emit("reset")
    },
    clicked(item) {
      this.$emit("change", item)
    },
    async updateDefaultSmartView(newSmartViewId) {
      this.defaultSmartViewId = newSmartViewId
      const brokerField = DESKTOP_BROKER_FIELD_MAPPING[this.entity]
      this.$db.updateBroker({
        [brokerField]: `${newSmartViewId}`,
      })
    },
    async updateDefaultSmartViewForMobile(newSmartViewId) {
      this.defaultSmartViewIdForMobile = newSmartViewId
      const brokerField = MOBILE_BROKER_FIELD_MAPPING[this.entity]
      this.$db.updateBroker({
        [brokerField]: `${newSmartViewId}`,
      })
    },
    deleteSmartView(item) {
      this.$warn(
        {
          title: this.$t("smartViews.delete.title"),
          desc: this.$t("smartViews.delete.desc", { name: item.name }),
          confirmButtonText: this.$t("smartViews.delete.submit"),
          redButton: true,
        },
        async () => {
          await this.$axios.delete(`/services/search_filters/${item.id}`)
          if (item.id == this.selectedSmartViewId) this.reset()
          this.$emit(
            "update:smartViews",
            this.smartViews.filter(o => o.id != item.id)
          )
          this.$emit("delete", item)
          App.flashy(this.$t("smartViews.delete.success"))
        }
      )
    },
    editSmartView(item) {
      this.$emit("edit", item.id)
    },
  },
  computed: {
    selectedSmartView() {
      return this.smartViews.find(o => o.id == this.selectedSmartViewId)
    },
    selectLabel() {
      return this.selectedSmartView ? this.selectedSmartView.name : this.placeholder
    },
  },
  watch: {
    defaultSmartViewId: {
      handler(newDefaultSmartViewId) {
        // Update the selected smart view if the default smart view changes (and nothing is selected)
        if (newDefaultSmartViewId && !this.selectedSmartViewId) {
          const smartView = this.smartViews.find(s => s.id === newDefaultSmartViewId)
          this.$emit("change", smartView)
        }
      },
      immediate: true,
    },
  },
}
</script>
