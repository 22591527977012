<template>
  <div>
    <dropdown v-if="selectable && canEditUserStatus">
      <template #reference>
        <a v-if="availability" href="#" @click.prevent style="color: #4b5563">
          <nice-tooltip :content="availability.name" class="flex justify-center">
            <fa-icon :name="availability.icon" class="cursor-pointer" />
          </nice-tooltip>
        </a>
      </template>
      <dropdown-section>
        <dropdown-item
          v-for="item in availabilities"
          :key="item.id"
          :title="item.name"
          @click="updateStatus(item.id)"
        />
      </dropdown-section>
    </dropdown>
    <nice-tooltip v-else-if="availability" :content="availability.name">
      <fa-icon :name="availability.icon" />
    </nice-tooltip>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue"
import { useI18n } from "vue-i18n"
import useCore from "@/plugins/use-core"
import type { Broker } from "@/interfaces"
import { getBrokersStatusOptions } from "@/utils/get-brokers-status-options"

export type BrokerAvailabilityProps = {
  broker: Partial<Broker & { availability: string }>
  selectable?: boolean
}

const { broker, selectable = false } = defineProps<BrokerAvailabilityProps>()
const emit = defineEmits(["update-broker"])

const { t } = useI18n()
const { api, db } = useCore()

const canEditUserStatus = computed(() => db.hasRight("rightToEditUserStatus"))

async function updateStatus(key: string | null): Promise<void> {
  const payload = { availability: key }
  try {
    await api.update("Broker", broker.id!, payload)
    const updatedBroker = { ...broker, availability: key }
    console.log("payload", payload, "updatedBroker", updatedBroker)
    emit("update-broker", updatedBroker)
  } catch (err) {
    App.alert(t("brokers.error"))
    console.error(err)
  }
}

const availabilityOptions = getBrokersStatusOptions(t, true)
const availabilities = computed(() => {
  const options = [...availabilityOptions]
  // Remove the "set status (plus)" option if the broker is not allowed to edit the status
  if (!(selectable && canEditUserStatus.value)) options.pop()
  return options
})

const availability = computed(() => {
  return availabilities.value.find(a => a.id === (broker.availability || null))
})
</script>
