import { RecipeConditionOperator, RecipesConfig, RecipeVariableConfig } from "@/interfaces"
import { ActiveRecord } from "./db"

type ConditionOperatorOption = {
  value: RecipeConditionOperator
  label: string
}
export type RecipeOptionVariable = {
  id: string
  label: string
  name: string
  type: string
  options?: string
  dboptions?: string
  plabeloptions?: string
  grouped?: string
}

export const OPERATORS: ConditionOperatorOption[] = [
  {
    value: "exact",
    label: "ist genau",
  },
  {
    value: "not-exact",
    label: "ist nicht",
  },
  {
    value: "text-match",
    label: "Text beinhaltet",
  },
  {
    value: "not-text-match",
    label: "Text beinhaltet nicht",
  },
  {
    value: "include",
    label: "beinhaltet",
  },
  {
    value: "exclude",
    label: "beinhaltet nicht",
  },
  {
    value: "in",
    label: "in",
  },
  {
    value: "not-in",
    label: "nicht in",
  },
  {
    value: "gt",
    label: "größer als",
  },
  {
    value: "lt",
    label: "kleiner als",
  },
  {
    value: "exists",
    label: "existiert",
  },
  {
    value: "missing",
    label: "fehlt",
  },
]

export const BOOLEAN_OPERATORS = [
  {
    value: "exists",
    label: "ist aktiv",
  },
  {
    value: "missing",
    label: "ist inaktiv",
  },
]

export const MULTI_SELECT_CONDITIONS: RecipeConditionOperator[] = ["in", "not-in"] as const

// transform condition values which are strings with comma separated numbers to an array of numbers for the model of a select
export const transformConditionToSelectModel = (
  value: string | string[] | number | number[] | null | undefined
): number[] => {
  let result: any[] = []
  if (value === null || value === undefined) return []

  if (typeof value === "string") {
    result = value
      .split(",")
      .map(v => (!isNaN(parseFloat(v)) ? parseInt(v.trim()) : v))
      .filter(Boolean)
  } else if (Array.isArray(value)) {
    result = value
      .filter(Boolean)
      .map(v => {
        if (typeof v === "number") return v
        return parseInt(v)
      })
      .filter(v => !Number.isNaN(v))
  } else {
    result = [value]
  }
  return result
}

// transform model of a select to a string with comma separated numbers for the condition value
export const transformSelectModelToConditionString = (
  value: string[] | number[] | number | string | null | undefined
): string | null => {
  if (value === null || value === undefined || (Array.isArray(value) && value?.length === 0)) return null
  if (typeof value === "number") return value.toString()
  if (Array.isArray(value)) return value.join(",")
  return value
}

export const CHANGED_ATTRIBUTE_OPTIONS = {
  CLIENT_UPDATED: [
    ["broker_id", "Betreuer"],
    ["client_status_id", "Status"],
    ["client_reason_id", "Speicherungsgrund"],
    ["client_source_id", "Quelle"],
    ["gdpr_status", "DSGVO-Status"],
    ["newsletter", "Newsletter gewünscht"],
    ["newsletter_unsubscribed", "Newsletter abgemeldet"],
    ["accept_contact", "Kontakterlaubnis"],
    ["property_mailing_wanted", "Immobilienmailing gewünscht"],
  ],
  PROPERTY_UPDATED: [
    ["property_status_id", "Status"],
    ["price", "Preis"],
    ["broker_id", "Betreuer"],
  ],
  PROJECT_UPDATED: [["status", "Status"]],
  CLIENT_PROPERTY_UPDATED: [
    ["deal_stage_id", "Deal-Phase"],
    ["price", "Angebotspreis"],
  ],
  DECISION_UPDATED: [["decision_option_id", "Entscheidungsoption"]],
}
CHANGED_ATTRIBUTE_OPTIONS["PROPERTY_UPSERTED"] = CHANGED_ATTRIBUTE_OPTIONS["PROPERTY_UPDATED"]
CHANGED_ATTRIBUTE_OPTIONS["CLIENT_UPSERTED"] = CHANGED_ATTRIBUTE_OPTIONS["CLIENT_UPDATED"]
CHANGED_ATTRIBUTE_OPTIONS["CLIENT_PROPERTY_UPSERTED"] = CHANGED_ATTRIBUTE_OPTIONS["CLIENT_PROPERTY_UPDATED"]

const modelNameMapping = {
  message: "E-Mail",
  client: "Kontakt",
  property: "Objekt",
  project: "Projekt",
  deal: "Deal",
  savedquery: "Suchprofil",
  note: "Notiz",
  todo: "Aufgabe",
  event: "Termin",
  viewing: "Termin-Slot",
  cancelation: "Absage",
  policy: "Nachweis",
  tip: "Tipp",
  decision: "Entscheidung",
  paperless_contract: "Vertrag",
  publishing: "Veröffentlichung",
  relationship: "Verknüpfung",
}

const customFieldTypeMapping = {
  Boolean: "boolean",
  Broker: "broker",
}

const modelToCustomFieldProperty = {
  client: "customFieldGroupsForClients",
  project: "customFieldGroupsForProjects",
  property: "customFieldGroupsForProperties",
  deal: "customFieldGroupsForDeals",
}

export const getVariablesForModel = (
  db: ActiveRecord,
  config: RecipesConfig,
  model,
  options: { prefix?: string } = {}
): RecipeOptionVariable[] => {
  let variables = config.variables[model] as RecipeVariableConfig[]
  if (!variables) return []

  if (Object.keys(modelToCustomFieldProperty).includes(model)) {
    const customFieldsAttribute = modelToCustomFieldProperty[model]

    const customFieldVariables = db.shopData[customFieldsAttribute].reduce((acc, group) => {
      return acc
        .concat(
          group.customFields.map(cf => ({
            label: `CF ${cf.prettyName}`,
            name: `custom_fields__${cf.name}`,
            options: cf.options,
            type: customFieldTypeMapping[cf.fieldType],
          }))
        )
        .concat(
          group.customFields
            .filter(cf => cf.fieldType == "Broker")
            .map(cf => ({
              label: `CF ${cf.prettyName} E-Mail`,
              name: `custom_fields__${cf.name}_email`,
              type: "email",
            }))
        )
    }, [])

    variables = [...variables, ...customFieldVariables]
  }

  variables
    .filter(v => v.extension)
    .forEach(v => {
      const variablesOfExtension = config.variables[v.extension!].map(vv => ({
        ...vv,
        name: `${v.name}__${vv.name}`,
        label: `${v.label} → ${vv.label}`,
      }))
      variables = [...variables, ...variablesOfExtension]

      if (["client", "property", "project"].includes(v.extension!)) {
        const customFieldsAttribute = modelToCustomFieldProperty[v.extension!]

        const customFieldVariables = db.shopData[customFieldsAttribute].reduce((acc, group) => {
          return acc.concat(
            ...group.customFields.map(cf => ({
              label: `${v.label} → CF ${cf.prettyName}`,
              name: `${v.name}__custom_fields__${cf.name}`,
              options: cf.options,
              type: customFieldTypeMapping[cf.fieldType],
            })),
            ...group.customFields
              .filter(cf => cf.fieldType == "Broker")
              .map(cf => ({
                label: `${v.label} → CF ${cf.prettyName} E-Mail`,
                name: `${v.name}__custom_fields__${cf.name}_email`,
                type: "email",
              }))
          )
        }, [])

        variables = [...variables, ...customFieldVariables]
      }
    })

  return variables
    .filter(v => !v.extension)
    .map(v => ({
      ...v,
      id: [options.prefix, v.name].filter(Boolean).join(""),
      name: `${modelNameMapping[model]} → ${v.label}`,
    }))
}
