<template>
  <li :data-id="item.id" class="standardList-item relative bg-white rounded border shadow-sm">
    <template v-if="item.clientId">
      <hover-actions left="10px" right="0px">
        <hover-action
          v-if="item.attendance !== true"
          icon="check"
          :loading="attendanceSubmitting == 'confirm'"
          @click.prevent="setAttendance(true)"
          :tooltip="$t('task.viewings.attendance.confirm')"
        />
        <hover-action
          v-if="item.attendance !== false"
          @click="setAttendance(false)"
          icon="ban"
          :loading="attendanceSubmitting == 'cancel'"
          class="text-danger"
          :tooltip="$t('task.viewings.attendance.cancel')"
        />
      </hover-actions>
    </template>

    <fa-icon v-if="item.attendance === true" name="check" class="mr-1" />
    <fa-icon v-if="item.attendance === false" name="ban" class="text-danger mr-1" />

    <router-link
      v-if="item.clientId"
      :to="`/contacts/clients/${item.clientId}`"
      class="standardList-itemTitle text-black"
    >
      {{ item.name }}
    </router-link>
    <span v-else class="standardList-itemTitle text-black">
      {{ item.name }}
      <nice-tooltip class="ml-2 text-yellow-600" :content="$t('task.viewings.item.noContact')">
        <fa-icon name="user-alt-slash" />
      </nice-tooltip>
    </span>

    <span class="mr-2 text-sm text-gray-500" :title="$customFilters.datetime(item.viewingDate)">
      {{ $t("task.viewings.item.time", { time: $customFilters.time(item.viewingDate) }) }}
    </span>

    <hover-actions>
      <hover-action v-if="!item.clientId" icon="plus" @click.prevent="addClientClicked(item)" />
      <hover-action
        v-if="item.email"
        icon="paper-plane"
        @click.prevent="quickviewMail(item.email, resource.id, item.id)"
      />
      <hover-action
        v-if="item.phone"
        icon="phone"
        :href="$customFilters.phonify(item.phone)"
        :data-client-id="item.clientId"
      />
      <hover-action @click="deleteItem" icon="trash-alt" class="text-danger" />
    </hover-actions>
  </li>
</template>

<script>
import eventBus from "@/config/event-bus"

export default {
  emits: ["remove", "update"],
  props: {
    resource: {},
    item: {},
  },
  data() {
    return {
      attendanceSubmitting: null,
    }
  },
  methods: {
    quickviewMail(email, taskId, viewingId) {
      eventBus.$emit("quick-view", {
        type: "mail",
        mode: "edit",
        params: {
          source: {
            viewingId,
            taskId,
            email,
          },
        },
      })
    },
    deleteItem() {
      this.$warn(
        {
          title: this.$t("task.viewings.delete.title"),
          desc: this.$t("task.viewings.delete.desc"),
          confirmButtonText: this.$t("task.viewings.delete.submit"),
        },
        () => {
          this.$axios.delete(`/viewings/${this.item.id}`)
          this.$emit("remove", this.item)
          App.flashy(this.$t("task.viewings.delete.success"))
        }
      )
    },
    addClientClicked(item) {
      eventBus.$emit("add-client", item)
    },
    setAttendance(value) {
      this.attendanceSubmitting = value ? "confirm" : "cancel"
      this.$axios
        .patch(`/viewings/${this.item.id}`, {
          viewing: {
            attendance: value,
            create_silently: true,
          },
        })
        .then(() => this.$emit("update"))
        .finally(() => {
          this.attendanceSubmitting = null
          if (this.item.clientId) {
            const title = value
              ? this.$t("task.viewings.attendance.tookPlaceLong")
              : this.$t("task.viewings.attendance.cancelledLong")
            eventBus.$emit("quick-view", {
              type: "note",
              mode: "edit",
              params: {
                followupTaskId: this.resource.id,
                followupTaskClientIds: [this.item.clientId],
                source: { title },
              },
            })
          }
        })
    },
  },
}
</script>
