<template>
  <div class="comment-item">
    <div class="comment-itemAvatar">
      <avatar :broker="item.broker" size="32px" />
    </div>
    <div class="comment-itemContent">
      <p class="comment-itemAuthor">{{ brokerName }}</p>
      <div class="d-flex">
        <article class="comment-itemBody" v-html="item.body"></article>
        <div class="comment-itemMeta">
          <span v-if="item.broker_id == $db.broker.id">
            <nice-tooltip :content="$t('commentsApp.commentItem.deleteButton')">
              <a href="#" class="text-danger" @click.prevent="deleteItem">
                <fa-icon name="trash-alt" />
              </a>
            </nice-tooltip>
          </span>
        </div>
      </div>
    </div>
    <span class="comment-date">{{ $customFilters.calendar(item.created_at) }}</span>
  </div>
</template>

<script>
export default {
  emits: ["delete"],
  props: ["item"],
  computed: {
    brokerName() {
      return this.item.broker?.name
    },
  },
  methods: {
    deleteItem() {
      if (!confirm(this.$t("commentsApp.commentItem.deleteConfirm"))) return
      this.$emit("delete", this.item)
      this.$axios.delete(`/api/v1/comments/${this.item.id}`)
    },
  },
}
</script>
