<template>
  <nice-input
    v-if="!showDecimals"
    type="text"
    size="small"
    :placeholder="placeholder"
    v-model="displayValue"
    @blur="isInputActive = false"
    @focus="isInputActive = true"
  />
  <input
    v-else
    type="text"
    v-model="displayValue"
    @blur="isInputActive = false"
    @focus="isInputActive = true"
    class="dv-input"
  />
</template>

<script setup lang="ts">
import { computed, ref } from "vue"
import useShop from "@/plugins/use-shop"
import { numberToCurrency } from "../config/number-filters"

const { currency: shopCurrency } = useShop()

const emits = defineEmits(["update:modelValue"])

const {
  modelValue,
  placeholder,
  currency,
  showDecimals = false,
} = defineProps<{
  modelValue: any
  placeholder?: string
  currency?: string
  showDecimals?: boolean
}>()

const isInputActive = ref(false)

const currencyWithFallback = computed(() => currency || shopCurrency.value || null)

const displayValue = computed({
  get() {
    // Cursor is inside the input field. unformat display value for user
    if (isInputActive.value) return modelValue
    // User is not modifying now. Format display value for user interface
    else return numberToCurrency(modelValue, currencyWithFallback.value)
  },
  set(modifiedValue) {
    // Recalculate value after ignoring "$" and "," in user input
    let newValue = parseFloat(modifiedValue.replace(/[^\d\.]/g, ""))
    if (!newValue) newValue = null
    // Ensure that it is not NaN
    if (newValue && isNaN(newValue)) newValue = 0
    emits("update:modelValue", newValue)
  },
})
</script>
