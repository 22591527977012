<script lang="ts" setup>
import EmailAddressSelect from "@/components/message/EmailAddressSelect.vue"
import { ref } from "vue"

type Props = {
  modelValue: {
    onlyUnread: boolean | null
    hasAttachments: boolean | null
    category: string | null
    source: string | null
    sender: string | null
    recipient: string | null
    propertyId: string | null
    groupId: string | null
  }
}

const visible = ref(false)

const close = () => {
  visible.value = false
}

const props = defineProps<Props>()

const emit = defineEmits(["update:modelValue"])

const update = (key: string, value: Event) => {
  emit("update:modelValue", {
    ...props.modelValue,
    [key]: value,
  })
}

const clear = () => {
  emit("update:modelValue", {
    onlyUnread: null,
    hasAttachments: null,
    category: null,
    source: null,
    sender: null,
    recipient: null,
  })
}
</script>

<template>
  <nice-popover placement="right-end" trigger="click" width="600" transition="none" :visible="visible">
    <template #reference>
      <button class="tableActions-action" @click.stop="visible = !visible">
        <fa-icon name="filter" class="mr-2" />
        {{ $t("inbox.filter.title") }}
      </button>
    </template>
    <template #default>
      <div class="el-popover-inner" v-click-outside="close">
        <div class="searchFilter">
          <popover-filter-row :title="$t('inbox.filter.onlyUnread')">
            <nice-switch
              :model-value="modelValue?.onlyUnread"
              @update:model-value="update('onlyUnread', $event)"
              style="height: 100%"
            />
          </popover-filter-row>

          <popover-filter-row :title="$t('inbox.filter.hasAttachments')">
            <nice-switch
              fixed-position
              :model-value="modelValue?.hasAttachments"
              @update:model-value="update('hasAttachments', $event)"
              style="height: 100%"
            />
          </popover-filter-row>

          <popover-filter-row :title="$t('inbox.filter.category')">
            <db-select
              fixed-position
              collection="messageCategories"
              :model-value="modelValue?.category"
              @update:model-value="update('category', $event)"
            />
          </popover-filter-row>

          <popover-filter-row :title="$t('inbox.filter.source')">
            <db-select
              fixed-position
              collection="clientSources"
              :model-value="modelValue?.source"
              @update:model-value="update('source', $event)"
            />
          </popover-filter-row>

          <popover-filter-row v-if="$db.get('activityGroups').length > 0" :title="$t('inbox.filter.tags')">
            <db-select
              fixed-position
              collection="activityGroups"
              :model-value="modelValue?.groupId"
              @update:model-value="update('groupId', $event)"
            />
          </popover-filter-row>

          <popover-filter-row :title="$t('inbox.filter.recipient')">
            <email-address-select
              fixed-position
              :model-value="modelValue?.recipient"
              @update:model-value="update('recipient', $event)"
              placeholder="interessent@yahoo.com"
            />
          </popover-filter-row>

          <popover-filter-row :title="$t('inbox.filter.sender')">
            <email-address-select
              fixed-position
              :model-value="modelValue?.sender"
              @update:model-value="update('sender', $event)"
              placeholder="anfrage@immobilienscout24.de"
            />
          </popover-filter-row>

          <popover-filter-row :title="$t('inbox.filter.linkedProperty')">
            <property-select
              fixed-position
              :model-value="modelValue?.propertyId"
              @update:model-value="update('propertyId', $event)"
            />
          </popover-filter-row>

          <!-- popover filter reset button -->
          <p class="mt-3">
            <strong>
              <a href="#" class="text-danger" @click.prevent="clear">{{ $t("popover.resetButton") }}</a>
            </strong>
          </p>
        </div>
      </div>
    </template>
  </nice-popover>
</template>
