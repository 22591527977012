<template>
  <!-- Use appendTo prop when tooltip is used inside a popover triggered by hover -->
  <!-- to prevent closing of popover when hovering over tooltip. -->
  <nice-tooltip :append-to="appendTo" :content="$t('detailView.copy')">
    <fa-icon v-bind="$attrs" class="icon px-1 cursor-pointer" name="clipboard" @click="copyToClipboard" />
  </nice-tooltip>
</template>

<script lang="ts">
import { defineComponent } from "vue"

export default defineComponent({
  inheritAttrs: false,
  props: {
    text: String,
    appendTo: Object,
  },
  methods: {
    copyToClipboard(e) {
      e.preventDefault()
      const el = document.createElement("textarea")
      el.value = this.text || ""
      el.setAttribute("readonly", "")
      el.style.position = "absolute"
      el.style.left = "-9999px"
      document.body.appendChild(el)
      el.select()
      document.execCommand("copy")
      document.body.removeChild(el)
      App.flashy(this.$t("detailView.emailCopySuccess"))
    },
  },
})
</script>
