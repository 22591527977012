<template>
  <widget-container
    :widget="widget"
    :config="config"
    :loading="page == 1 && loading"
    :infinite-scroll="() => fetchData()"
    :infinite-scroll-disabled="loading || noMore"
  >
    <template v-slot:title>
      <div class="flex justify-between">
        <span>{{ title }}</span>
        <router-link :to="`/opportunities?smartview=${widget.location}`" class="text-base">
          <nice-tooltip :content="$t('widget.link')">
            <fa-icon name="arrow-right" style="font-size: 1.2rem" />
          </nice-tooltip>
        </router-link>
      </div>
    </template>
    <router-link
      v-for="item in items"
      :key="item.id"
      class="flex items-center rounded p-2 mx-1 cursor-pointer text-black hover:bg-black/5"
      :to="`/opportunities/${item.id}`"
    >
      <aside class="mr-2">
        <fa-icon name="dollar-sign" class="text-lg" />
      </aside>
      <main class="flex-grow pl-1 overflow-hidden">
        <p class="leading-snug whitespace-nowrap text-ellipsis" :title="item.client.name">
          {{ item.client.name }} - {{ item.property?.name }}
        </p>
      </main>
    </router-link>
    <div v-if="loading" class="popover-empty">
      <span class="spinner"></span>
    </div>
    <p v-if="!items.length" class="px-2 mx-1 text-sm text-gray-600 italic mt-2">
      {{ $t("widget.emptystates.deals") }}
    </p>
  </widget-container>
</template>

<script>
import WidgetContainer from "./WidgetContainer.vue"
import { buildQuery } from "@/mixins/elastic-filters"

export default {
  components: { WidgetContainer },
  props: {
    widget: {},
    config: {},
  },
  watch: {
    widget: {
      handler() {
        this.fetchData()
      },
    },
  },
  data() {
    return {
      items: [],
      total: 0,
      loading: false,
      noMore: false,
      page: 1,
    }
  },
  methods: {
    async fetchData() {
      if (this.loading) return
      this.loading = true
      const { data } = await this.$axios.post("/api/v1/client_properties/search", this.filters)
      this.items = this.items.concat(data.data)
      this.loading = false
      this.noMore = 10 * this.page >= data.total_count
      this.page++
    },
  },
  computed: {
    filters() {
      const filter = this.$db.broker.searchFilters.find(sf => sf.id == this.widget.location)
      const params = JSON.parse(filter.params)
      return {
        per: 10,
        page: this.page,
        sort_by: params.sort_by,
        order: params.order,
        filter_set: buildQuery(filter.esFilters, this.$db),
      }
    },
    title() {
      return this.widget.title || this.config[this.widget.category].label
    },
  },
  mounted() {
    this.fetchData()
  },
}
</script>
