import { floor, prettyNumber, prettySpace, numberToCurrency, rented } from "../number-filters"
import { calendar } from "../date-filters"

export const config = {
  photo: {
    operation: val => val,
  },
  name: {
    operation: val => val,
    sort: true,
  },
  project_id: {
    operation: val => val,
    sort: true,
  },
  floor: {
    operation: val => floor(val),
    sort: true,
  },
  number_of_rooms: {
    operation: val => val,
    sort: true,
  },
  property_space_value: {
    operation: (val, db) => prettySpace(val, db.shopData.areaUnit),
    sort: true,
  },
  get_price: {
    operation: val => prettyNumber(val),
    sort: true,
  },
  broker: {
    operation: val => val.name,
    sort: "broker.name",
  },
  status: {
    operation: val => val.name,
    sort: "status.name",
  },
  unit_id: {
    operation: val => val,
    sort: true,
  },
  price_per_sqm: {
    operation: val => prettyNumber(val),
    sort: true,
  },
  exposee_id: {
    operation: val => val,
    sort: true,
  },
  street_number: {
    operation: val => val,
    sort: true,
  },
  zip_code: {
    operation: val => val,
    sort: true,
  },
  city: {
    operation: val => val,
    sort: true,
  },
  location: {
    operation: val => val,
    sort: true,
  },
  rented: {
    operation: val => (val ? "Ja" : ""),
    sort: true,
  },
  rs_type_label: {
    operation: val => val,
    sort: true,
  },
  rs_category_label: {
    operation: val => val,
    sort: true,
  },
  plot_area: {
    operation: (val, db) => prettySpace(val, db.shopData.areaUnit),
    sort: true,
  },
  base_rent: {
    operation: val => val,
    sort: true,
  },
  total_rent: {
    operation: val => prettyNumber(val),
    sort: true,
  },
  marketing_type: {
    operation: val => val,
    sort: true,
  },
  title: {
    operation: val => val,
    sort: true,
  },
  highest_deal: {
    operation: val => val,
    sort: false,
  },
  deal_stage_id: {
    operation: val => val,
    sort: false,
  },
  groups: {
    operation: val => val,
    sort: false,
  },
  owners: {
    operation: val => val,
    sort: false,
  },
  last_name: {
    operation: val => val,
    sort: false,
  },
  created_at: {
    operation: val => calendar(val),
    sort: true,
  },
  updated_at: {
    operation: val => calendar(val),
    sort: true,
  },
  district: {
    operation: val => val,
    sort: true,
  },
  cadastral_district: {
    operation: val => val,
    sort: true,
  },
  plot_number: {
    operation: val => val,
    sort: true,
  },
  publishings: {
    operation: val => val,
    sort: false,
  },
  floorplans: {
    operation: val => val,
    sort: false,
  },
  deals: {
    operation: val => val,
    sort: false,
  },
}

const CURRENCY_MAP = {
  euro: "€",
  chf: "CHF",
  huf: "Ft",
  aed: "AED",
  czk: "Kč",
}

export const format = (col, value, db) => {
  // if (!value) return
  if (col.unit === "currency") return numberToCurrency(value, db.shopData.currency || "€")
  if (config[col.name]) return config[col.name].operation(value, db)
  if (Object.keys(CURRENCY_MAP).includes(col.unit)) return numberToCurrency(value, CURRENCY_MAP[col.unit])
  if (col.unit === "percent") return value + " %"
  if (col.unit === "sqm") return prettySpace(value, db.shopData.areaUnit)
  if (typeof value === "number" && col.unit !== "year") return prettyNumber(value)
  return value
}

export const getSort = name => {
  if (name.match(/^rs_/)) return false
  // if not specified obove (custom fields), make sortable by name
  if (!config[name]) return name

  const sort = config[name].sort

  if (!sort) return null
  if (typeof sort === "boolean") return name
  if (typeof sort === "string") return sort
}
