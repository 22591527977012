<template>
  <div class="border border-slate-300 p-4 h-full mr-4">
    <div class="subnav d-flex justify-content-between">
      <nav class="d-flex">
        <div
          class="automation-sidebar__tab cursor-pointer select-none"
          :class="{
            active: isTrigger,
          }"
          @click="selectTrigger"
        >
          {{ $t("automations.flowchart.trigger") }}
        </div>
        <div
          class="automation-sidebar__tab cursor-pointer select-none"
          :class="{
            active: isCondition,
          }"
          @click="selectCondition"
        >
          {{ $t("automations.flowchart.condition") }}
        </div>
        <div
          class="automation-sidebar__tab cursor-pointer select-none"
          :class="{
            active: isAction,
          }"
          @click="selectAction"
        >
          {{ $t("automations.flowchart.action") }}
        </div>
      </nav>
    </div>

    <div v-if="isTrigger">
      <form-section>
        <form-row :title="$t('automations.triggerType')" direction="column">
          <nice-select
            v-model="recipeForm.triggerType"
            :options="triggerTypes"
            :placeholder="$t('automations.triggerTypePh')"
            label-key="label"
            id-key="name"
          />
        </form-row>
      </form-section>
    </div>

    <div v-if="isCondition">
      <form-section>
        <form-row direction="column" :title="$t('automations.conditions')">
          <div>
            <condition-row
              v-for="(condition, $index) in recipeForm.conditions"
              :key="$index"
              :condition="condition"
              :recipes="recipes"
              :recipe="recipeForm"
              :action-options-data="actionOptionsData"
              :recipes-config="recipesConfig"
              @remove="removeCondition($index)"
            />

            <footer class="searchFilter-footer">
              <button class="searchFilter-add" @click.prevent="addCondition">
                <fa-icon name="plus" />
                {{ $t("automations.addConditions") }}
              </button>
            </footer>
          </div>
        </form-row>
      </form-section>
    </div>

    <div v-if="isAction">
      <form-section>
        <form-row :title="$t('automations.actionType')" direction="column">
          <nice-select
            v-model="recipeForm.actionType"
            :options="actionTypes"
            :placeholder="$t('automations.actionTypePh')"
            label-key="label"
            id-key="name"
          />
        </form-row>
        <form-row direction="column" :title="$t('automations.actions')">
          <action-option-row
            v-for="(option, $index) in recipeForm.actionOptions"
            :option="option"
            :key="[recipeForm.triggerType, $index].join('')"
            :recipes="recipes"
            :recipe="recipeForm"
            :action-options-data="actionOptionsData"
            :selected-trigger-type-option="selectedTriggerTypeOption"
            :recipes-config="recipesConfig"
            @remove="removeActionOption($index)"
          />

          <footer class="searchFilter-footer">
            <button class="searchFilter-add" @click.prevent="addActionOption">
              <fa-icon name="plus" />
              {{ $t("automations.addActionData") }}
            </button>
          </footer>
        </form-row>
      </form-section>
    </div>
  </div>
</template>

<script>
import { NODE_TYPE } from "@/config/automation-helpers"
import ActionOptionRow from "./ActionOptionRow"
import ConditionRow from "./ConditionRow"
import * as _ from "lodash"

export default {
  components: { ActionOptionRow, ConditionRow },
  data: () => ({
    recipeForm: {
      id: null,
      title: "",
      triggerType: "",
      actionType: "",
      webhookUrl: "",
      changedAttribute: "",
      conditions: [],
      actionOptions: [],
      nextRecipeIds: [],
    },
    actionOptionsData: {},
  }),
  props: {
    recipe: {
      type: Object,
      required: true,
    },
    recipes: {
      type: Array,
      required: true,
    },
    recipesConfig: {
      type: Object,
      required: true,
    },
    selected: {
      type: Object,
    },
  },
  computed: {
    isTrigger() {
      return this.selected?.nodeType === NODE_TYPE.TRIGGER || this.selected?.nodeType === NODE_TYPE.ADD_TRIGGER
    },
    isCondition() {
      return this.selected?.nodeType === NODE_TYPE.CONDITION || this.selected?.nodeType === NODE_TYPE.ADD_CONDITION
    },
    isAction() {
      return this.selected?.nodeType === NODE_TYPE.ACTION || this.selected?.nodeType === NODE_TYPE.ADD_ACTION
    },
    triggerTypes() {
      return (this.recipesConfig?.triggers || []).concat([{ label: this.$t("automations.recentRecipe"), name: null }])
    },
  },
  methods: {
    openRecipeForm(recipe = {}) {
      this.recipeForm.id = recipe.id
      this.recipeForm.title = recipe.title
      this.recipeForm.triggerType = recipe.triggerType
      this.recipeForm.actionType = recipe.actionType
      this.recipeForm.webhookUrl = recipe.webhookUrl
      this.recipeForm.changedAttribute = recipe.changedAttribute
      this.recipeForm.conditions = Array.isArray(recipe.conditions) ? recipe.conditions : []
      this.recipeForm.actionOptions = _.reduce(
        recipe.actionOptions,
        (result, value, key) => {
          result.push({ key, value })
          return result
        },
        []
      )
      this.recipeForm.nextRecipeIds = recipe.nextRecipeIds || []
      this.fetchActionOptionsData()
    },
    closeRecipeForm() {
      this.dialogVisible = false
      this.recipeForm = {
        id: null,
        title: "",
        triggerType: "",
        actionType: "",
        webhookUrl: "",
        changedAttribute: "",
        conditions: [],
        actionOptions: [],
        nextRecipeIds: [],
      }
    },
    async fetchActionOptionsData() {
      const { shop } = await this.$graphql(`{
        shop {
          brokers {
            id,
            name
          },
          departments {
            id,
            name
          },
          snippets {
            id,
            name
          },
          clientGroups {
            id,
            name
          },
          clientStatuses {
            id,
            name
          },
          clientReasons { id name }
          propertyStatuses {
            id,
            name
          },
          noteCategories {
            id,
            name
          },
          todoCategories {
            id,
            name
          },
          eventCategories {
            id,
            name
          },
          decisionCategories {
            id,
            name
          },
          dealStages { id name },
          projectStatuses {
            id,
            name
          },
        }
      }`)

      this.actionOptionsData = shop
    },
    addCondition() {
      this.recipeForm.conditions.push({
        key: "",
        match: null,
        value: "",
      })
    },
    removeCondition(index) {
      let conditions = this.recipeForm.conditions.slice()
      _.pullAt(conditions, index)
      this.recipeForm.conditions = conditions
    },
    selectedTriggerTypeOption() {
      return this.triggerTypes.find(t => t.name == this.recipeForm.triggerType)
    },
    actionTypes() {
      return this.recipesConfig?.actions || []
    },
    selectTrigger() {
      this.$emit("selectTrigger")
    },
    selectAction() {
      this.$emit("selectAction")
    },
    selectCondition() {
      this.$emit("selectCondition")
    },
  },
  watch: {
    recipe(value) {
      this.fetchActionOptionsData()
      return this.openRecipeForm(value)
    },
    "recipeForm.triggerType": {
      handler(newTriggerType) {
        if (!_.includes(newTriggerType, "_UPDATED") && !_.includes(newTriggerType, "_UPSERTED")) {
          this.recipeForm.changedAttribute = null
        }
      },
    },
    recipeForm: {
      handler(value) {
        this.$emit("changed", value)
      },
      deep: true,
    },
  },
  async mounted() {
    await this.fetchActionOptionsData()
    return this.openRecipeForm(this.recipe)
  },
}
</script>

<style>
.automation-sidebar__tab {
  display: block;
  font-weight: 500;
  top: 0;
  height: 30px;
  padding: 0 0.9em;
  line-height: 30px;
  margin: 0 5px 0 0;
  border-radius: 30px;
  color: black;
}

.automation-sidebar__tab:hover,
.automation-sidebar__tab:focus {
  text-decoration-line: underline;
  text-decoration-thickness: 2px;
}

.automation-sidebar__tab.active {
  text-decoration-line: underline;
  text-decoration-thickness: 2px;
}
</style>
