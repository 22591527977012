<template>
  <section class="box">
    <header v-show="!hideHeader" class="box-header">
      <h3 class="box-title">
        {{ group.name }}
        <nice-tooltip
          v-if="canSplit"
          :content="
            group.splitIntoTwoColumns
              ? $t('detailViewGroups.group.oneFieldPerLine')
              : $t('detailViewGroups.group.switchTwoColumns')
          "
        >
          <a href="#" class="text-muted ml-1 fs-12" @click.prevent="toggleColumnView">
            <fa-icon :name="group.splitIntoTwoColumns ? 'th' : 'th-list'" />
          </a>
        </nice-tooltip>
        <a href="#" class="text-muted ml-1 fs-12" @click.prevent="editName" v-if="!disableEditing">
          <fa-icon name="pencil" />
        </a>
        <nice-tooltip v-if="hasConditions" :content="$t('detailViewGroups.group.limitedVisibility')">
          <span class="text-muted ml-1 fs-12">
            <fa-icon name="eye-slash" />
          </span>
        </nice-tooltip>
      </h3>
      <div>
        <a href="#" class="text-danger ml-1" @click.prevent="$emit('removeGroup')">
          <fa-icon name="times" />
        </a>
      </div>
    </header>
    <draggable
      class="box-rows"
      :list="group.detailViewFields"
      item-key="id"
      group="fields"
      @change="updateFieldPositions"
    >
      <template #item="{ element: field }">
        <field
          :form-fields="formFields"
          :field="field"
          :group="group"
          @edit-rights="openRightsModal"
          @remove="removeField(field)"
        />
      </template>
    </draggable>
    <form class="box-form">
      <nice-select
        ref="inputField"
        filterable
        v-model="newFieldName"
        :options="availableFormFieldsOptions"
        :placeholder="$t('detailViewGroups.group.addField')"
        @change="addField"
      ></nice-select>
    </form>
    <form-dialog
      name="Gruppe"
      :id="editingGroup && editingGroup.id"
      v-model:visible="groupModalVisible"
      :saving="groupSaving"
      @submit="updateGroup"
      append-to-body
    >
      <template v-if="editingGroup">
        <form-section>
          <form-row :title="$t('detailViewGroups.group.name')">
            <nice-input size="small" v-model="editingGroup.name" />
          </form-row>
          <form-row v-if="canSplit" :title="$t('detailViewGroups.group.splitInTwo')">
            <nice-switch v-model="editingGroup.splitIntoTwoColumns" />
          </form-row>
          <form-row v-for="locale in translatedLocales" :key="locale">
            <template v-slot:label>
              <label>
                {{ $t("detailViewGroups.group.translation") }}
                <flag :locale="locale" />
              </label>
            </template>
            <nice-input
              :model-value="getGroupTranslation(locale)"
              @update:model-value="value => handleGroupTranslationChange(value, locale)"
            />
          </form-row>
          <form-row v-if="group.entity === 'for_exposee'" :title="$t('detailViewGroups.group.visibleIn')">
            <nice-radio-group
              v-model="editingGroup.exposeType"
              class="w-100"
              :options="[
                { id: 'short', name: $t('detailViewGroups.group.shortExpose') },
                { id: 'long', name: $t('detailViewGroups.group.longExpose') },
                { id: null, name: $t('detailViewGroups.group.shortAndLongExpose') },
              ]"
            />
          </form-row>
        </form-section>

        <form-section v-if="canHaveRights && group.entity != 'for_tipster_profiles'">
          <form-row :title="$t('detailViewGroups.group.brokersWithReadRights')">
            <db-select collection="unarchivedBrokers" multiple v-model="editingGroup.readBrokerIds" />
          </form-row>
          <form-row :title="$t('detailViewGroups.group.teamsWithReadRights')">
            <db-select collection="departments" multiple v-model="editingGroup.readDepartmentIds" />
          </form-row>
        </form-section>

        <form-section v-if="group.entity == 'for_properties' || group.entity == 'for_saved_queries'">
          <form-row :title="$t('detailViewGroups.group.advertisement')">
            <nice-radio-group
              v-model="editingGroup.allowedMarketingType"
              class="w-100"
              :options="[
                { id: null, name: $t('detailViewGroups.group.noMatter') },
                { id: 'BUY', name: $t('detailViewGroups.group.buy') },
                { id: 'RENT', name: $t('detailViewGroups.group.rent') },
              ]"
            />
          </form-row>
          <form-row :title="$t('detailViewGroups.group.propertyCategories')">
            <nice-select
              multiple
              v-model="editingGroup.allowedRsTypes"
              :options="rsTypes"
              :placeholder="$t('detailViewGroups.group.noMatter')"
            />
          </form-row>
          <form-row v-if="group.entity == 'for_properties'" :title="$t('detailViewGroups.group.propertyStatuses')">
            <db-select
              multiple
              v-model="editingGroup.propertyStatusIds"
              collection="propertyStatuses"
              :placeholder="$t('detailViewGroups.group.noMatter')"
            />
          </form-row>
          <form-row v-if="group.entity == 'for_saved_queries'" :title="$t('detailViewGroups.group.clientGroups')">
            <db-select
              multiple
              v-model="editingGroup.clientGroupIds"
              collection="clientGroups"
              :placeholder="$t('detailViewGroups.group.noMatter')"
            />
          </form-row>
          <form-row :title="$t('detailViewGroups.group.countries')">
            <nice-select
              multiple
              v-model="editingGroup.countries"
              :options="countries"
              :placeholder="$t('detailViewGroups.group.noMatter')"
            />
          </form-row>
        </form-section>

        <form-section v-if="group.entity == 'for_deals'">
          <form-row :title="$t('detailViewGroups.group.dealPipelines')">
            <db-select multiple v-model="editingGroup.dealPipelineIds" collection="dealPipelines" />
          </form-row>
          <form-row :title="$t('detailViewGroups.group.dealStages')">
            <db-select
              multiple
              v-model="editingGroup.dealStageIds"
              collection="dealStages"
              :filter-func="item => editingGroup.dealPipelineIds.includes(item.dealPipelineId)"
            />
          </form-row>
        </form-section>
      </template>
    </form-dialog>
    <form-dialog
      name="Feld"
      :id="editingField && editingField.id"
      v-model:visible="rightsModalVisible"
      :saving="rightsSaving"
      @submit="updateField"
      append-to-body
    >
      <template v-if="editingField">
        <form-section>
          <form-row :title="$t('detailViewGroups.group.ownName')">
            <nice-input
              v-model="editingField.title"
              :placeholder="formFields[editingField.fieldName] && formFields[editingField.fieldName].label"
            />
          </form-row>
          <form-row v-if="group.entity == 'for_tipster_profiles'" :title="$t('detailViewGroups.group.canEdit')">
            <nice-switch v-model="editingField.editable" />
          </form-row>
          <form-row
            v-if="editingField.fieldName == 'container'"
            :title="$t('detailViewGroups.group.containerField')"
            :hint="$t('detailViewGroups.group.containerFieldHint')"
          >
            <nice-select
              multiple
              filterable
              :multiple-limit="3"
              :options="availableFormFieldsForContainerOptions"
              v-model="editingField.containerFieldNames"
            ></nice-select>
          </form-row>
          <form-row
            v-if="group.entity != 'for_exposee' && group.entity != 'for_tipster_profiles'"
            :title="$t('detailViewGroups.group.note')"
          >
            <nice-textarea v-model="editingField.hint" :minRows="2" />
          </form-row>
          <form-row
            v-if="group.entity === 'for_exposee'"
            :title="$t('detailViewGroups.group.visibleIn')"
            :disabled="Boolean(group.exposeType)"
          >
            <nice-radio-group
              v-model="editingField.exposeType"
              class="w-100"
              :options="[
                { id: 'short', name: $t('detailViewGroups.group.shortExpose') },
                { id: 'long', name: $t('detailViewGroups.group.longExpose') },
                { id: null, name: $t('detailViewGroups.group.shortAndLongExpose') },
              ]"
            />
          </form-row>
        </form-section>

        <form-section v-if="canHaveRights && group.entity != 'for_tipster_profiles'">
          <form-row :title="$t('detailViewGroups.group.brokersWithReadRights')">
            <db-select
              collection="brokers"
              :additional-options="
                group.entity == 'for_properties' || 'for_clients'
                  ? [
                      {
                        id: -1,
                        name: $t('detailViewGroups.group.propertyContact'),
                        internalName: $t('detailViewGroups.group.propertyContact'),
                      },
                    ]
                  : []
              "
              multiple
              v-model="editingField.readBrokerIds"
            />
          </form-row>
          <form-row :title="$t('detailViewGroups.group.teamsWithReadRights')">
            <db-select collection="departments" multiple v-model="editingField.readDepartmentIds" />
          </form-row>
          <form-row :title="$t('detailViewGroups.group.brokersWithWriteRights')">
            <db-select
              collection="brokers"
              :additional-options="
                group.entity == 'for_properties' || 'for_clients'
                  ? [
                      {
                        id: -1,
                        name: $t('detailViewGroups.group.propertyContact'),
                        internalName: $t('detailViewGroups.group.propertyContact'),
                      },
                    ]
                  : []
              "
              multiple
              v-model="editingField.writeBrokerIds"
            />
          </form-row>
          <form-row :title="$t('detailViewGroups.group.teamsWithWriteRights')">
            <db-select collection="departments" multiple v-model="editingField.writeDepartmentIds" />
          </form-row>
        </form-section>

        <form-section v-if="group.entity == 'for_properties' || group.entity == 'for_saved_queries'">
          <form-row :title="$t('detailViewGroups.group.advertisement')">
            <nice-radio-group
              v-model="editingField.allowedMarketingType"
              class="w-100"
              :options="[
                { id: null, name: $t('detailViewGroups.group.noMatter') },
                { id: 'BUY', name: $t('detailViewGroups.group.buy') },
                { id: 'RENT', name: $t('detailViewGroups.group.rent') },
              ]"
            />
          </form-row>
          <form-row :title="$t('detailViewGroups.group.propertyCategories')">
            <nice-select
              multiple
              v-model="editingField.allowedRsTypes"
              :options="rsTypes"
              :placeholder="$t('detailViewGroups.group.noMatter')"
            />
          </form-row>
          <form-row :title="$t('detailViewGroups.group.propertyStatuses')" v-if="group.entity == 'for_properties'">
            <db-select
              multiple
              v-model="editingField.propertyStatusIds"
              collection="propertyStatuses"
              :placeholder="$t('detailViewGroups.group.noMatter')"
            />
          </form-row>
          <form-row :title="$t('detailViewGroups.group.clientGroups')" v-if="group.entity == 'for_saved_queries'">
            <db-select
              multiple
              v-model="editingField.clientGroupIds"
              collection="clientGroups"
              :placeholder="$t('detailViewGroups.group.noMatter')"
            />
          </form-row>
          <form-row :title="$t('detailViewGroups.group.countries')">
            <nice-select
              multiple
              v-model="editingField.countries"
              :options="countries"
              :placeholder="$t('detailViewGroups.group.noMatter')"
            />
          </form-row>
        </form-section>

        <form-section v-if="group.entity == 'for_tasks'">
          <form-row :title="$t('detailViewGroups.group.activityCategories')">
            <nice-select
              multiple
              v-model="editingField.taskCategoryIds"
              :options="additionalValues.taskCategories"
              :placeholder="$t('detailViewGroups.group.noMatter')"
            />
          </form-row>
        </form-section>

        <form-section v-if="group.entity == 'for_deals'">
          <form-row :title="$t('detailViewGroups.group.dealPipelines')">
            <db-select multiple v-model="editingField.dealPipelineIds" collection="dealPipelines" />
          </form-row>
          <form-row :title="$t('detailViewGroups.group.dealStages')">
            <db-select
              multiple
              v-model="editingField.dealStageIds"
              collection="dealStages"
              :filter-func="item => editingField.dealPipelineIds.includes(item.dealPipelineId)"
            />
          </form-row>
        </form-section>

        <form-row
          v-if="canHaveRights && group.entity != 'for_tipster_profiles'"
          :title="$t('detailViewGroups.group.mandatory')"
          :hint="$t('detailViewGroups.group.mandatoryHint')"
        >
          <nice-switch v-model="editingField.mandatory" />
        </form-row>

        <form-row
          v-if="editingFieldColor && group.entity != 'for_tipster_profiles'"
          :title="$t('detailViewGroups.group.backgroundColor')"
          :hint="$t('detailViewGroups.group.backgroundColorHint')"
        >
          <color-picker v-model="editingField.color" :custom-colors="predefinedColors" />
        </form-row>
      </template>
    </form-dialog>
  </section>
</template>

<script>
import Field from "./Field"
import ClientFormFields from "@/config/client/form-fields"
import PropertyFormFields from "@/config/property/form-fields"
import DealFormFields from "@/config/deal/form-fields"
import { countriesByAlpha3 } from "@/config/client/countries"
import { camelCase, snakeCase } from "@/utils/with-case"
import Flag from "@/components/Flag.vue"
import * as _ from "lodash"
import { defineComponent } from "vue"

const allowedTypes = [
  "string",
  "date",
  "dropdown",
  "broker",
  "multiselect",
  "number",
  "text",
  "phone",
  "link",
  "tags",
  "tags_by_category",
]

const allowedComponents = ["owner-field", "property-category"]

export default defineComponent({
  emits: ["update", "input"],
  props: {
    group: Object,
    availableFormFields: Object,
    formFields: Object,
    hideHeader: {
      type: Boolean,
      default: false,
    },
    additionalProps: {
      type: Object,
      required: false,
    },
    additionalValues: {
      type: Object,
      required: false,
    },
    disableEditing: {
      type: Boolean,
      required: false,
    },
  },

  components: {
    Field,
    Flag,
  },

  data() {
    return {
      newFieldName: "",
      editingField: null,
      rightsSaving: false,
      rightsModalVisible: false,
      editingGroup: null,
      groupModalVisible: false,
      groupSaving: false,
    }
  },

  computed: {
    availableFormFieldsOptions() {
      return Object.entries(this.availableFormFields).map(([key, value]) => ({ id: key, name: value.label }))
    },
    canSplit() {
      return ["for_properties", "for_projects", "for_clients"].includes(this.group.entity)
    },
    canHaveRights() {
      return this.group.entity != "for_exposee" && this.group.entity != "for_saved_queries"
    },
    rsTypes() {
      return this.$db.shopData.propertyOptionsMapping.rs_type
    },
    availableFormFieldsForContainerOptions() {
      const filteredFields = _.omitBy(this.availableFormFields, (v, k) => {
        return k == "container" || _.startsWith(k, "rs_")
      })
      return Object.entries(filteredFields).map(([key, value]) => ({
        id: key,
        name: value.label,
      }))
    },
    editingFieldColor() {
      if (!["for_clients", "for_properties", "for_deals"].includes(this.group.entity)) return false
      let prop
      if (this.group.entity === "for_clients") prop = ClientFormFields[camelCase(this.editingField.fieldName)]
      if (this.group.entity === "for_properties") prop = PropertyFormFields[camelCase(this.editingField.fieldName)]
      if (this.group.entity === "for_deals") prop = DealFormFields[camelCase(this.editingField.fieldName)]
      if (
        this.editingField.fieldName === "container" ||
        this.editingField.fieldName.includes("cf_") ||
        this.editingField.fieldName.includes("rs_") ||
        (prop?.type && allowedTypes.includes(prop.type)) ||
        allowedComponents.includes(prop?.component)
      ) {
        return true
      }
      return false
    },
    predefinedColors() {
      return [
        "#fcdae1",
        "#f9d9d0",
        "#ffe8cc",
        "#fcf1d8",
        "#fbffa6",
        "#d5e8d2",
        "#e2f3f0",
        "#ccf2ff",
        "#d4dff9",
        "#e1e4f4",
        "#e7deec",
        "#ffe6ff",
      ]
    },
    countries() {
      return countriesByAlpha3(this.$root.$i18n.locale)
    },
    hasConditions() {
      const {
        allowedMarketingType,
        allowedRsTypes = [],
        propertyStatusIds = [],
        countries = [],
        clientGroupIds = [],
      } = this.group
      return (
        allowedMarketingType ||
        allowedRsTypes.length > 0 ||
        propertyStatusIds.length > 0 ||
        countries.length > 0 ||
        clientGroupIds.length > 0
      )
    },
    translatedLocales() {
      return [this.$db.shopData.language].concat(this.$db.shopData.supportedPropertyLocales || []).filter(Boolean)
    },
  },

  methods: {
    editName() {
      this.editingGroup = _.cloneDeep(this.group)
      this.groupModalVisible = true
    },
    updateGroup() {
      this.groupSaving = true
      this.$api
        .update("DetailViewGroup", this.group.id, _.omit(this.editingGroup, ["id", "detailViewFields"]))
        .then(_ => {
          this.editingGroup.detailViewFields.forEach(f => (f.exposeType = this.editingGroup.exposeType))
          this.$emit("update", this.editingGroup)
          this.groupModalVisible = false
        })
        .catch(this.$axios.handleError)
        .finally(_ => (this.groupSaving = false))
    },
    getGroupTranslation(locale) {
      return this.editingGroup.translations?.find(t => t.locale === locale)?.["name"]
    },
    handleGroupTranslationChange(value, locale) {
      this.editingGroup.translations ||= []
      let translation = this.editingGroup.translations.find(t => t.locale === locale)

      if (_.isEmpty(translation)) {
        this.$emit("input", this.editingGroup.translations.push({ locale: locale, name: value }))
      } else {
        this.$emit("input", _.set(translation, "name", value))
      }
    },
    toggleColumnView() {
      this.group.splitIntoTwoColumns = !this.group.splitIntoTwoColumns
      this.$api
        .update("DetailViewGroup", this.group.id, {
          splitIntoTwoColumns: this.group.splitIntoTwoColumns,
        })
        .catch(this.$axios.handleError)
    },
    addField() {
      this.$api
        .create("DetailViewField", {
          detailViewGroupId: this.group.id,
          position: this.group.detailViewFields.length,
          fieldName: snakeCase(this.newFieldName),
          ...(this.additionalProps || {}),
        })
        .then(data => {
          this.$emit("update", {
            ...this.group,
            detailViewFields: [
              ...this.group.detailViewFields,
              {
                id: data.id,
                fieldName: snakeCase(this.newFieldName),
                title: null,
                readBrokerIds: [],
                readDepartmentIds: [],
                writeBrokerIds: [],
                writeDepartmentIds: [],
                allowedMarketingType: null,
                allowedRsTypes: [],
                propertyStatusIds: [],
                clientGroupIds: [],
                countries: [],
                exposeType: null,
                ...(this.additionalProps || {}),
              },
            ],
          })
          this.newFieldName = ""
          this.$refs.inputField.blur()
          this.$refs.inputField.focus()
        })
    },
    removeField(field) {
      this.$api.destroy("DetailViewField", field.id)
      this.$emit("update", {
        ...this.group,
        detailViewFields: this.group.detailViewFields.filter(f => f.id != field.id),
      })
    },
    updateFieldPositions(event) {
      if (event.added || event.moved) {
        this.$api.mutation("sortDetailViewField", {
          detailViewGroupId: this.group.id,
          items: this.group.detailViewFields.map(f => parseInt(f.id)),
        })
      }
    },
    openRightsModal(field) {
      this.rightsModalVisible = true
      this.editingField = JSON.parse(JSON.stringify(field))
    },
    updateField() {
      this.rightsSaving = true
      const payload = _.omit(this.editingField, ["id", "fieldName"])
      this.$api
        .update("DetailViewField", this.editingField.id, payload)
        .then(() => {
          this.$emit("update", {
            ...this.group,
            detailViewFields: this.group.detailViewFields.map(f =>
              f.id == this.editingField.id ? this.editingField : f
            ),
          })
          this.rightsModalVisible = false
        })
        .catch(this.$axios.handleError)
        .finally(_ => {
          this.rightsSaving = false
        })
    },
  },
})
</script>

<style scoped>
.box {
  background: white;
  border-radius: 4px;
  padding: 0.5rem;
  border: 1px solid #dddddd;
  margin-bottom: 1rem;
}
.box-header {
  display: flex;
  justify-content: space-between;
  padding: 4px 15px 4px 5px;
}
.box-title {
  margin-bottom: 0.5rem;
  font-size: 1rem;
}
</style>
