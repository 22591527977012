<template>
  <nice-input
    v-bind="$attrs"
    type="textarea"
    :model-value="modelValue"
    @update:model-value="$emit('update:model-value', $event)"
    @change="$emit('change', $event)"
    :autosize="autosize"
    size="small"
    @enter="$attrs.resize == 'none' ? $event.preventDefault() : undefined"
  />
</template>

<script setup lang="ts">
import { computed, defineEmits, defineProps, defineExpose } from "vue"

export type NiceTextareaProps = {
  modelValue: string
  minRows?: number
  maxRows?: number
}

const { modelValue, minRows = 3, maxRows = 50 } = defineProps<NiceTextareaProps>()

const emit = defineEmits(["update:model-value", "change"])

const setValue = (value: string) => {
  emit("update:model-value", value)
}

const autosize = computed(() => ({
  minRows,
  maxRows,
}))

defineExpose({
  setValue,
})
</script>

<style>
.el-textarea__inner {
  text-overflow: ellipsis;
  word-break: break-word;
}
</style>
