import { BROKER_STATUSES, type BrokerStatusTypes } from "@/config/constants"

export type BrokerStatusOption = {
  id: BrokerStatusTypes | null
  name: string
  icon: string
}
export const getBrokersStatusOptions = (
  translator: (key: string) => string,
  includeNoStatusOption = false
): Array<BrokerStatusOption> => {
  const options = Object.values(BROKER_STATUSES).map(status => ({
    icon: status.icon,
    id: status.value,
    name: translator(status.label),
  }))
  if (!includeNoStatusOption) {
    options.pop()
  }
  return options
}
