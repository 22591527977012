<template>
  <nice-popover :width="width" transition="none" :visible="visible" placement="top" trigger="click" :teleported="false">
    <div class="flex flex-col mt-1" v-click-outside="hide">
      <span class="text-xl px-4 py-2" v-if="title">{{ title }}</span>
      <ps-data-table
        :columns="columns"
        :data="tableData?.data || []"
        :per="per"
        :page="page"
        @update:page="updatePage"
        :total="tableData?.meta.total_count || 0"
        :loading="loading"
        v-if="columns"
      />
    </div>
  </nice-popover>
</template>

<script lang="ts" setup>
import { ref, watch } from "vue"

const emit = defineEmits(["toggleVisibility"])

export type TablePopoverProps = {
  tableData: { data: any[]; meta: { total_count: number } }
  columns: any[]
  title?: string
  width?: number
  fetch?: (params: { page: number; per: number }) => void
  per?: number
}

const { tableData, columns, title, width = 750, fetch, per = 10 } = defineProps<TablePopoverProps>()

const page = ref(1)
const visible = ref(false)
const loading = ref(true)

watch(
  () => tableData,
  () => {
    if (visible.value) return
    loading.value = false
    visible.value = true
    page.value = 1
    emit("toggleVisibility", true)
  }
)

function hide() {
  if (!visible.value) return
  visible.value = false
  loading.value = true
  emit("toggleVisibility", false)
}

function updatePage(pg: number) {
  page.value = pg
  fetch?.({ page: pg, per })
}
</script>
