<template>
  <div>
    <form-dialog
      :name="$t('links.title')"
      :id="editable.id"
      :visible="dialogVisible"
      :saving="saving"
      @close="dialogVisible = false"
      @submit="saveItem"
    >
      <form-row :title="$t('links.name')">
        <div class="w-full relative">
          <nice-input size="small" :placeholder="$t('links.namePh')" v-model="editable.title" />
          <char-counter
            :count="editable.title ? editable.title.length : 0"
            :total="30"
            :title="$t('links.nameCounter')"
          />
        </div>
      </form-row>

      <form-row :title="$t('links.url')">
        <nice-input size="small" :placeholder="$t('links.urlPh')" v-model="editable.url" />
      </form-row>

      <form-row :title="$t('links.tags')" :hint="$t('links.tagsHint')">
        <tag-select v-model="editable.tags" :placeholder="$t('links.tagsPh')" :options="tags" allow-create />
      </form-row>

      <form-row :title="$t('links.portals')">
        <nice-switch :model-value="!editable.is_private" @update:model-value="editable.is_private = !$event" />
      </form-row>

      <form-row :title="$t('links.portalsSpecific')" :class="{ disabled: editable.is_private }">
        <db-select collection="uniqConnections" multiple v-model="editable.connection_ids" />
      </form-row>

      <form-row :title="$t('links.lp')">
        <nice-switch v-model="editable.on_landing_page" />
      </form-row>

      <form-row :title="$t('links.lpEmbedable')" :class="{ disabled: !editable.on_landing_page }">
        <nice-switch v-model="editable.is_embedable" />
      </form-row>

      <form-row :title="$t('links.projectHeader')">
        <nice-switch v-model="editable.pinned" />
      </form-row>
    </form-dialog>

    <header class="d-flex justify-content-between align-items-center mb-3">
      <div>
        <h5 class="fs-16">
          {{ $customFilters.pluralize(items.length, $t("links.title"), $t("links.titlePlural")) }}
        </h5>
        <p class="text-muted fs-12" v-if="parentProjectLinksCount">
          + {{ $customFilters.pluralize(parentProjectLinksCount, $t("links.projLink"), $t("links.projLinks")) }}
        </p>
      </div>
      <nav>
        <ps-button v-if="canEdit" icon="cloud-upload" active @click="openDialog()" :title="$t('links.addLink')" />
      </nav>
    </header>
    <draggable class="wrap" :list="items" item-key="id" @end="updatePositions">
      <template #item="{ element: item }">
        <media-item :title="item.title" :url="getLink(item.url)" :class="{ 'global-bg': item.global }">
          <template v-slot:icon>
            <fa-icon name="link" class="text-blue-700" />
          </template>
          <template v-slot:subtitle>
            <fa-icon v-if="item.global" name="globe" />
            {{ getSubtitle(item) }}
          </template>
          <template v-slot:actions>
            <nice-tooltip v-if="item.variable_name" :content="$t('links.copy')">
              <hover-action @click="copy(item)" icon="brackets-curly" />
            </nice-tooltip>
            <hover-action v-if="canEdit && !item.global" @click="openDialog(item)" icon="pencil" />
            <hover-action
              v-if="canEdit && !item.global"
              @click="deleteItem(item)"
              icon="trash-alt"
              class="text-danger"
            />
          </template>
        </media-item>
      </template>
    </draggable>
  </div>
</template>

<script>
import MediaItem from "./Item"
import UploadButton from "./UploadButton"

export default {
  props: [
    "propertyId",
    "projectId",
    "tags",
    "initialItems",
    "parentProjectLinksCount",
    "brokerIds",
    "readonly",
    "property",
    "project",
  ],
  components: {
    MediaItem,
    UploadButton,
  },
  data(props) {
    return {
      items: props.initialItems,
      editable: {},
      saving: false,
      dialogVisible: false,
      selectedIds: [],
    }
  },
  methods: {
    copy(item) {
      this.$util.copy(
        `{{ ${this.projectId ? "projekt" : "einheit"}.${item.variable_name}_button | linktext: "${item.title}" }}`
      )
      App.flashy(this.$t("links.copySuccess"))
    },
    getLink(link) {
      if (link.search(/^http[s]?\:\/\//) == -1) {
        link = "https://" + link
      }
      return link
    },
    getSubtitle(link) {
      if (link.is_private && !link.on_landing_page) {
        return this.$t("links.subtitle.non")
      } else if (link.is_private && link.on_landing_page) {
        return this.$t("links.subtitle.lp")
      } else if (!link.is_private && !link.on_landing_page) {
        return this.$t("links.subtitle.portals")
      } else if (!link.is_private && link.on_landing_page) {
        return this.$t("links.subtitle.lpPortals")
      }
    },
    openDialog(item = { is_private: true }) {
      this.editable = JSON.parse(JSON.stringify(item))
      this.dialogVisible = true
    },
    saveItem(itm, preventUpdate) {
      const item = itm || this.editable
      this.saving = true

      this.$axios
        .request({
          method: item.id ? "PUT" : "POST",
          url: `/api/v1/links/${item.id || ""}`,
          data: {
            link: item,
            property_id: this.$parent.propertyId,
            project_id: this.$parent.projectId,
          },
        })
        .then(({ data }) => {
          this.saving = false
          this.dialogVisible = false
          item.variable_name = data.variable_name
          if (item.id) {
            if (!preventUpdate) this.items[this.items.findIndex(itm => itm.id === item.id)] = item
          } else {
            item.id = data.id
            this.items.push(item)
          }
        })
        .catch(err => {
          this.saving = false
          this.$axios.handleError(err)
        })
    },
    deleteItem(item) {
      this.$warn(
        {
          title: this.$t("links.delete.title"),
          desc: this.$t("links.delete.desc"),
          confirmButtonText: this.$t("links.delete.submit"),
          redButton: true,
        },
        () => {
          this.$axios
            .delete(`/api/v1/links/${item.id}`)
            .then(() => {
              this.items = this.items.filter(o => o.id !== item.id)
              App.flashy(this.$t("links.delete.success"))
            })
            .catch(this.$axios.handleError)
        }
      )
    },
    updatePositions(event) {
      this.$axios.put(this.sortUrl, {
        item: this.items.map(o => o.id),
      })
    },
  },
  computed: {
    canEdit() {
      if (this.readonly) return false

      if (this.projectId) {
        return (
          this.$db.hasRight("rightToEditUnits") ||
          (this.$db.hasRight("rightToEditSharedUnits") &&
            !!this.project &&
            (this.project.writeBrokerIds?.includes(this.$db.broker.id) ||
              this.project.writeDepartmentIds?.some(id => this.$db.broker.departmentIds.includes(id)))) ||
          (!!this.brokerIds && this.brokerIds.includes(this.$db.broker.id))
        )
      }

      return (
        this.$db.hasRight("rightToEditUnits") ||
        (this.$db.hasRight("rightToEditSharedUnits") &&
          !!this.property &&
          (this.property.brokerIds?.includes(this.$db.broker.id) ||
            this.property.departmentIds?.some(id => this.$db.broker.departmentIds.includes(id)))) ||
        (!!this.brokerIds && this.brokerIds.includes(this.$db.broker.id))
      )
    },
    selected() {
      return this.items.filter(item => this.selectedIds.includes(item.id))
    },
    baseURL() {
      return "/api/v1/links"
    },
    sortUrl() {
      const sortable_type = this.projectId ? "Project" : "Property"
      const sortable_id = this.projectId || this.propertyId
      return `${this.baseURL}/sort?sortable_type=${sortable_type}&sortable_id=${sortable_id}`
    },
  },
}
</script>

<style scoped>
.wrap {
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  grid-gap: 10px;
}

.global-bg {
  opacity: 0.7;
  background-color: #f0f0f0;
}
</style>
