<template>
  <p class="standardList-item">
    <a v-if="editable" href="#" @click.prevent="$emit('delete', filter.id)" class="standardList-remove">
      <fa-icon name="trash-alt" />
    </a>
    <span v-if="editable" class="standardList-itemTitle">
      <input type="text" v-model="filter.name" class="table-form-control" @keydown.enter="blur" @change="save" />
    </span>
    <span v-if="!editable" class="standardList-itemTitle">
      {{ filter.name }}
    </span>
    <span class="checkbox check-primary mb-0" style="padding-top: 0.5rem">
      <input type="checkbox" :id="`desktop_filter_default_${filter.id}`" :checked="selected || null" @change="change" />
      <label :for="`desktop_filter_default_${filter.id}`"></label>
    </span>
    <span class="checkbox check-primary mb-0" style="padding-top: 0.5rem">
      <input
        type="checkbox"
        :id="`mobile_filter_default_${filter.id}`"
        :checked="selectedForMobile || null"
        @change="changeForMobile"
      />
      <label :for="`mobile_filter_default_${filter.id}`"></label>
    </span>
  </p>
</template>

<script>
export default {
  emits: ["change", "changeForMobile"],
  props: {
    filter: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
    },
    selectedForMobile: {
      type: Boolean,
    },
    entity: {
      type: String,
    },
  },

  computed: {
    editable() {
      return this.filter.brokerId == this.$db.broker.id || this.$db.broker.admin
    },
  },

  methods: {
    blur(event) {
      event.target && event.target.blur()
    },

    save() {
      const data = {
        search_filter: {
          name: this.filter.name,
        },
      }

      this.$axios.put(`/services/search_filters/${this.filter.id}`, data)
    },

    change(evt) {
      const val = this.selected ? null : this.filter.id.toString()
      this.$emit("change", val)

      if (this.entity === "for_contacts") {
        this.$db.updateBroker({ defaultClientsFilter: val })
      } else if (this.entity === "for_properties") {
        this.$db.updateBroker({ defaultPropertiesFilter: val })
      } else if (this.entity === "for_activities") {
        this.$db.updateBroker({ defaultActivitiesFilter: val })
      } else if (this.entity === "for_deals") {
        this.$db.updateBroker({ defaultDealsFilter: val })
      }
    },

    changeForMobile(evt) {
      const val = this.selectedForMobile ? null : this.filter.id.toString()
      this.$emit("changeForMobile", val)

      if (this.entity === "for_contacts") {
        this.$db.updateBroker({ defaultClientsFilterForMobile: val })
      } else if (this.entity === "for_properties") {
        this.$db.updateBroker({ defaultPropertiesFilterForMobile: val })
      } else if (this.entity === "for_activities") {
        this.$db.updateBroker({ defaultActivitiesFilterForMobile: val })
      } else if (this.entity === "for_deals") {
        this.$db.updateBroker({ defaultDealsFilterForMobile: val })
      }
    },
  },
}
</script>
