<template>
  <div
    class="dropdown-item"
    @click="clicked"
    :class="disabled && tooltip ? 'disabled-tooltip' : disabled ? 'disabled' : ''"
  >
    <slot>
      <nice-tooltip v-if="tooltip" :content="tooltip" placement="left">
        <span class="dropdown-item-title">
          <slot name="title">{{ title }}</slot>
        </span>
      </nice-tooltip>
      <span v-else class="dropdown-item-title">
        <slot name="title">{{ title }}</slot>
      </span>

      <div v-if="hasToggle" class="dropdown-item-detail">
        <nice-switch :model-value="toggleActive" :width="30" />
      </div>
    </slot>
    <nice-label v-if="highlightAsNew" color="#00ff00" size="mini" class="mr-3 uppercase">
      {{ $t("labels.new") }}
    </nice-label>
  </div>
</template>

<script setup lang="ts">
import { useRouter } from "vue-router"
import useEnvironment from "@/plugins/use-environment"

const router = useRouter()
const emit = defineEmits(["click", "update:toggle-active"])

const { isBackdoor } = useEnvironment()

const { title, tooltip, hasToggle, toggleActive, link, newTab, highlightAsNew, sensitive, disabled } = defineProps<{
  title?: string
  tooltip?: string
  hasToggle?: boolean
  toggleActive?: boolean
  link?: string
  newTab?: boolean
  highlightAsNew?: boolean
  sensitive?: boolean
  disabled?: boolean
}>()

const clicked = (e: MouseEvent) => {
  if (disabled) return
  if (sensitive && isBackdoor.value) return App.alert("Aktion nicht erlaubt von Support")

  emit("click")

  if (hasToggle) {
    e.stopPropagation()
    emit("update:toggle-active", !toggleActive)
  } else if (link && newTab) {
    Object.assign(document.createElement("a"), {
      target: "_blank",
      rel: "noopener noreferrer",
      href: link,
    }).click()
  } else if (link) {
    router.push(link)
  }
}
</script>

<style scoped>
.dropdown-item {
  min-height: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

a {
  color: inherit;
}

.dropdown-item:hover {
  background-color: rgba(0, 0, 0, 0.07);
  /* color: inherit; */
}

.dropdown-item-title {
  outline: none;
  padding-left: 14px;
  padding-right: 14px;
  flex: 1;
  word-break: normal;
}
.dropdown-item-detail {
  padding-right: 14px;
}

.disabled-tooltip {
  opacity: 0.45;
  cursor: default;
}
.disabled-tooltip:hover {
  background-color: rgba(0, 0, 0, 0);
}
</style>
