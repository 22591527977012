<template>
  <div :class="['alert', `${type ? `alert--type-${type}` : ''}`]">
    <fa-icon v-if="type !== null && IconMap[type]" :name="IconMap[type]" class="mr-3 text-xl" />
    <div class="text-sm" :class="{ 'self-center': center }">
      <slot />
    </div>
  </div>
</template>

<script lang="ts" setup>
// TODO: OCR-3831 consolidate with el-alert
export type AlertType = "success" | "danger" | "warning" | "info" | null
const { type = null, center = true } = defineProps<{
  type: AlertType
  center?: boolean
}>()
const IconMap = {
  danger: "exclamation-triangle",
  warning: "exclamation",
  success: "check",
  info: "info-circle",
}
</script>

<style scoped lang="postcss">
.alert {
  @apply px-3 py-2 rounded flex border bg-gray-100 text-gray-700 border-gray-900/10;
}
.alert--type-success {
  @apply bg-green-100 text-green-700 border-green-900/10;
}
.alert--type-danger {
  @apply bg-red-100 text-red-700 border-red-900/10;
}
.alert--type-warning {
  @apply bg-yellow-100 text-yellow-700 border-yellow-900/10;
}
.alert--type-info {
  @apply bg-blue-100 text-blue-700 border-blue-900/10;
}
</style>
