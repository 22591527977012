<template>
  <span id="reserved-bubbles" class="bubbles d-flex ml-3">
    <bubble
      v-for="(bubble, $idx) in bubbles"
      :key="$idx"
      :tooltipText="`${bubble.count}x ${bubble.name}`"
      :color="bubble.color"
      :url="url"
      class="mr-1"
    >
      {{ bubble.count }}
    </bubble>
  </span>
</template>

<script lang="ts">
import { PropType, defineComponent } from "vue"
import { DealBubble, DealAggregation } from "../../interfaces"

export default defineComponent({
  props: {
    dealAggregations: {
      type: Array as PropType<DealAggregation[]>,
    },
    url: {
      type: String,
      required: true,
    },
  },
  computed: {
    bubbles(): DealBubble[] {
      return _.orderBy(
        this.dealAggregations.map(da => {
          if (!da.dealStageId)
            return {
              chance: 0,
              name: "Abgesagt",
              color: "#414241",
              count: da.count,
            }

          return {
            ...(this.$db.get("dealStages").find(o => o.id == da.dealStageId) || {}),
            count: da.count,
          }
        }),
        "chance",
        "asc"
      )
    },
  },
})
</script>
