import { computed } from "vue"
import useCore from "./use-core"

const useShop = () => {
  const { db } = useCore()

  const shop = computed(() => db.shopData)
  const currency = computed(() => db.shopData?.currency)

  const isSpanish = computed(() => db.shopData?.internationalCountry == "spain")

  return {
    shop,
    currency,
    isSpanish,
  }
}

export default useShop
