<template>
  <widget-container :widget="widget" :config="config">
    <div v-for="item in brokers" :key="item.id" class="flex items-center rounded p-2 mx-1 hover:bg-black/5">
      <aside class="mr-2">
        <avatar size="24px" :broker="item" />
      </aside>
      <main class="flex-grow pl-1">
        <p class="leading-snug">{{ item.name }}</p>
      </main>
      <span class="ml-2 text-gray-600 inline-flex items-center">
        <span class="text-sm mr-2">
          {{ item.availabilityLabel }}
        </span>
        <broker-availability :broker="item" class="text-lg text-center" style="width: 16px" />
      </span>
    </div>
    <p v-if="!brokers.length" class="px-2 mx-1 text-sm text-gray-600 italic mt-2">
      {{ $t("widget.emptystates.availabilities") }}
    </p>
  </widget-container>
</template>

<script>
import { getBrokersStatusOptions } from "@/utils/get-brokers-status-options"
import WidgetContainer from "./WidgetContainer.vue"
import BrokerAvailability from "@/components/BrokerAvailability.vue"

export default {
  components: { WidgetContainer, BrokerAvailability },
  props: {
    widget: {},
    config: {},
  },
  computed: {
    brokers() {
      let { brokers, departments } = this.$db.shopData
      const { departmentIds, brokerIds } = this.widget

      brokers = brokers.filter(b => b.availability)
      if (departmentIds && departmentIds.length) {
        brokers = brokers.filter(b => departments.some(d => departmentIds.includes(d.id) && d.brokerIds.includes(b.id)))
      }
      if (brokerIds && brokerIds.length) {
        brokers = brokers.filter(b => brokerIds.includes(b.id))
      }
      const BrokerAvailabilityOptions = getBrokersStatusOptions(this.$t)
      return brokers.map(b => ({
        ...b,
        availabilityLabel: BrokerAvailabilityOptions.find(opt => opt.id === b.availability).name,
      }))
    },
  },
}
</script>
