<template>
  <nice-upload
    drag
    :action="action"
    :multiple="multiple"
    :with-credentials="true"
    :show-file-list="false"
    :before-upload="beforeUpload"
    :on-success="onSuccess"
    :on-error="onError"
    :accept="accept"
    :disabled="uploading"
    :style="{ width: width }"
  >
    <div :class="styleRow ? 'flex flex-row items-center py-1 px-2' : 'p-3'">
      <fa-icon name="cloud-upload" class="text-gray-400" :class="styleRow ? 'mr-2' : 'text-3xl'" />
      <p>
        <spinner v-if="uploading" />
        <small v-else>{{ $t(title) }}</small>
      </p>
    </div>
  </nice-upload>
</template>

<script lang="ts" setup>
import { ref } from "vue"
import useCore from "@/plugins/use-core"

export type UploadAreaProps = {
  action: string
  accept?: string
  title?: string
  width?: string
  styleRow?: boolean
  multiple?: boolean
}
const {
  action,
  accept = "image/jpeg,image/png",
  title = "uploadArea.uploadPhoto",
  width = "100%",
  styleRow = false,
  multiple = false,
} = defineProps<UploadAreaProps>()

const emits = defineEmits(["success", "error"])
const { axios } = useCore()

const uploading = ref(false)

function beforeUpload() {
  uploading.value = true
}
function onSuccess(response: any) {
  uploading.value = false
  emits("success", response)
}
function onError(response: any) {
  uploading.value = false
  emits("error", response)
  try {
    axios.handleError({ response: { data: JSON.parse(response.message) } })
  } catch {
    axios.handleError(response)
  }
}
</script>

<style>
.el-upload {
  width: 100%;
  --el-upload-dragger-padding-horizontal: 0px;
  --el-upload-dragger-padding-vertical: 0px;
}
.el-upload-dragger {
  width: 100%;
  height: initial;
}
</style>
