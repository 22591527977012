<script lang="ts">
import VueGridLayout from "vue3-grid-layout"
import BirthdaysWidget from "@/components/dashboard/BirthdaysWidget.vue"
import EventsWidget from "@/components/dashboard/EventsWidget.vue"
import FollowupsWidget from "@/components/dashboard/FollowupsWidget.vue"
import TodosWidget from "@/components/dashboard/TodosWidget.vue"
import AvailabilitiesWidget from "@/components/dashboard/AvailabilitiesWidget.vue"
import ActivitiesWidget from "@/components/dashboard/ActivitiesWidget.vue"
import EmailsWidget from "@/components/dashboard/EmailsWidget.vue"
import ContactsWidget from "@/components/dashboard/ContactsWidget.vue"
import PropertiesWidget from "@/components/dashboard/PropertiesWidget.vue"
import ChartsWidget from "@/components/dashboard/ChartsWidget.vue"
import IframeWidget from "@/components/dashboard/IframeWidget.vue"
import DealsWidget from "@/components/dashboard/DealsWidget.vue"
import DayFilter from "@/components/dashboard/DayFilter.vue"
import EmailLocationDropdown from "@/components/dashboard/EmailLocationDropdown.vue"
import ChartsWidgetConfig from "@/components/dashboard/ChartsWidgetConfig.vue"

export type Widget = {
  id: number
  x: number
  y: number
  w: number
  h: number
  i: string
  category: string
  color: string
}

export default {
  emits: ["layoutUpdate", "resizeItem", "editItem", "deleteItem"],
  props: {
    items: {
      type: Array as () => Widget[],
      required: true,
    },
    config: {
      type: Object as () => Record<string, any>,
      required: true,
    },
    editMode: {
      type: Boolean as () => boolean,
      default: false,
    },
    presetsEnabled: {
      type: Boolean as () => boolean,
      default: false,
    },
  },
  components: {
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    BirthdaysWidget,
    EventsWidget,
    FollowupsWidget,
    AvailabilitiesWidget,
    ActivitiesWidget,
    TodosWidget,
    EmailsWidget,
    ContactsWidget,
    PropertiesWidget,
    ChartsWidget,
    IframeWidget,
    DealsWidget,
    DayFilter,
    EmailLocationDropdown,
    ChartsWidgetConfig,
  },
}
</script>

<template>
  <main class="flex-grow relative w-full pb-6 pl-[20px] pr-[10px]">
    <grid-layout
      :layout="items"
      @update:layout="$emit('layoutUpdate', $event)"
      :col-num="3"
      use-css-transforms
      :row-height="200"
      :is-draggable="editMode"
      :is-resizable="editMode"
      :is-mirrored="false"
      :vertical-compact="true"
      :margin="[10, 10]"
      data-testid="grid-dashboard_widgets"
    >
      <grid-item
        v-for="item in items"
        :x="item.x"
        :y="item.y"
        :w="item.w"
        :h="item.h"
        :i="item.i"
        :key="item.id"
        class="border border-gray-100 rounded shadow-sm relative js-dashboard-grid-item"
        :class="{ 'bg-gray-50': !item.color }"
        :style="item.color && `background-color: ${item.color}`"
        :data-id="item.id"
        @resized="$emit('resizeItem', item)"
      >
        <hover-actions v-if="editMode" class="active" top="10px" right="10px">
          <hover-action icon="pencil" @click="$emit('editItem', item)" />
          <hover-action icon="trash-alt" class="text-danger" @click="$emit('deleteItem', item)" />
        </hover-actions>
        <component :is="[item.category, 'Widget'].join('')" :widget="item" :config="config" />
      </grid-item>
    </grid-layout>
  </main>
</template>

<style lang="postcss">
.vue-grid-layout {
  width: 100%;
}

.vue-grid-item.vue-resizable .widget-container {
  pointer-events: none;
  opacity: 0.75;
}

.vue-grid-item.vue-grid-placeholder {
  opacity: 1;
  @apply bg-blue-100;
}

.vue-grid-focus {
  z-index: 1;
}
</style>
