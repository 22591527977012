<template>
  <div class="messageItem" :class="classes">
    <nice-checkbox :model-value="checked" @update:model-value="$emit('check', message.id)" class="mli-checkWrap" />
    <a href="#" @click="clicked" class="mli-inner">
      <div class="mli-sidebar">
        <div :title="$t('inbox.messageItem.unreadIndicator')" class="mli-unread-indicator"></div>
        <avatar
          v-if="
            message.broker_id &&
            message.broker_ids.length &&
            (!$route.query.inboxBrokerId
              ? message.broker_id != $db.broker.id
              : message.broker_id != $route.query.inboxBrokerId)
          "
          :broker-id="message.broker_id"
          size="15px"
          class="text-black relative right-1 mb-1"
        />
        <div class="mli-processed-indicator"></div>
        <i class="fal fa-star mli-favorite-indicator"></i>
      </div>

      <div class="mli-head">
        <p class="sender">
          <nice-tooltip :content="$t('inbox.messageItem.viewReply')" v-if="message.replied">
            <a
              @click.stop.prevent="navigateToMessage('replied')"
              class="message-relation-link"
              href="#"
            >
              <fa-icon name="reply" class="fs-12 mr-1 hover:text-blue-500" />
            </a>
          </nice-tooltip>
          <nice-tooltip :content="$t('inbox.messageItem.viewForwardedMessage')" v-if="message.forwarded">
            <a
              @click.stop.prevent="navigateToMessage('forwarded')"
              class="message-relation-link"
              href="#"
            >
              <fa-icon name="share" class="fs-12 mr-1" />
            </a>
          </nice-tooltip>
          {{ message.sender.name || $t("inbox.messageItem.noRecipient") }}
        </p>
        <p class="date sub flex">
          <comments-indicator
            resource-type="Message"
            :resource-id="message.id"
            v-model:counter="message.comments_count"
          />
          <fa-icon v-if="message.attachments > 0" name="paperclip" class="mr-1" />
          {{ $customFilters.emailCalendar(message.createdAt) }}
        </p>
        <actions :message="message" class="mli-actions" @trigger-action="$emit('trigger-action', $event)" />
      </div>

      <div class="mli-content">
        <p class="mli-subject">{{ message.subject }}</p>
        <p class="mli-body">{{ message.body }}</p>
        <nice-label v-if="category" :color="category.color" :label="category.name" class="mt-1 mr-1" />
        <nice-label v-if="source" color="#ffbb00" :label="source.name" class="mt-1 mr-1" />
        <nice-label v-if="groupsLabelName" color="#ff00bb" :label="groupsLabelName" class="mt-1 mr-1" />
        <nice-label v-if="message.send_at" :title="$t('inbox.messageItem.scheduled')" class="mt-2" color="#ffbb00">
          <fa-icon name="clock" class="mr-1" />
          <span>{{ $t("inbox.messageItem.scheduledAt") }}</span>
          {{ $customFilters.datetime(message.send_at) }} Uhr
        </nice-label>
      </div>
    </a>
  </div>
</template>

<script>
import Actions from "./Actions"
import CommentsIndicator from "../CommentsIndicator"
import eventBus from "@/config/event-bus"

export default {
  props: ["message", "checked", "opened"],

  components: {
    Actions,
    CommentsIndicator,
  },

  methods: {
    clicked(event) {
      event.preventDefault()

      if (event.metaKey || event.altKey) {
        this.$emit("check", this.message.id)
      } else if (event.shiftKey) {
        this.$emit("check", this.message.id) // geiler wäre, wenn er es schafft, alle aus dem range zu selektieren
      } else {
        if (this.message.is_draft) {
          eventBus.$emit("quick-view", {
            type: "mail",
            mode: "edit",
            params: { source: { draftId: this.message.id } },
          })
        } else {
          this.$emit("open", this.message)
        }

        // this.$store.commit("inbox/setSelected", [])
      }
    },
    async navigateToMessage(linkType = "replied") {
      const queryName = linkType === "replied" ? "repliedMessage" : "forwardedMessage"
      const query = `query ${queryName} {
        ${queryName}(originalMessageId: ${this.message.id}) {
          id
        }
      }`
      const response = await this.$graphql(query)

      const linkedMessage = response[queryName]
      if (linkedMessage) {
        eventBus.$emit("quick-view", { type: "message", mode: "view", id: linkedMessage.id })
      } else {
        App.alert(this.$t("inbox.messageItem.viewMessageError"))
      }
    },
  },

  computed: {
    classes() {
      return {
        unread: !this.message.read,
        processed: this.message.processed,
        checked: this.checked,
        favorite: this.message.starred,
        active: this.message.id === this.opened,
        "bg-green-50": this.message.send_at,
      }
    },
    source() {
      return this.$db.get("clientSources").find(c => c.id == this.message.client_source_id)
    },
    category() {
      return this.$db.get("messageCategories").find(c => c.id == this.message.message_category_id)
    },
    groups() {
      return this.$db.get("activityGroups").filter(g => this.message.group_ids.includes(g.id))
    },
    groupsLabelName() {
      const groupsLength = this.groups.length
      if (groupsLength > 0) {
        const groupName = this.groups[0].name
        return groupsLength > 1 ? `${groupName} +${groupsLength - 1}` : groupName
      }
    },
  },
}
</script>

<style scoped>
.messageItem {
  display: flex;
  border-bottom: 1px solid #e3e3e3;
  position: relative;
}
.messageItem.active,
.messageItem:hover {
  background-color: #f3f3f3;
  color: inherit;
}

.messageItem.checked {
  background: #f5f5f5;
}

.messages-container--compact .messageItem .mli-body,
.messages-container--compact .messageItem .mli-body ~ .label {
  display: none;
}

.messageItem > a {
  display: block;
  color: inherit;
  padding: 12px 12px 12px 30px;
  position: relative;
  width: 100%;
}

.messageItem .mli-head {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.messageItem .mli-content {
  position: relative;
}

.messageItem .sender {
  font-size: 1.05rem;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 0.5rem;
}

.mli-head .date {
  color: #777;
  flex-shrink: 0;
}

.mli-subject {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mli-body {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #777;
}

.messageItem .subject {
  height: 22px;
  overflow: hidden;
}

.messageItem .mli-sidebar {
  width: 9px;
  position: absolute;
  left: 10px;
  top: 40px;
}

.messageItem .mli-favorite-indicator {
  font-size: 90%;
  color: gold;
  display: none;
  position: relative;
  right: 2px;
  margin-bottom: 5px;
}

.messageItem .mli-unread-indicator {
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: blue;
  display: none;
  margin-bottom: 5px;
}

.messageItem .mli-processed-indicator {
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: #bf0;
  display: none;
  margin-bottom: 5px;
}

.messageItem.unread .mli-unread-indicator {
  display: block;
}
.messageItem.unread .sender,
.messageItem.unread .mli-subject,
.messageItem.unread .date {
  font-weight: 600;
  color: black;
}

.messageItem.processed .mli-processed-indicator {
  display: block;
}

.messageItem.favorite .mli-favorite-indicator {
  display: block;
}

.messageItem .mli-checkWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 3px;
  left: 1px;
  padding: 10px 7px 20px;
  z-index: 1;
}

.mli-actions {
  visibility: hidden;
  position: absolute;
  right: 0;
}
.messageItem:hover .mli-actions {
  visibility: visible;
}

.message-relation-link {
  display: inline-block;
  color: inherit;
  transition: color 0.2s ease;
  cursor: pointer;
}

.message-relation-link:hover {
  color: #0000cc;
  -webkit-text-stroke-width: 0.5px;
  -webkit-text-stroke-color: #0000cc;
}
</style>
