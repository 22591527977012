<template>
  <section class="mb-4">
    <form-dialog name="Grundriss" :id="editable.id" v-model:visible="dialogVisible" :saving="saving" @submit="saveItem">
      <div class="form-group" :class="{ 'has-error': editable.title && editable.title.length > 30 }">
        <label>Titel</label>
        <input type="text" v-model="editable.title" class="form-control" />
        <small v-if="editable.title && editable.title.length > 30" class="mt-1 text-danger">
          Der Titel ist zu lang ({{ editable.title.length }}/30)
        </small>
      </div>
    </form-dialog>

    <header class="d-flex justify-content-between align-items-center">
      <h5 class="fs-16">Grundrisse ({{ items.length }})</h5>
    </header>

    <draggable
      class="file-items"
      :model-value="items"
      @update:model-value="items = $event"
      item-key="id"
      handle=".fileItem-handle"
      @end="updatePositions"
    >
      <template #item="{ element: item }">
        <div class="fileItem">
          <i class="fileItem-handle icon-cursor-move">
            <fa-icon name="sort" />
          </i>
          <a :href="item.url" target="_blank" class="file-item-title">
            <span class="mr-2" :title="$customFilters.datetime(item.created_at)">{{ item.title }}</span>
          </a>
          <nice-button
            v-if="$db.hasRight('rightToEditUnits')"
            size="small"
            icon="edit"
            @click.prevent="openDialog(item)"
          >
            <fa-icon name="pencil" />
          </nice-button>
          <nice-button
            v-if="$db.hasRight('rightToEditUnits')"
            size="small"
            type="danger"
            @click.prevent="deleteItem(item)"
          >
            <fa-icon name="trash-alt" />
          </nice-button>
        </div>
      </template>
    </draggable>
  </section>
</template>

<script>
export default {
  props: ["propertyId", "projectId", "initialItems"],
  data(props) {
    return {
      items: props.initialItems,
      editable: {
        title: "",
      },
      saving: false,
      dialogVisible: false,
    }
  },
  methods: {
    openDialog(item = {}) {
      this.editable = JSON.parse(JSON.stringify(item))
      this.dialogVisible = true
    },
    saveItem() {
      this.saving = true

      this.$axios
        .request({
          method: "PUT",
          url: `${this.baseURL}/${this.editable.id}`,
          data: { floorplan: this.editable },
        })
        .then(({ data }) => {
          this.saving = false
          this.items[this.items.findIndex(item => item.id === this.editable.id)] = this.editable
          this.dialogVisible = false
        })
        .catch(err => {
          this.saving = false
          this.$axios.handleError(err)
        })
    },
    deleteItem(item) {
      if (confirm("Soll der Link wirklich gelöscht werden?")) {
        this.$axios
          .delete(`${this.baseURL}/${item.id}`)
          .then(() => {
            this.items = this.items.filter(o => o.id !== item.id)
            App.flashy("Link wurde erfolgreich gelöscht.")
          })
          .catch(this.$axios.handleError)
      }
    },
    updatePositions(event) {
      this.$axios.put(this.sortUrl, {
        item: this.items.map(o => o.id),
      })
    },
  },
  computed: {
    baseURL() {
      return "/api/v1/floorplans"
    },
    sortUrl() {
      const sortable_type = this.projectId ? "Project" : "Property"
      const sortable_id = this.projectId || this.propertyId
      return `${this.baseURL}/sort?sortable_type=${sortable_type}&sortable_id=${sortable_id}`
    },
  },
}
</script>
