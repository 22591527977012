<template>
  <nice-tooltip :content="props.disabledText" :disabled="!props.disabled" :placement="props.placement">
    <div>
      <div :disabled="props.disabled || undefined"><slot /></div>
    </div>
  </nice-tooltip>
</template>
<script setup lang="ts">
const props = defineProps<{
  disabledText: string
  disabled: boolean | undefined
  placement?: string
}>()
</script>
