import { useI18n } from "vue-i18n"

const ACTIVITY_ACTIONS_MAP = {
  cancelation: "activityTypes.locales.cancelation",
  inquiry: "activityTypes.locales.inquiry",
  reminder: "activityTypes.locales.reminder",
  letter: "activityTypes.locales.letter",
  message: "activityTypes.locales.message",
  policy: "activityTypes.locales.policy",
  note: "activityTypes.locales.note",
  sms: "activityTypes.locales.sms",
  call: "activityTypes.locales.call",
  publishing: "activityTypes.locales.publishing",
  event: "activityTypes.locales.event",
}

export default function useActivityTypes() {
  const { t } = useI18n()
  const activityTypes = Object.entries(ACTIVITY_ACTIONS_MAP).reduce((acc, [key, value]) => {
    acc[key] = t(value)
    return acc
  }, {})
  return activityTypes
}
