<template>
  <div
    class="group p-2 bg-gray-50 rounded border border-gray-100 relative hover:border-gray-200 hover:bg-gray-100 transition-all"
  >
    <div>
      <div class="font-medium">
        <router-link :to="$customFilters.clientPath(resource.clientId)" class="link">
          {{ resource.client.name }}
        </router-link>
        <span v-if="resource.property" class="text-gray-400 font-mono font-normal">|</span>
        <router-link :to="`/portfolio/properties/${resource.propertyId}`" v-if="resource.property" class="link">
          {{ resource.property.name }}
        </router-link>
      </div>
      <div class="text-gray-600 text-sm">
        <span>{{ $customFilters.numberToCurrency(resource.soldPrice) }}</span>
        <nice-divider v-if="resource.soldPrice && resource.startDate" direction="vertical" />
        <span>
          {{ $customFilters.date(resource.startDate) }}
        </span>
      </div>
      <p class="text-sm text-gray-600 line-clamp-3" v-if="resource.note">{{ resource.note }}</p>
    </div>
    <dropdown class="absolute top-2 right-2 invisible group-hover:visible">
      <dropdown-section>
        <dropdown-item
          v-if="resource.client && resource.client.email"
          @click="openQuickview('mail', resource)"
          :title="$t('deals.lastCell.email')"
        />
        <dropdown-item @click="openQuickview('note', resource)" :title="$t('deals.lastCell.note')" />
        <dropdown-item @click="openQuickview('todo', resource)" :title="$t('deals.lastCell.todo')" />
        <dropdown-item @click="openQuickview('event', resource)" :title="$t('deals.lastCell.event')" />
      </dropdown-section>
      <dropdown-section>
        <dropdown-item
          @click="
            $router.push({
              path: '/contacts/feed',
              query: { clientIds: resource.clientId, propertyIds: resource.propertyId },
            })
          "
          :title="$t('deals.lastCell.activities')"
        />
      </dropdown-section>
      <dropdown-section>
        <dropdown-item
          v-if="!resource.deal_stage || resource.deal_stage.chance > 0"
          @click="openQuickview('reason', resource)"
          :title="$t('deals.cancellation')"
        />
        <dropdown-item v-if="resource.client" @click="$emit('visit', resource)" :title="$t('deals.edit')" />
        <dropdown-item
          v-if="$db.hasRight('rightToDeleteDeals')"
          class="text-red-600"
          @click="deleteDeal"
          :title="$t('deals.delete')"
        />
      </dropdown-section>
    </dropdown>
  </div>
</template>

<script>
import eventBus from "@/config/event-bus"

export default {
  props: {
    resource: {
      type: Object,
      required: true,
    },
  },
  methods: {
    openQuickview(type, { clientId, propertyId, projectId }, props = {}) {
      eventBus.$emit("quick-view", {
        type: type,
        mode: "edit",
        params: {
          source: {
            clientIds: clientId ? [clientId] : [],
            propertyIds: propertyId ? [propertyId] : [],
            projectIds: projectId ? [projectId] : [],
            ...props,
          },
        },
      })
    },

    deleteDeal() {
      this.$warn(
        {
          title: this.$t("deals.delWarnTitle"),
          desc: this.$t("deals.delWarnDesc"),
          confirmButtonText: this.$t("deals.delWarnSubmit"),
          redButton: true,
        },
        async () => {
          await this.$axios.delete(`/services/reservations/${this.resource.id}`)
          App.flashy(this.$t("deals.delWarnSuccess"))
          this.$emit("delete", this.resource)
        }
      )
    },
  },
}
</script>
