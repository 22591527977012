<template>
  <div id="quicktask" class="quickview-wrapper" :class="classType" style="display: block" v-if="visible">
    <component
      v-bind="getParams"
      v-bind:is="componentType"
      :key="key"
      :id="id"
      :type="type"
      @close="clearParams"
      @error="setError"
      @params="setParams"
    />
  </div>
</template>
<script lang="ts">
import eventBus from "@/config/event-bus"
import TaskForm from "@/views/panes/TaskForm.vue"
import InquiryManager from "@/views/panes/InquiryManager.vue"
import TaskDetail from "@/views/panes/TaskDetail.vue"
import LetterForm from "@/views/panes/LetterForm.vue"
import ContractForm from "@/views/panes/ContractForm.vue"
import MessageForm from "@/views/panes/MessageForm.vue"
import MessageView from "@/components/Inbox/MessageDetail.vue"
import QueryForm from "@/views/contacts/QueryForm.vue"
import TextMessageForm from "@/views/panes/TextMessageForm.vue"
import TextMessageDetail from "@/views/panes/TextMessageDetail.vue"
import PolicyDetail from "@/views/panes/PolicyDetail.vue"
import PublishingForm from "@/views/panes/PublishingForm.vue"
import OTAForm from "@/views/panes/OTAForm.vue"
import shortcuts from "@/utils/shortcuts"
import * as _ from "lodash"
import ScoutMessenger from "@/views/panes/ScoutMessenger.vue"

export const QUERY_TYPES = [
  "todo",
  "policy",
  "event",
  "query",
  "message",
  "sms",
  "letter",
  "task",
  "reason",
  "note",
  "inquiry",
  "reminder",
  "call",
  "cancelation",
  "decision",
  "mail",
  "publishing",
  "scoutPropertyId",
  "conversationId",
]

export type Params = { type?: string; id?: number; mode?: string; postfix?: Date; params?: Record<string, any> }

const serializeParams = params => {
  if (!params.type) return {}
  if (params.type === "event" && params.params?.date) {
    return { [params.type]: `${params.id}_${params.params?.date.toISOString()}` }
  }
  return { [params.type]: `${params.id}` }
}

const deserializeParams = (params: Params): Params => {
  const [type, value] = Object.entries(params)?.[0] ?? []
  if (!type || !value) return {}

  if (typeof value === "string") {
    if (type === "event" && value.includes("_")) {
      const [id, dateRaw] = value.split("_")
      const date = new Date(Date.parse(dateRaw))
      return { type, id: parseInt(id), mode: "view", postfix: date, params: { date } }
    }

    return { type, id: parseInt(value), mode: "view", params: {} }
  }

  return {}
}

export default {
  data() {
    return {
      mode: undefined as undefined | "view" | "edit",
      type: "",
      id: undefined,
      key: undefined,
      params: {} as Params,
      dirty: false,
      error: false,
    }
  },
  components: {
    TaskForm,
    TaskDetail,
    LetterForm,
    ContractForm,
    MessageForm,
    MessageView,
    QueryForm,
    TextMessageForm,
    TextMessageDetail,
    PolicyDetail,
    InquiryManager,
    PublishingForm,
    OTAForm,
    ScoutMessenger,
  },
  watch: {
    $route(from) {
      if (this.error) return this.$router.replace({ query: undefined })
      if (!!this.mode && this.mode !== "view") return
      this.syncRoute(from.query)
    },
    $data: {
      handler: function (val) {
        if (this.error || (!!this.mode && this.mode !== "view")) return
        this.syncQuery(val)
      },
      deep: true,
    },
    visible(newValue) {
      if (newValue) {
        this.error = false
        this.registerShortcuts()
        eventBus.$emit("quick-view@open", { id: this.id })
      } else {
        this.unbindShortcuts()
        eventBus.$emit("quick-view@close", { id: this.id })
      }
    },
  },
  methods: {
    syncRoute(query) {
      const params = _.pick(query, QUERY_TYPES)
      const nextData = {
        ...this.$data,
        ...this.nextParams(deserializeParams(params)),
      }
      if (_.isEqual(this.$data, nextData)) return
      Object.assign(this.$data, _.omit(nextData, "params"))
    },
    syncQuery(data) {
      if (data.mode == "edit") return
      const { query } = this.$route
      const nextQuery = { ..._.omit(query, QUERY_TYPES), ...serializeParams(data) }
      if (_.isEqual(query, nextQuery)) return
      this.$router.push({ query: nextQuery }).catch(() => {})
    },
    getKey({ type, mode, id, postfix }) {
      if (!type || !mode) return
      return [type, mode, id || `t${Date.now()}`, postfix].filter(Boolean).join("-")
    },
    nextParams({ type, id, mode, params, postfix }: Params) {
      const key = this.getKey({ type, mode, id, postfix })
      return { mode, type, id, key, params, dirty: false }
    },
    setParams({ type, id, mode, params, postfix }: Params) {
      Object.assign(this.$data, this.nextParams({ type, id, mode, params, postfix }))
    },
    updateParams({ params }: { params: Params }) {
      this.params = params
    },
    clearParams({ type, id, mode, postfix }: { type?: string; id?: string; mode?: string; postfix?: string } = {}) {
      const key = this.getKey({ type, mode, id, postfix })
      if (key && key !== this.key) return
      this.mode = undefined
      this.type = ""
      this.key = undefined
      this.id = undefined
      this.params = {}
      this.dirty = false
      eventBus.$emit("quick-view@close")
    },
    setError() {
      this.error = true
      this.clearParams()
    },
    setDirty() {
      this.dirty = true
    },
    getDefaultParams() {
      if (this.isMail && this.mode === "view") {
        return {
          hasCloseButton: true,
          messageId: this.id,
        }
      }
      return {}
    },
    registerShortcuts() {
      shortcuts.bind("esc", "quickview", _ => {
        this.clearParams()
      })
      shortcuts.setScope("quickview")
    },
    unbindShortcuts() {
      shortcuts.deleteScope("quickview")
      shortcuts.unbind("esc", "quickview")
    },
  },
  computed: {
    isMail() {
      return ["mail", "message"].includes(this.type)
    },
    isTask() {
      return [
        "task",
        "reason",
        "inquiry",
        "note",
        "todo",
        "reminder",
        "event",
        "call",
        "cancelation",
        "decision",
      ].includes(this.type)
    },
    isScoutMessenger() {
      return this.type === "scoutMessenger"
    },
    isLetter() {
      return this.type === "letter"
    },
    isQuery() {
      return this.type === "query"
    },
    isTextMessage() {
      return this.type === "sms"
    },
    isPolicy() {
      return this.type === "policy"
    },
    isPublishing() {
      return this.type === "publishing"
    },
    isOTA() {
      return this.type === "OTA"
    },
    classType() {
      if (this.isMail) {
        return "msg-quickview"
      }

      if (this.isScoutMessenger) {
        return "scout-messenger-quickview"
      }
      return "task-quickview"
    },
    getParams() {
      const params = { ...this.getDefaultParams(), ...(this.params || {}) }
      return params
    },
    visible() {
      return !!this.type
    },
    componentType() {
      if (this.type === "inquirymanager") return "InquiryManager"
      if (this.type === "contract") return "ContractForm"
      if (this.isPublishing) return "PublishingForm"
      if (this.isOTA) return "OTAForm"
      if (this.isTask && this.mode === "edit") return "TaskForm"
      if (this.isTask && this.mode === "view") return "TaskDetail"
      if (this.isLetter && this.mode == "edit" && !this.id) return "LetterForm"
      if (this.isLetter && this.mode == "view") return "TaskDetail"
      if (this.isLetter && (this.mode == "view" || this.id)) return "TaskForm"
      if (this.isMail && this.mode == "edit") return "MessageForm"
      if (this.isMail && this.mode == "view") return "MessageView"
      if (this.isQuery) return "QueryForm"
      if (this.isTextMessage && this.mode == "view") return "TextMessageDetail"
      if (this.isTextMessage && this.mode == "edit") return "TextMessageForm"
      if (this.isPolicy && this.mode == "view") return "PolicyDetail"
      if (this.isScoutMessenger) return "ScoutMessenger"
    },
  },
  mounted() {
    this.syncRoute(this.$route.query)

    eventBus.$on("quick-view", this.setParams)
    eventBus.$on("quick-view-clear", this.clearParams)
    eventBus.$on("quick-view-update", this.updateParams)

    const taskId = this.$route.query.task_id
    if (taskId) this.setParams({ type: "task", mode: "view", id: Number(taskId) })

    // if (QUERY_TYPES.some(n => n == Object.keys(this.$route.query)[0])) this.setRouteQuery()

    $(document).on("click", "[data-message-draft]", e => {
      e.preventDefault()
      const originalMessage = Number(e.target.dataset.messageDraft)
      this.setParams({ type: "message", mode: "edit", params: { source: { originalMessage } } })
    })

    $(document).on("click", "[data-quick-view]", e => {
      e.preventDefault()
      const id = Number(e.target.dataset.quickView)
      const type = e.target?.href?.includes("query=") ? "query" : "task"
      this.setParams({ type, mode: "view", id })
    })
  },
  beforeUnmount() {
    eventBus.$off("quick-view", this.setParams)
    eventBus.$off("quick-view-clear", this.clearParams)
    eventBus.$off("quick-view-update", this.updateParams)
  },
}
</script>
