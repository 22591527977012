<template>
  <db-select
    :model-value="brokerOrDepartmentId"
    collection="activeBrokers"
    @change="assignedChanged"
    @update:modelValue="assignedChanged"
    :additional-options="additionalBrokerOptions"
  />
</template>

<script setup lang="ts">
import { computed } from "vue"
import useCore from "@/plugins/use-core"
const { db } = useCore()

const emit = defineEmits(["update:departmentId", "update:brokerId", "change"])

const props = defineProps<{
  departmentId: Number
  brokerId: Number
}>()

const assignedChanged = newValue => {
  let departmentIdEmit = null as any
  let brokerIdEmit = null
  if (newValue) {
    if (typeof newValue === "string" && newValue.startsWith("dep-")) {
      const parsedDepartmentId = parseInt(newValue.replace("dep-", ""))
      if (!isNaN(parsedDepartmentId)) departmentIdEmit = parsedDepartmentId
    } else {
      brokerIdEmit = newValue
    }
  }
  emit("update:departmentId", departmentIdEmit)
  emit("update:brokerId", brokerIdEmit)
  emit("change")
}

const brokerOrDepartmentId = computed(() => props.brokerId || (props.departmentId ? `dep-${props.departmentId}` : null))

const additionalBrokerOptions = computed(() => {
  let departments = db.shopData.departments.map(d => ({
    id: `dep-${d.id}`,
    type: "department",
    internalName: d.name,
    name: d.name,
  }))
  return departments
})
</script>
