<template>
  <div class="app-panel" ref="messageForm">
    <a href="#" class="messageForm-toggleButton" @click.prevent="togglePane"></a>
    <nice-page-header>
      <template v-slot:leftMenu>
        <a href="#" @click.prevent="close" class="pane-button">
          <fa-icon name="times" />
        </a>
        <ul class="details-header-actions left">
          <li class="details-action">
            <span v-if="draft.saving" class="spinner spinner-sm"></span>
            <nice-tooltip
              v-else
              :content="$t('panes.messageForm.lastSaved', { time: lastSavedFormatted })"
              :disabled="!draft.lastSaved"
            >
              <a @click.prevent="discardDraft">
                <fa-icon name="trash-alt" />
              </a>
            </nice-tooltip>
          </li>
        </ul>
      </template>
      <template v-slot:rightButtons>
        <ul class="details-header-actions mr-3">
          <dropdown>
            <template v-slot:title>
              <fa-icon name="paperclip" />
            </template>
            <dropdown-section>
              <dropdown-item :title="$t('panes.messageForm.attach')" @click="triggerDropzone" />
              <letter-generator
                v-if="m.to.length"
                :client-email="m.to[0]"
                :property-id="m.properties[0]"
                :project-id="m.project_ids[0]"
                :team-id="m.team"
                @letter-generated="attachLetter"
              >
                <template v-slot:default="{ open }">
                  <dropdown-item :title="$t('panes.messageForm.letterAttachment')" @click="open" />
                </template>
              </letter-generator>
              <dropdown-item
                :title="$t('panes.messageForm.propertyAttachment')"
                :disabled="!m.properties.length"
                @click="handlePropertyAttachmentClick"
              />
              <dropdown-item
                :title="$t('panes.messageForm.projectAttachment')"
                :disabled="!(m.project_ids && m.project_ids.length)"
                @click="handleProjectAttachmentClick"
              />
              <dropdown-item
                :title="$t('panes.messageForm.contactAttachment')"
                :disabled="!messageClientIds.length"
                @click="handleContactAttachmentClick"
              />
              <dropdown-item :title="$t('panes.messageForm.sharepoint')" @click="launchOneDrivePicker()" />
              <dropdown-item :title="$t('panes.messageForm.dropbox')" @click="launchDropboxPicker()" />
            </dropdown-section>
          </dropdown>
          <li class="details-action">
            <a
              href="#"
              @click.prevent="draft.saving ? null : openPreview()"
              :class="{ disabled: draft.saving || preview.opening || disablePreview }"
              data-tagid="messagePreview"
            >
              <span v-if="preview.opening" class="spinner spinner-sm mr-2"></span>
              {{ $t("panes.messageForm.preview") }}
            </a>
          </li>

          <li class="details-action">
            <nice-tooltip
              htmlAllowed
              :content="
                sendAt
                  ? `<div class='text-center'>${$t('panes.messageForm.planned', {
                      time: $customFilters.longDatetime(sendAt),
                    })}</div>`
                  : $t('panes.messageForm.setPlan')
              "
            >
              <a ref="sendAtPopoverOpenRef" href="#" :class="{ 'bg-green-200': sendAt }">
                <fa-icon name="clock" :class="[sendAt ? '' : 'opacity-50']" />
              </a>
            </nice-tooltip>
          </li>
          <nice-popover
            placement="bottom"
            trigger="click"
            width="300"
            :show-arrow="false"
            :virtual-ref="$refs.sendAtPopoverOpenRef"
            virtual-triggering
          >
            <div class="py-3">
              <p class="font-medium text-md text-center mb-2">{{ $t("panes.messageForm.timeframe") }}</p>
              <button
                @click="sendAt = null"
                class="flex justify-between py-2 px-3 w-full standard-hover"
                :class="{ active: sendAt === null }"
              >
                {{ $t("panes.messageForm.now") }}
                <span></span>
              </button>
              <button
                v-for="(moment, i) in delayedMoments"
                :key="i"
                @click="sendAt = moment.value"
                class="flex justify-between py-2 px-3 w-full standard-hover"
                :class="{ active: sendAt === moment.value }"
              >
                <span>{{ moment.label }}</span>
                <span class="text-gray-700">{{ moment.dateString }}</span>
              </button>

              <div class="px-3 mt-3">
                <nice-date-picker
                  class="w-100"
                  v-model="sendAt"
                  type="datetime"
                  :placeholder="$t('panes.messageForm.ownTimeframe')"
                  format="dd.MM.yyyy HH:mm"
                  default-time="08:00:00"
                  align="right"
                />
              </div>
            </div>
          </nice-popover>
        </ul>
        <a
          href="#"
          data-toggle="error-popover"
          @click.prevent="onSubmit"
          :disabled="isSubmitDisabled"
          class="ml-2 pane-button"
          data-tagid="messageSubmit"
        >
          <span v-if="submitting" class="spinner spinner-sm mr-2"></span>
          {{
            massMail ? $t("panes.messageForm.massSend", { mails: massClients.length }) : $t("panes.messageForm.send")
          }}
        </a>
      </template>
    </nice-page-header>

    <spinner v-if="loading" />
    <div v-else class="app-panel-body scrollable">
      <form id="message-form" class="message-form" @submit.prevent="onSubmit">
        <section class="message-form-rows">
          <div v-if="hasSharedInboxes && fromVisible" class="message-form-row">
            <div class="message-form-group fluid-item">
              <label for="broker_id">{{ $t("panes.messageForm.from") }}</label>
              <div class="fake-select fluid">
                <select v-model="m.broker_id" class="form-control moz-styling">
                  <option v-for="item in sharedInboxes" :key="item.id" :value="item.id">
                    {{ item.name }} ({{ item.email }})
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div v-if="massClients.length > 0">
            <div class="message-form-row" data-tagid="messageTo">
              <div class="message-form-group fluid-item">
                <label for="to">{{ $t("panes.messageForm.to") }}</label>
                <div class="fluid">
                  <a href="#" ref="clientsNamePopoverRef" @click.prevent="fetchClientsDetails">
                    <strong>{{ massClients.length }}</strong>
                    {{ $t("panes.messageForm.selected") }}
                  </a>
                  <span
                    v-if="disabledContactClients?.length"
                    class="text-red-500"
                    v-html="$t('panes.messageForm.amountWithoutRight', { amount: disabledContactClients.length })"
                  />
                  <span>({{ $t("panes.messageForm.massMail") }})</span>
                  <nice-popover
                    placement="bottom"
                    width="320"
                    trigger="click"
                    transition="none"
                    :virtual-ref="$refs.clientsNamePopoverRef"
                    virtual-triggering
                  >
                    <div class="flex flex-col py-2 overflow-auto" style="max-height: 295px">
                      <spinner v-if="!massClientsDetails.length" />
                      <section
                        v-for="client in massClientsDetails"
                        :key="client.id"
                        class="px-3 py-2 border-b flex items-center"
                      >
                        <aside class="mr-2">
                          <a @click="removeClient(client)">
                            <fa-icon name="times" class="text-red-600 text-xl" />
                          </a>
                        </aside>
                        <main class="flex-grow">
                          <p class="text-sm font-semibold">{{ client.name }}</p>
                          <p class="text-xs">
                            <span v-if="client.email">{{ client.email }}</span>
                            <span v-else class="text-red-600">{{ $t("panes.messageForm.noEmail") }}</span>
                          </p>
                        </main>
                        <aside class="ml-2" v-if="!client.accept_contact">
                          <nice-tooltip :content="$t('panes.messageForm.noRight')" placement="right">
                            <fa-icon name="comment-alt-exclamation" class="text-yellow-600 text-xl" />
                          </nice-tooltip>
                        </aside>
                      </section>
                    </div>
                  </nice-popover>
                </div>
                <a
                  v-if="hasSharedInboxes"
                  href="#"
                  @click.prevent="fromVisible = !fromVisible"
                  :class="{ active: fromVisible }"
                  class="cc-btn link standard-hover"
                >
                  {{ $t("panes.messageForm.from") }}
                </a>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="message-form-row" data-tagid="messageTo">
              <div class="message-form-group fluid-item">
                <label for="to">{{ $t("panes.messageForm.to") }}</label>
                <div class="fluid">
                  <email-select
                    ref="emailSelect"
                    :autofocus="m.to.length <= 0"
                    :model-value="m.to"
                    @update:model-value="m.to = $event"
                    :departments="departments"
                    :emails="emails"
                    @update-recipients="selectedRecipients"
                  />
                </div>
                <a
                  v-if="hasSharedInboxes"
                  href="#"
                  @click.prevent="fromVisible = !fromVisible"
                  :class="{ active: fromVisible }"
                  class="cc-btn link standard-hover"
                >
                  {{ $t("panes.messageForm.from") }}
                </a>
                <a
                  href="#"
                  @click.prevent="ccVisible = !ccVisible"
                  :class="{ active: ccVisible || m.cc.length > 0 }"
                  class="cc-btn link standard-hover"
                >
                  {{ $t("panes.messageForm.cc") }}
                </a>
                <a
                  href="#"
                  @click.prevent="bccVisible = !bccVisible"
                  :class="{ active: bccVisible || m.bcc.length > 0 }"
                  class="cc-btn link standard-hover"
                >
                  {{ $t("panes.messageForm.bcc") }}
                </a>
                <a
                  href="#"
                  @click.prevent="toggleRightsRow"
                  :class="{
                    active: lockVisible || m.access_broker_ids.length > 0 || m.access_department_ids.length > 0,
                  }"
                  class="cc-btn link standard-hover"
                >
                  <fa-icon name="lock" />
                </a>
              </div>
            </div>

            <div v-if="ccVisible || m.cc.length > 0" class="message-form-row">
              <div class="message-form-group fluid-item">
                <label for="cc">{{ $t("panes.messageForm.cc") }}</label>
                <div class="fluid">
                  <email-select
                    :model-value="m.cc"
                    @update:model-value="m.cc = $event"
                    :departments="departments"
                    :emails="emails"
                  />
                </div>
              </div>
            </div>

            <div v-if="bccVisible || m.bcc.length > 0" class="message-form-row">
              <div class="message-form-group fluid-item">
                <label for="bcc">{{ $t("panes.messageForm.bcc") }}</label>
                <div class="fluid">
                  <email-select
                    :model-value="m.bcc"
                    @update:model-value="m.bcc = $event"
                    :departments="departments"
                    :emails="emails"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="message-form-row" data-tagid="messageSubject">
            <div class="message-form-group">
              <input
                type="text"
                name="message_subject"
                class="form-control message-form-subject"
                :placeholder="$t('panes.messageForm.subject')"
                v-model="m.subject"
              />
            </div>
          </div>

          <div class="message-form-row message-form-group">
            <db-select v-model="m.category" collection="messageCategories" />
            <nice-select
              v-if="teams && teams.length > 0"
              v-model="m.team"
              :options="teams"
              :placeholder="$t('panes.messageForm.team')"
              class="pl-3 border-l border-gray-200"
              @change="teamChanged"
            />
            <db-select
              v-if="$db.shopData.activityGroups.length > 0"
              v-model="m.group_ids"
              multiple
              class="pl-3 border-l border-gray-200"
              collection="formattedActivityGroups"
              label="activityGroups"
            />
          </div>

          <div
            v-if="lockVisible || m.access_broker_ids.length > 0 || m.access_department_ids.length > 0"
            class="message-form-row message-form-group"
          >
            <db-select
              collection="activeBrokers"
              v-model="m.access_broker_ids"
              :placeholder="$t('panes.messageForm.shareUsers')"
              multiple
              label-key="internalName"
            />

            <db-select
              v-if="departments.length > 0"
              v-model="m.access_department_ids"
              multiple
              :placeholder="$t('panes.messageForm.shareDepartments')"
              class="pl-3 border-l border-gray-200"
              collection="departments"
            />
          </div>

          <div v-if="massProperties.length > 0" class="message-form-row">
            <p class="message-form-p">
              {{ $t("panes.messageForm.selectedProperties", { amount: massProperties.length }) }}
              <span v-if="selectedProjects.length > 0">
                {{ $t("panes.messageForm.project") }}
                <strong>{{ selectedProjects.join(", ") }}</strong>
              </span>
            </p>
          </div>

          <div v-else class="message-form-row message-form-group">
            <property-select
              ref="propertySelect"
              v-model="m.properties"
              multiple
              variants
              @change-project="m.project_ids = $event"
            />

            <project-select multiple v-model="m.project_ids" class="border-l border-gray-200" />
          </div>

          <div class="message-form-row message-form-group">
            <div class="w-100" data-tagid="messageSnippetSelect">
              <snippet-select @change="snippetSelected" />
            </div>
            <div class="w-100 border-l border-gray-200">
              <variable-select @selected="insertVariable" />
            </div>
          </div>

          <section
            v-if="m.files.length > 0 || numUploading"
            class="message-form-row pt-2 flex flex-col"
            style="padding: 1rem 30px 0"
          >
            <header class="mb-2 flex text-sm" v-if="m.files.length > 1">
              <span class="font-medium">
                {{
                  $customFilters.pluralize(
                    m.files.length,
                    $t("panes.messageForm.attachment"),
                    $t("panes.messageForm.attachments")
                  )
                }}&nbsp;
              </span>
              {{ $t("panes.messageForm.totalSize", { length: totalFilesLength(m.files) }) }}
            </header>
            <div class="flex justify-between w-100 flex-wrap">
              <attachment
                v-for="a in showAllAttachments ? m.files : m.files.slice(0, 6)"
                :key="a.id"
                :attachment="a"
                :message="message"
                @delete="removeFile(a.id)"
                message-form
              />
              <span v-for="n in numUploading" :key="n" class="message-file">
                <span class="spinner"></span>
              </span>
            </div>
            <a v-if="m.files.length > 6" class="link text-sm mb-2" @click="showAllAttachments = !showAllAttachments">
              {{ showAllAttachments ? $t("panes.messageForm.showLess") : $t("panes.messageForm.showMore") }}
            </a>
          </section>
          <div class="message-form-row sticky-menu" v-loading="fetchingAiText">
            <froala v-if="froalaConfig" ref="emailBody" id="email-body" v-model:value="m.body" :config="froalaConfig" />
          </div>

          <div class="pt-3 pl-4">
            <nice-checkbox v-model="m.cancellation_policy_on" class="ml-3">
              {{ $t("panes.messageForm.policy") }}
            </nice-checkbox>
            <nice-checkbox v-if="$db.shopData.id == 1 && !massMail" v-model="m.send_as_pdf" class="ml-3">
              {{ $t("panes.messageForm.asPdf") }}
            </nice-checkbox>
            <nice-checkbox
              v-if="m.event_id"
              :model-value="!m.skip_attaching_ics_file"
              @update:model-value="m.skip_attaching_ics_file = !$event"
              class="ml-3"
            >
              {{ $t("panes.messageForm.withIcs") }}
            </nice-checkbox>
            <nice-checkbox
              v-if="massMail && $db.shopData.excludeNotAccepted && !$db.shopData.gdprCompliantMailSending"
              v-model="m.ignore_contact_acceptance"
              class="ml-3"
            >
              {{ $t("panes.messageForm.ignoreContactAcceptance") }}
            </nice-checkbox>
            <nice-tooltip
              html-allowed
              :content="
                $t('panes.messageForm.trackTooltip', {
                  link: `<a href=\'https://propstack.zendesk.com/hc/de/articles/18364231504285-Trackings-verstehen\' target=\'_blank\'>${$t(
                    'panes.messageForm.trackTooltipLinkText'
                  )}</a>`,
                })
              "
              v-if="!$db.shopData.emailReadTrackingDisabled"
            >
              <nice-checkbox
                :model-value="!m.disable_email_tracking"
                @update:model-value="m.disable_email_tracking = !$event"
                class="ml-3"
              >
                {{ $t("panes.messageForm.track") }}
              </nice-checkbox>
            </nice-tooltip>
          </div>

          <p v-if="selectedTemplate" class="pt-3 pl-4">
            {{ $t("panes.messageForm.selectedPreview", { temp: selectedTemplate.name }) }}
          </p>
        </section>

        <div class="message-footer">
          <div class="mt-2 mb-4">
            <p v-if="m.event_id" class="light fs-11">{{ $t("panes.messageForm.eventAccess") }}</p>
          </div>
        </div>
      </form>
    </div>
    <div class="message-drop-overlay">
      <div
        class="flex flex-col items-center px-32 py-16 bg-gray-100 rounded-3xl pointer-events-none"
        style="border: 3px dashed #ccc"
      >
        <fa-icon name="cloud-upload" class="mb-2 text-7xl text-gray-500 pointer-events-none" />
        <p class="text-gray-500 pointer-events-none">{{ $t("panes.messageForm.attachmentDropzone") }}</p>
      </div>
    </div>
    <div class="vModal" :class="{ active: preview.visible }">
      <header class="vModal-header">
        <div class="d-flex align-items-center">
          <button class="vModal-close" @click="preview.visible = false">×</button>
          <p class="vModal-title">
            {{ $t("panes.messageForm.subject") }}: {{ preview.subject || $t("panes.messageForm.subjectEmpty") }}
          </p>
        </div>
        <div class="mr-4">
          <a
            href="#"
            data-toggle="error-popover"
            @click.prevent="onSubmit"
            :disabled="isPaneButtonDisabled"
            class="pane-button"
          >
            <span v-if="submitting" class="spinner spinner-sm mr-2"></span>
            {{
              massMail ? $t("panes.messageForm.massSend", { mails: massClients.length }) : $t("panes.messageForm.send")
            }}
          </a>
        </div>
      </header>
      <div class="vModal-body">
        <div class="vModal-iframeWrapper">
          <iframe :srcdoc="preview.body" width="100%" height="100%" frameborder="0"></iframe>
        </div>
      </div>
    </div>
    <property-docs-modal
      :visible="docsModalVisible"
      @close="handleDocsModalClose"
      :ids="docsModalIds"
      :type="docsModalType"
      @submit="docsSelected"
    />
    <offmarket-popup
      v-model="showOffmarketPopup"
      :property-id="offmarketPropertyId"
      :lp-share-url="lpShareUrl"
      @trackOffmarket="handleTrackOffmarket"
      @close="close($event)"
      @submited="recordOffmarketUsage($event)"
      @error="recordOffmarketUsage(null, true)"
    />
    <input type="hidden" ref="dropzone" />
  </div>
</template>

<script lang="ts">
const EMAILS_KEY = "message-form-emails"

import { debounce } from "@/utils"
import combokeys from "@/utils/shortcuts"
import SaveStateMixin, { saveStateToLocalStorage, getSavedStateFromLocalStorage } from "@/mixins/save-state-mixin"
import EmailSelect from "@/components/message/EmailSelect.vue"
import SnippetSelect from "@/components/message/SnippetSelect.vue"
import Dropzone from "@/config/dropzone"
import PropertyDocsModal from "@/components/PropertyDocsModal.vue"
import OffmarketPopup from "@/components/OffmarketPopup.vue"
import {
  createHTMLEditorConfig,
  insertAtCursorPosition,
  monkeyPatchFroalaEditorHelpers,
  registerAiCommand,
  registerAiTranslateCommand,
} from "@/config/richtext-editor"
import Attachment from "@/components/Inbox/Attachment.vue"
import eventBus from "@/config/event-bus"
import LetterGenerator from "@/components/LetterGenerator.vue"
import VariableSelect from "@/components/VariableSelect.vue"
import { coerceBooleanAttribute } from "@/utils/booleanAttributeCoercion"
import FroalaEditor from "froala-editor"

const resourceFields = `
  id subject body brokerId
  accessBrokerIds accessDepartmentIds
  cancellationPolicyOn
  to { email }
  cc { email }
  bcc { email }
  eventId viewingId
  repliedMessageId forwardedMessageId
  messageCategoryId messageTemplateId
  propertyIds projectIds
  messageAttachments { id name url fileSize }
  policyId sendAsPdf skipAttachingIcsFile campaignClientIds campaignClientFilter
  groupIds ignoreContactAcceptance signature withExistingDeals
`

const formatReceiverEmails = (receivers, departments) => {
  return _.flatten(
    receivers.map(
      o =>
        _.get(
          departments.find(d => d.id === o),
          "emails"
        ) || o.trim()
    )
  ).filter(o => o)
}

const FROALA_STYLE_ATTR_REGEX = /\sfr-original-style=".*?"/g
const FROALA_CLASS_ATTR_REGEX = /\sfr-original-class=".*?"/g

const sanitizeFroalaHTML = html => {
  return html.replace(FROALA_STYLE_ATTR_REGEX, "").replace(FROALA_CLASS_ATTR_REGEX, "")
}

const SIGNATURE_REGEX_REPLACE = /<div.*class=.b-signature((?!email\-trail).)*<\/div>/gs
const SIGNATURE_REGEX_CONTENT = /<div.*b-signature.*?>([\s\S]*?)<\/div>/

function compareSignatures(html, signature) {
  const sanitizedHtml = sanitizeFroalaHTML(html)
  const matches = sanitizedHtml.match(SIGNATURE_REGEX_CONTENT)
  if (!matches?.length) {
    return false
  }

  const signatureContent = matches[1].replace("\n", "").trim() // remove newlines and whitespaces
  return signatureContent == signature
}

export default {
  emits: ["close", "submit"],
  props: ["contactEmails", "projectIds", "reply", "initialMessage", "clientIds", "massAction", "source"],
  mixins: [SaveStateMixin],
  components: {
    EmailSelect,
    SnippetSelect,
    PropertyDocsModal,
    Attachment,
    OffmarketPopup,
    LetterGenerator,
    VariableSelect,
  },
  data() {
    const broker = this.$db.broker
    const fallbackBrokerId = broker.availableInboxes.find(o => o.id == broker.primarySenderBrokerId)
      ? broker.primarySenderBrokerId
      : broker.availableInboxes.map(o => o.id)[0]

    const initialMessage = this.initialMessage || {}

    return {
      froalaConfig: undefined,
      froalaEditor: undefined as FroalaEditor | undefined,
      isSignatureModified: false,
      originalSignature: null,
      sendAt: null,
      policyId: initialMessage.policy_id,
      message_id: this.source ? this.source.draftId : null,
      fetchingAiText: false,
      disablePreview: false,
      messageTemplates: this.$db.shopData.messageTemplates || [],
      loading: true,
      odUploading: false,
      dbUploading: false,
      numUploading: 0,
      submitting: false,
      fromVisible: false,
      ccVisible: false,
      bccVisible: false,
      lockVisible: false,
      docsModalVisible: false,
      docsModalType: undefined,
      massClientsDetails: [],
      infinteScrollStop: false,
      infiniteScrollPage: 1,
      messageClientIds: [],
      showAllAttachments: false,
      message: null,
      showOffmarketPopup: false,
      seekers: undefined,
      fetchingSeekers: false,
      offmarketRecorded: false,
      lpShareUrl: null,
      disabledContactClients: [],
      editedMassClients: null,
      reFetchClientsDetails: false,
      emails: [],
      contact: {
        loading: false,
        data: [],
      },
      preview: {
        subject: "",
        body: "",
        visible: false,
        opening: false,
      },
      draft: {
        saving: false,
        lastSaved: undefined,
      },
      m: {
        forwarded_message_id: initialMessage.forwarded_message_id,
        replied_message_id: initialMessage.replied_message_id,
        reply: this.reply,
        broker_id: initialMessage.broker_id || fallbackBrokerId,
        to: initialMessage.to || [],
        cc: initialMessage.cc || [],
        bcc: initialMessage.bcc || [],
        subject: initialMessage.subject || "",
        category: initialMessage.message_category_id,
        team: initialMessage.team_id,
        body: initialMessage.body || "",
        template: initialMessage.message_template_id,
        properties: initialMessage.property_ids || [],
        project_ids: this.projectIds || initialMessage.project_ids || [],
        files: initialMessage.message_attachments || [],
        cancellation_policy_on: initialMessage.cancellation_policy_on,
        send_as_pdf: initialMessage.send_as_pdf,
        skip_attaching_ics_file: initialMessage.skip_attaching_ics_file,
        disable_email_tracking: initialMessage.disable_email_tracking,
        ignore_contact_acceptance: initialMessage.ignore_contact_acceptance,
        event_id: initialMessage.event_id,
        viewing_id: initialMessage.viewing_id,
        access_broker_ids: this.accessBrokerIds || [], // ?
        access_department_ids: this.accessDepartmentIds || [], // ?
        group_ids: [],
        campaignClientIds: null,
        campaignClientFilter: null,
        with_existing_deals: initialMessage.with_existing_deals,
      },
    }
  },

  watch: {
    massProperties: {
      handler(newValue) {
        this.m.project_ids = _.uniq(newValue.map(o => o.project_id).filter(o => !!o))
      },
      deep: true,
    },
    "m.body": {
      handler() {
        const editorHTML = this.froalaEditor?.el?.getHTML?.() // need to read it directly from the input html, bc froala adds some additional tags
        if (editorHTML && this.originalSignature) {
          this.isSignatureModified = !compareSignatures(editorHTML, this.originalSignature)
        }
      },
    },
    massClients: {
      handler() {
        this.saveDraft()
        this.massClientsDetails = []
        this.infinteScrollStop = false
        this.infiniteScrollPage = 1

        this.fetchDisabledContactClients()
        if (this.reFetchClientsDetails) this.fetchClientsDetails()
      },
      deep: true,
    },
    "preview.visible": {
      handler(newValue) {
        if (newValue) {
          this.registerPreviewBinds()
        } else {
          this.unbindPreviewBinds()
        }
      },
    },
  },

  methods: {
    handleDocsModalClose() {
      this.docsModalVisible = false
      this.docsModalType = undefined
    },
    handleContactAttachmentClick() {
      this.docsModalVisible = true
      this.docsModalType = "client"
    },
    handleProjectAttachmentClick() {
      this.docsModalVisible = true
      this.docsModalType = "project"
    },
    handlePropertyAttachmentClick() {
      this.docsModalVisible = true
      this.docsModalType = "property"
    },
    selectedRecipients(recipients) {
      this.messageClientIds = recipients.filter(r => r.type === "client").map(r => r.id)
      this.fetchDisabledContactClients(this.messageClientIds)
    },
    async loadSnippet(snippetId: number | string) {
      return await this.$axios.get(`/api/v1/snippets/${snippetId}`).then(({ data: snippet }) => {
        if (!this.froalaEditor) return
        if (snippet.message_template_id) {
          // For message templates, replace the entire content
          this.froalaEditor?.html.set(snippet.body)
        } else {
          insertAtCursorPosition(this.froalaEditor, snippet.body)
        }

        if (snippet.subject) {
          this.m.subject = snippet.subject
        }
        this.m.category = snippet.message_category_id || ""
        this.m.template = snippet.message_template_id || ""
        this.m.cancellation_policy_on = !!snippet.cancellation_policy_on
        this.m.files = this.m.files.concat(snippet.message_attachments)

        this.updateMessageBodyFromEditor()
      })
    },
    setMessage(message) {
      this.policyId = message.policyId
      this.message_id = message.id
      this.originalSignature = message.signature
      this.m.forwarded_message_id = message.forwardedMessageId
      this.m.replied_message_id = message.repliedMessageId
      if (message.brokerId) this.m.broker_id = message.brokerId
      this.m.to = (message.to || []).map(({ email }) => email)
      this.m.cc = (message.cc || []).map(({ email }) => email)
      this.m.bcc = (message.bcc || []).map(({ email }) => email)
      this.m.subject = message.subject || ""
      this.m.category = message.messageCategoryId
      this.m.team = message.teamId
      this.m.body = message.body || ""
      this.m.template = message.messageTemplateId
      this.m.properties = message.propertyIds || []
      this.m.project_ids = message.projectIds || []
      this.m.files = message.messageAttachments || []
      this.m.cancellation_policy_on = message.cancellationPolicyOn
      this.m.send_as_pdf = message.sendAsPdf
      this.m.skip_attaching_ics_file = message.skipAttachingIcsFile
      this.m.ignore_contact_acceptance = message.ignoreContactAcceptance
      this.m.disable_email_tracking = message.disableEmailTracking
      this.m.event_id = message.eventId
      this.m.viewing_id = message.viewingId
      this.m.campaignClientIds = message.campaignClientIds
      this.m.campaignClientFilter = message.campaignClientFilter
      this.m.group_ids = message.groupIds
      this.m.with_existing_deals = message.withExistingDeals
    },
    setWatchers() {
      this.$watch(
        "m",
        debounce(function () {
          this.saveDraft()
        }, 600),
        { deep: true }
      )

      this.$watch("selectedInbox", newValue => {
        const newSignature = newValue.signature ?? ""
        if (SIGNATURE_REGEX_REPLACE.test(this.m.body)) {
          this.m.body = this.m.body.replace(SIGNATURE_REGEX_REPLACE, `<div class="b-signature">${newSignature}</div>`)
        } else {
          this.m.body += `<div class="b-signature">${newSignature}</div>`
        }
        this.originalSignature = newValue
      })
    },
    launchOneDrivePicker() {
      const self = this
      self.odUploading = true
      OneDrive.open({
        clientId: "e7a2dba4-a5d4-4dbd-8add-57812bae5737",
        action: "download",
        multiSelect: true,
        advanced: { redirectUri: `${window.location.origin}/dashboard/msft` },
        success: async function (result) {
          const { value: files } = result
          const results = await Promise.all(
            files.map(async f => {
              const { data } = await self.$axios.post("/mailbox/attach_files", {
                file_url: f["@microsoft.graph.downloadUrl"],
              })
              return data
            })
          )
          self.m.files = self.m.files.concat(...results)
          self.odUploading = false
        },
        cancel: function (result) {
          self.odUploading = false
        },
        error: function (error) {
          App.alert(this.$t("panes.messageForm.downloadError"))
          self.odUploading = false
        },
      })
    },
    launchDropboxPicker() {
      const self = this
      self.dbUploading = true
      Dropbox.choose({
        linkType: "direct",
        success: async function (files) {
          self.numUploading += files.length
          const results = await Promise.all(
            files.map(async f => {
              const { data } = await self.$axios.post("/mailbox/attach_files", {
                file_url: f.link,
              })
              self.numUploading--
              return self.$util.objWithCamelCaseComp(data)
            })
          )
          self.m.files = self.m.files.concat(...results)
          self.dbUploading = false
        },
        cancel: function (result) {
          self.dbUploading = false
        },
        error: function (error) {
          console.log("error", error)
          App.alert(self.$t("inbox.attachments.uploadError"))
          self.dbUploading = false
        },
      })
    },

    togglePane() {
      $(".msg-quickview").toggleClass("minimized")
    },

    toggleRightsRow() {
      if (this.lockVisible) {
        this.lockVisible = false
        this.m.access_broker_ids = []
        this.m.access_department_ids = []
      } else {
        this.lockVisible = true
      }
    },

    onSubmit() {
      const body = this.m.body.toLowerCase()

      if (!this.m.subject && !this.massMail) {
        this.$warn(
          {
            title: this.$t("panes.messageForm.noSubjectConfirm.title"),
            desc: this.$t("panes.messageForm.noSubjectConfirm.text"),
            confirmButtonText: this.$t("panes.messageForm.noSubjectConfirm.submit"),
            redButton: true,
          },
          () => {
            this.submit()
          }
        )
      } else if (
        this.$tm("panes.messageForm.attachmentWarnings").some(w => body.includes(w)) &&
        this.m.files.length <= 0
      ) {
        this.$warn(
          {
            title: this.$t("panes.messageForm.noAttachmentConfirm.title"),
            desc: this.$t("panes.messageForm.noAttachmentConfirm.text"),
            confirmButtonText: this.$t("panes.messageForm.noAttachmentConfirm.submit"),
            redButton: true,
          },
          () => {
            this.submit()
          }
        )
      } else {
        if (this.massMail && this.massClients.length > 10) {
          this.$prompt(
            this.$t("panes.messageForm.sendForm.text", { length: this.massClients.length }),
            this.$t("panes.messageForm.sendForm.title"),
            {
              confirmButtonText: this.$t("panes.messageForm.sendForm.confirm"),
              cancelButtonText: this.$t("panes.messageForm.sendForm.cancel"),
              inputPlaceholder: this.$t("panes.messageForm.sendForm.placeholder"),
              inputPattern: new RegExp(`^${this.massClients.length}$`),
              inputErrorMessage: this.$t("panes.messageForm.sendForm.error"),
            }
          )
            .then(() => this.submit())
            .catch(() => {})
          return
        }
        this.submit()
      }
    },

    async validateBody() {
      const body = this.m.body.toLowerCase()
      const clientIds = this.massClients?.length ? this.massClients : this.messageClientIds
      const propertyIds = this.m.properties
      const validationNeeded =
        Array.isArray(clientIds) &&
        clientIds.length &&
        Array.isArray(propertyIds) &&
        propertyIds.length &&
        body.match(/einheit_button|einheit_exposee_button|einheit_statistik_button|dokument_exposee_url/)
      if (!validationNeeded) return true

      const { validationErrors } = await this.$api.mutation(
        "validateMessage",
        {
          variables: ["exposee"],
          propertyIds: propertyIds,
          clientIds: clientIds,
        },
        "validationErrors"
      )
      if (validationErrors.length === 0) return true
      if (validationErrors[0][0] === "exposee") {
        const duplicateClients = validationErrors[0][1]
        await this.$confirm(
          this.$t("panes.messageForm.duplicate.removeText", { count: duplicateClients.length }),
          this.$t("panes.messageForm.duplicate.warning", { count: duplicateClients.length }),
          {
            confirmButtonText: this.$t("panes.messageForm.duplicate.submitButton"),
            cancelButtonText: this.$t("panes.messageForm.duplicate.removeButton"),
          }
        )
          .then(() => this.submit(true))
          .catch(() => {
            if (this.massClients?.length) {
              this.m.campaignClientIds = this.massClients.filter(id => !duplicateClients.includes(id))
            } else if (this.messageClientIds?.length) {
              this.$refs.emailSelect?.select(item => item.type !== "client" || !duplicateClients.includes(item.id))
            }
          })
      }
      return false
    },

    async submit(skipValidation = false) {
      if (this.submitting && !skipValidation) return

      this.submitting = true
      if (!skipValidation && !(await this.validateBody())) {
        this.submitting = false
        return
      }

      try {
        const { data } = await this.$axios.post("/services/messages", this.params)
        if (data.errors) {
          App.alert(data.errors[0])
          return
        }
        saveStateToLocalStorage(EMAILS_KEY, _.uniq(this.emails.concat(this.m.to).concat(this.m.cc).concat(this.m.bcc)))

        // if (this.$db.shopData.offmarketEnabled && this.offmarketPropertyId && this.hasPropertyExposeeLink) {
        //   this.handleOffmarket()
        // } else {
        this.handleMessageSent()
        // }
      } catch (e) {
        this.$axios.handleError(e)
      } finally {
        this.submitting = false
      }
    },

    fetchDisabledContactClients(recipients?: unknown[]) {
      return null // return for now since this doesn't work for large data sets

      // FIXME: this is not working with massCLient.query!
      this.$axios
        .post("/api/v1/contacts/search", {
          filter_set:
            this.massClients && this.massClients.query
              ? this.massClients.query
              : { must: [{ terms: { id: recipients || this.massClients } }] },
        })
        .then(({ data }) => {
          this.disabledContactClients = data.data.filter(c => !c.accept_contact)
        })
    },

    handleMessageSent() {
      App.flashy(this.$t("panes.messageForm.success"))
      if (this.massMail) eventBus.$emit("quick-view-submit")
      this.close()
    },

    async openPreview() {
      this.preview.opening = true
      await this.saveDraft()

      this.$axios
        .post("/mailbox/messages/preview", { message_id: this.message_id }, { responseType: "json" })
        .then(({ data }) => {
          this.preview.opening = false
          if (data.error) {
            alert(this.$t("panes.messageForm.previewError"))
          } else {
            this.preview.subject = data.subject
            this.preview.body = data.body
            this.preview.visible = true
          }
        })
        .catch(xhr => {
          this.preview.opening = false
          console.log(xhr)
        })
    },

    updateMessageBodyFromEditor() {
      // explicitly set content body from editor, because it does not update the model before saving preview
      const body = this.froalaEditor.html.get()
      this.m.body = body
    },

    insertVariable(variable) {
      insertAtCursorPosition(this.froalaEditor, variable)
      this.updateMessageBodyFromEditor()
    },

    snippetSelected($event) {
      this.draft.saving = true
      const snippetId = $($event.target).val()
      if (!snippetId) return

      this.loadSnippet(snippetId).then(() => {
        $(".message-form .fr-element.fr-view").get(0).focus()
        $($event.target).val("")
      })
    },

    removeFile(id) {
      this.m.files = this.m.files.filter(o => o.id !== id)
    },

    triggerDropzone() {
      this.$refs.dropzone.click()
    },

    initDropzone() {
      try {
        const self = this
        new Dropzone(this.$refs.messageForm, {
          url: "/mailbox/attach_files",
          paramName: "file",
          clickable: this.$refs.dropzone,
          addRemoveLinks: true,
          dictRemoveFile: this.$t("panes.messageForm.dropzoneDelete"),
          timeout: 300000, // 3 Minuten
          dragenter(e) {
            if (window.draggingDocument) return
            $("body").addClass("drop-target")
          },
          dragleave(e) {
            const offsetX = 759
            if (offsetX < document.documentElement.clientWidth - e.clientX) $("body").removeClass("drop-target")
            if (e.clientY < 43) $("body").removeClass("drop-target")
          },
          drop(e) {
            $("body").removeClass("drop-target")
          },
          error(file, error) {
            App.alert(error.errors?.join(", ") || "Upload fehlgeschlagen.")
            console.error("ERROR", file, error)
          },
          init() {
            this.on("addedfile", file => self.numUploading++)
            this.on("canceled", function (file) {
              self.numUploading--
              alert(this.$t("panes.messageForm.dropzoneError"))
            })
            this.on("complete", file => self.numUploading--)
            this.on("success", (file, res) => {
              if (res.status === "error") return
              self.m.files = self.m.files.concat(self.$util.objWithCamelCaseComp(res))
            })
          },
        })
      } catch (e) {}
    },

    initFroalaConfig() {
      const tableEnabled = !this.m?.forwarded_message_id && !this.m?.replied_message_id && !this.source?.resendMessageId
      const that = this
      const froalaConfig = createHTMLEditorConfig({
        preToolbarButtons: this.$db.shopData.openaiConnected ? ["ai", "translate"] : [],
        pluginsEnabled: [
          "align",
          "codeView",
          "colors",
          "fontAwesome",
          "fontFamily",
          "fontSize",
          "image",
          "link",
          "lists",
          "url",
          "fullscreen",
          tableEnabled ? "table" : null,
        ],
        placeholderText: this.$t("panes.messageForm.message"),
        events: {
          initialized: function () {
            monkeyPatchFroalaEditorHelpers(this)
            that.froalaEditor = this
          },
          contentChanged: () => {
            setTimeout(() => (that.disablePreview = false), 1000)
          },
          keydown: () => {
            that.disablePreview = true
          },
        },
      })

      this.froalaConfig = froalaConfig
    },
    saveDraft() {
      if (this.submitting) return

      this.draft.saving = true
      return this.$axios
        .post("/mailbox/drafts", this.params)
        .then(({ data }) => {
          if (data.id) {
            this.message_id = data.id
          }
          this.draft.saving = false
          this.draft.lastSaved = moment()
          this.disablePreview = false
        })
        .catch(xhr => {
          this.draft.saving = false
          console.log(xhr)
        })
    },
    discardDraft() {
      if (this.message_id) {
        this.$axios.delete(`/mailbox/drafts/${this.message_id}`).catch(err => {
          console.log(err)
        })
        App.flashy(this.$t("panes.messageForm.draftDiscard"))
      }
      this.close()
    },

    handleTrackOffmarket(seekers = null) {
      if (
        !this.offmarketRecorded &&
        this.$db.shopData.offmarketEnabled &&
        this.m.properties.length &&
        this.hasPropertyExposeeLink
      ) {
        this.recordOffmarketUsage(seekers)
      }
    },

    close(seekers = null) {
      this.preview.visible = false
      this.showOffmarketPopup = false
      this.handleTrackOffmarket(seekers)
      this.$emit("close")
    },

    recordOffmarketUsage(seekers, error = false) {
      this.$api
        .mutation("recordOffmarketUsage", {
          seekers: seekers,
          offmarketTimeout: error,
          propertyIds: this.m.properties,
        })
        .then(() => {
          this.offmarketRecorded = true
        })
    },

    setProperties(ids) {
      this.m.properties = ids
      this.$nextTick(_ => {
        this.$refs.propertySelect && this.$refs.propertySelect.prefill()
      })
    },

    teamChanged() {
      const selected = this.teams?.find(t => t.id == this.m.team)

      if (selected?.mailSignature) {
        const hasSignature = SIGNATURE_REGEX_REPLACE.test(this.m.body)
        if (hasSignature) {
          this.m.body = this.m.body.replace(
            SIGNATURE_REGEX_REPLACE,
            `<div class="b-signature">${selected.mailSignature}</div>`
          )
        } else if (this.selectedInbox?.signature) {
          this.m.body = this.m.body.concat(`<div class="b-signature">${selected.mailSignature}</div>`)
        }
      }
    },

    async buildResource() {
      const params = _.pickBy(
        this.source
          ? {
              clientId: this.source.clientIds && this.source.clientIds.length === 1 && this.source.clientIds[0],
              propertyId: this.source.propertyIds && this.source.propertyIds.length === 1 && this.source.propertyIds[0],
              propertyIds: this.source.propertyIds && this.source.propertyIds.length > 1 && this.source.propertyIds,
              projectId: this.source.projectIds && this.source.projectIds.length === 1 && this.source.projectIds[0],
              clientIds:
                (Array.isArray(this.massClients) && this.massClients) ||
                (this.source.clientIds && this.source.clientIds.length > 1 && this.source.clientIds),
              taskId: this.source.taskId,
              suggestedEventId: this.source.suggestedEventId,
              snippetId: this.source.snippetId,
              viewingId: this.source.viewingId,
              draftId: this.source.draftId,
              followupMessageId: this.source.followupMessageId,
              replyMessageId: this.source.replyMessageId,
              replyAllMessageId: this.source.replyAllMessageId,
              forwardMessageId: this.source.forwardMessageId,
              resendMessageId: this.source.resendMessageId,
              originalMessage: this.source.originalMessage,
              email: this.source.email,
              withExistingDeals: this.source.withExistingDeals,
            }
          : {},
        val => !!val
      )
      this.loading = true
      const { message } = await this.$api.mutation("buildMessage", params, `message { ${resourceFields} }`)

      this.setMessage(message)
      this.loading = false
    },
    async docsSelected(documents) {
      this.docsModalVisible = false
      this.numUploading += documents.length
      const results = await Promise.all(
        documents.map(async doc => {
          const { data } = await this.$axios.post("/mailbox/attach_files", { document_id: doc.id })
          return data
        })
      )
      this.m.files = this.m.files.concat(...results.map(this.$util.objWithCamelCaseComp))
      this.numUploading -= documents.length
    },
    async attachLetter(url) {
      this.numUploading++
      try {
        const { data } = await this.$axios.post("/mailbox/attach_files", { file_url: url })
        this.m.files.push(this.$util.objWithCamelCaseComp(data))
        this.numUploading--
      } catch (e) {
        App.alert(e.message)
        this.numUploading--
      }
    },
    registerPreviewBinds() {
      combokeys.bind("esc", "preview", () => {
        this.preview.visible = false
      })
      combokeys.setScope("preview")
    },
    unbindPreviewBinds() {
      combokeys.deleteScope("preview")
      combokeys.unbind("esc", "preview")
      combokeys.setScope("quickview")
    },
    fetchClientsDetails() {
      this.$axios
        .post("/api/v1/contacts/search", {
          filter_set: { must: [{ terms: { id: this.massClients } }] },
          per: 20,
          page: this.infiniteScrollPage,
          include_children: true,
        })
        .then(({ data }) => {
          this.massClientsDetails = _.uniqBy(this.massClientsDetails.concat(data.data), "id")
          this.infinteScrollStop = 20 * this.infiniteScrollPage >= data.meta.total_count
          this.infiniteScrollPage++
          this.reFetchClientsDetails = false
        })
    },
    totalFilesLength(files) {
      let result = files.reduce((a, b) => a + (b.fileSize || 0), 0)
      return this.$customFilters.humanFileSize(result)
    },

    handleOffmarket() {
      this.$graphql(
        `query offmarketProperty { property(id: ${this.offmarketPropertyId}) { offmarketable lpShareUrl } }`
      ).then(({ property }) => {
        if (!property.offmarketable) return this.handleMessageSent()

        if (!this.$db.shopData.offmarketEnabled) {
          this.handleMessageSent()
        } else {
          this.lpShareUrl = property.lpShareUrl
          this.showOffmarketPopup = true
        }
      })
    },

    confirmLeave() {
      return window.confirm(this.$t("panes.messageForm.offmarket.leavePageWarning"))
    },

    confirmStayInDirtyForm() {
      return this.showOffmarketPopup && !this.confirmLeave()
    },

    beforeWindowUnload(e) {
      if (this.offmarketRecorded) return
      if (this.confirmStayInDirtyForm()) {
        // Cancel the event
        e.preventDefault()
        // Chrome requires returnValue to be set
        e.returnValue = ""
      }
    },
    removeClient(client) {
      this.editedMassClients = this.massClients.filter(c => c != client.id)
      this.reFetchClientsDetails = true
    },
  },

  computed: {
    isPaneButtonDisabled() {
      return coerceBooleanAttribute(this.draft.saving || this.submitting)
    },
    isSubmitDisabled() {
      return coerceBooleanAttribute(
        !!(
          (this.disabledContactClients.length > 0 && this.$db.shopData.contactAcceptanceRequestSnippetId) ||
          this.draft.saving ||
          this.submitting
        )
      )
    },
    massClients() {
      return (
        this.editedMassClients ||
        this.m.campaignClientIds ||
        this.m.campaignClientFilter ||
        this.massAction?.clientIds ||
        []
      )
    },
    massProperties() {
      return (this.massAction && this.massAction.propertyIds) || []
    },
    delayedMoments() {
      const todayNoon = moment().set({ hour: 13, minute: 0, second: 0, millisecond: 0 })
      const tomorrowMorning = moment().add(1, "d").set({ hour: 8, minute: 0, second: 0, millisecond: 0 })
      const mondayMorning = moment()
        .startOf("isoWeek")
        .add(1, "week")
        .set({ hour: 8, minute: 0, second: 0, millisecond: 0 })

      const arr = [
        {
          label: this.$t("panes.messageForm.delay.tmr"),
          dateString: tomorrowMorning.format("Do MMM, H:mm"),
          value: tomorrowMorning,
        },
        {
          label: this.$t("panes.messageForm.delay.monday"),
          dateString: mondayMorning.format("Do MMM, H:mm"),
          value: mondayMorning,
        },
      ]

      if (todayNoon > moment())
        arr.unshift({
          label: this.$t("panes.messageForm.delay.today"),
          dateString: todayNoon.format("Do MMM, H:mm"),
          value: todayNoon,
        })
      return arr
    },
    saveStateConfig() {
      return {
        cacheKey: "message-form",
        saveProperties: ["fromVisible", "ccVisible", "bccVisible", "lockVisible"],
      }
    },

    sharedInboxes() {
      return this.$db.broker.allAvailableMailboxes
    },

    hasSharedInboxes() {
      return this.sharedInboxes.length > 1
    },

    lastSavedFormatted() {
      return this.$customFilters.calendar(this.draft.lastSaved)
    },

    massMail() {
      return this.massClients.length > 0
    },
    docsModalIds() {
      switch (this.docsModalType) {
        case "client":
          return this.messageClientIds
        case "property":
          return this.m.properties
        case "project":
          return this.m.project_ids
        default:
          return undefined
      }
    },
    params() {
      return {
        message_id: this.message_id,
        message: {
          send_at: this.sendAt,
          broker_id: this.m.broker_id,
          forwarded_message_id: this.m.forwarded_message_id,
          replied_message_id: this.m.replied_message_id,
          mass_email: this.massMail,
          campaign_client_ids: this.massClients && Array.isArray(this.massClients) && this.massClients.filter(o => o),
          campaign_client_filter: this.massClients && !Array.isArray(this.massClients) && this.massClients,
          reply: this.m.reply,
          to: this.massMail ? [] : formatReceiverEmails(this.m.to, this.departments),
          cc: this.massMail ? [] : formatReceiverEmails(this.m.cc, this.departments),
          bcc: this.massMail ? [] : formatReceiverEmails(this.m.bcc, this.departments),
          subject: this.m.subject,
          body: sanitizeFroalaHTML(this.m.body),
          message_category_id: this.m.category,
          message_template_id: this.m.template,
          property_ids: _.uniq(this.massProperties.filter(o => !!o).concat(this.m.properties)),
          project_ids: this.m.project_ids,
          message_attachment_ids: this.m.files.map(o => o.id),
          cancellation_policy_on: this.m.cancellation_policy_on,
          send_as_pdf: this.m.send_as_pdf,
          skip_attaching_ics_file: this.m.skip_attaching_ics_file,
          disable_email_tracking: this.m.disable_email_tracking,
          ignore_contact_acceptance: this.m.ignore_contact_acceptance,
          event_id: this.m.event_id,
          viewing_id: this.m.viewing_id,
          policy_id: this.policyId,
          access_broker_ids: this.m.access_broker_ids,
          access_department_ids: this.m.access_department_ids,
          group_ids: this.m.group_ids,
          team_id: this.m.team,
          with_existing_deals: this.m.with_existing_deals,
        },
      }
    },

    selectedProjects() {
      return this.$db.shopData.projects.filter(o => this.m.project_ids.includes(o.id)).map(o => o.name)
    },
    selectedTemplate() {
      return this.messageTemplates.find(o => this.m.template === o.id)
    },
    selectedInbox() {
      return this.sharedInboxes.find(i => i.id == this.m.broker_id)
    },
    departments() {
      return this.$db.shopData.departments.map(d => ({
        ...d,
        emails: this.$db.shopData.brokers.filter(b => d.brokerIds.includes(b.id)).map(b => b.senderEmail),
      }))
    },
    teams() {
      if (this.$db.broker.accessTeamIds && this.$db.broker.accessTeamIds.length > 0) {
        return this.$db.shopData.teams.filter(t => this.$db.broker.accessTeamIds.includes(t.id))
      }
    },
    offmarketPropertyId() {
      if (this.m.properties.length === 1) return this.m.properties[0]
    },
    hasPropertyExposeeLink() {
      return (
        this.m.body?.includes("{{ einheit_exposee_button") ||
        this.m.body?.includes("{{ einheit_button") ||
        this.m.body?.includes("{{ einheit_statistik_button")
      )
    },
  },
  beforeUnmount() {
    window.removeEventListener("beforeunload", this.beforeWindowUnload)
  },
  created() {
    window.addEventListener("beforeunload", this.beforeWindowUnload)
    this.emails = getSavedStateFromLocalStorage(EMAILS_KEY) || []

    if (this.$db.shopData.openaiConnected)
      registerAiCommand(
        this,
        () => {
          this.fetchingAiText = true
        },
        text => {
          if (!text) {
            App.alert(this.$t("toolbar.ai.noText"))
          }
          this.fetchingAiText = false
        },
        e => {
          this.fetchingAiText = false
          return this.$axios.handleError(e)
        }
      )
    registerAiTranslateCommand(
      this,
      () => {
        this.fetchingAiText = true
      },
      text => {
        if (!text) {
          App.alert(this.$t("toolbar.ai.noText"))
        }
        this.fetchingAiText = false
      },
      e => {
        this.fetchingAiText = false
        this.$axios.handleError(e)
      }
    )
  },
  beforeRouteLeave(_to, _from, next) {
    // If the form is dirty and the user did not confirm leave,
    // prevent losing unsaved changes by canceling navigation
    if (this.confirmStayInDirtyForm()) {
      next(false)
    } else {
      if (!this.offmarketRecorded) this.recordOffmarketUsage(null)
      // Navigate to next view
      next()
    }
  },

  mounted() {
    if (this.m.body && this.m.body.length > 50) {
      this.saveDraft()
    }

    // FIX: fixes weird safari bug, can't set contact.data directly, have to do in mounted
    if (this.contactEmails) {
      this.contact.data = this.contactEmails.map(e => ({
        name: e,
        email: e,
      }))
    }

    if (this.massClients && this.massClients.length) {
      this.fetchDisabledContactClients()
    }

    this.buildResource().then(_ => {
      this.initDropzone()
      this.initFroalaConfig()
      this.setWatchers()
      // Initialize snippet
      const snippetId = this.source?.snippetId
      if (snippetId) {
        this.loadSnippet(snippetId)
      }
    })
  },
}
</script>

<style>
.app-panel > .dz-preview {
  display: none;
}
.custom-dialog > .el-dialog {
  background-color: #fff;
}

@-moz-document url-prefix() {
  .moz-styling {
    padding-top: 10px !important;
  }
}
.sticky-menu > .fr-box > .fr-toolbar {
  position: -webkit-sticky !important; /* Safari */
  position: sticky !important;
  z-index: 2;
}
</style>
