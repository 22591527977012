<template>
  <div v-if="avatarUrl" :title="user.name" class="avatarItem" :style="avatarStyle"></div>
  <div
    v-else-if="user"
    :title="user.name"
    class="avatarItem has-placeholder"
    :style="colorStyle"
    :data-before="user.initials || $customFilters.initials(user.name)"
  ></div>
</template>

<script lang="ts" setup>
import useCore from "@/plugins/use-core"
import { computed } from "vue"

export type AvatarProps = {
  broker?: Record<string, any>
  brokerId?: number
  size?: string
  forcePhoto?: boolean
}

const { broker, brokerId, size = "26px", forcePhoto = false } = defineProps<AvatarProps>()

const { db } = useCore()

const user = computed(() => {
  if (brokerId) return db.shopData.brokers.find(b => Number(b.id) === brokerId)
  return broker
})

const avatarUrl = computed(() => {
  const showAvatar = db.shopData.showBrokerAvatarsInCrm || forcePhoto
  const avatarUrl = user?.value?.avatar || user?.value?.avatarUrl || ""
  return showAvatar ? avatarUrl : ""
})

const avatarStyle = computed(() => ({
  width: size,
  height: size,
  "background-image": `url('${avatarUrl.value}')`,
}))

const colorStyle = computed(() => ({
  width: size,
  height: size,
  background: `${user?.value?.color}55`,
  "font-size": `${Number.parseInt(size) / 2.6}px`, // converting font size to a specific size using magic number 2.6
}))
</script>

<style>
.avatarItem {
  border-radius: 50%;
  background-size: 100%;
  position: relative;
  background-color: #99999955;
  width: 26px;
  height: 26px;
  font-size: 10px;
  overflow: hidden;
}

.avatarItem.has-placeholder::before {
  content: attr(data-before);
  font-size: inherit;
  font-weight: 500;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  text-align: center;
  margin: auto;
  height: 26px;
  line-height: 26px;
}
</style>
