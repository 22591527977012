<template>
  <el-popover v-bind="{ ...$attrs, ...$props }" ref="popoverRef">
    <template #reference>
      <slot name="reference"></slot>
    </template>
    <slot />
  </el-popover>
</template>

<script setup lang="ts">
import { ref } from "vue"

const popoverRef = ref()

const hide = () => {
  popoverRef.value?.hide()
}

defineExpose({ hide, popoverRef })
</script>
