<template>
  <form-dialog
    :title="$t('brokers.form.title')"
    :visible="visible"
    @close="close"
    width="660px"
    footerType="bigButton"
    :submitButtonTitle="$t('brokers.form.title')"
    :disabled="disabled"
    :submitFunc="submit"
  >
    <form-section>
      <form-row :title="$t('brokers.form.name')">
        <nice-input size="small" v-model="firstName" :placeholder="$t('brokers.form.firstName')" class="mr-2" />
        <nice-input size="small" v-model="lastName" :placeholder="$t('brokers.form.lastName')" />
      </form-row>
      <form-row :title="$t('brokers.form.email')">
        <nice-input size="small" v-model="email" type="email" :placeholder="$t('brokers.form.emailPh')" />
      </form-row>
      <form-row :title="$t('brokers.form.password')">
        <nice-input size="small" v-model="password" type="password" placeholder="********" class="mr-2" />
      </form-row>
      <form-row :title="$t('brokers.form.passwordConfirm')">
        <nice-input size="small" v-model="passwordConfirmation" type="password" placeholder="********" />
      </form-row>
      <template v-if="allLicencesInUse">
        <form-row :title="$t('brokers.form.brokerWithCosts')">
          <nice-checkbox v-model="brokerWithCosts" />
        </form-row>
        <form-row direction="column" class="-mt-3">
          <p class="break-normal text-sm text-gray-700">{{ $t("brokers.form.brokerWithCostsText") }}</p>
        </form-row>
      </template>
    </form-section>
  </form-dialog>
</template>

<script lang="ts">
import { defineComponent } from "vue"

const emailRegEx = new RegExp(/^((?!\.)[a-z0-9._%+-]+(?!\.)\w)@[a-z0-9-\.]+\.[a-z.]{1,20}(?!\.)\w$/i)

export default defineComponent({
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    allLicencesInUse: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      passwordConfirmation: "",
      brokerWithCosts: false,
    }
  },
  methods: {
    submit() {
      return this.$api
        .create("Broker", {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          password: this.password,
          passwordConfirmation: this.passwordConfirmation,
          brokerWithCosts: this.brokerWithCosts,
        })
        .then(data => {
          this.$emit("save", data)
          this.$emit("update:visible", false)
          this.reset()
          App.flashy(this.$t("brokers.form.success"))
        })
    },
    close() {
      this.$emit("update:visible", false)
      this.reset()
    },
    reset() {
      this.firstName = ""
      this.lastName = ""
      this.email = ""
      this.password = ""
      this.passwordConfirmation = ""
      this.brokerWithCosts = false
    },
  },
  computed: {
    disabled(): boolean {
      return (
        !this.lastName ||
        !emailRegEx.test(this.email) ||
        !this.password ||
        !this.passwordConfirmation ||
        this.password != this.passwordConfirmation ||
        (this.allLicencesInUse && !this.brokerWithCosts)
      )
    },
  },
})
</script>
