<script lang="ts" setup>
import { computed, defineProps } from "vue"
import { Client } from "./ClientSelect.vue"

const props = defineProps<{
  client: Client
}>()

const client = computed(() => props.client)
</script>

<template>
  <div :class="[{ archived: client.archived }, 'flex items-center py-2']">
    <fa-icon name="lock" class="mr-4" v-if="client.locked" />
    <div class="w-full flex justify-between">
      <div>
        <div class="font-semibold text-sm" :data-id="client.id">{{ client.name }}</div>
        <div class="light text-xs">{{ client.email }}</div>
      </div>
      <div v-if="client.relationShip" class="light text-xs">
        {{ $t(`clientSelect.suggestion.${client.relationShip}`) }}
      </div>
    </div>
  </div>
</template>
