<script lang="ts" setup>
import { onMounted, ref } from "vue"
import useCore from "@/plugins/use-core"
import eventBus from "@/config/event-bus"
import TodoActivity from "@/components/todo-activities/TodoActivity.vue"
import Note from "@/components/todo-activities/Note.vue"
import Todo from "@/components/todo-activities/Todo.vue"
import Cancelation from "@/components/todo-activities/Cancelation.vue"
import Message from "@/components/todo-activities/Message.vue"
import Property from "@/components/todo-activities/Property.vue"

const todoId = ref<number | null>(null)
const visible = ref(false)
const loading = ref(false)
const shopData = ref<any>({})
const items = ref<any[]>([])
const submitting = ref(false)

const { graphql, axios } = useCore()

const componentMap = {
  note: Note,
  todo: Todo,
  cancelation: Cancelation,
  message: Message,
  property: Property,
}

const fetchData = async () => {
  loading.value = true
  const { todo, shop } = await graphql(`{
        todo(id: ${todoId.value}) {
          category {
            todoActivities {
              id,
              label,
              activityType,
              mandatory,
              payload { snippetId brokerId noteTypeId todoTypeId dueDate eventTypeId propertyStatusId reservationReasonId reviewerIds }
            }
          },
          clients {
            name
          },
          properties {
            name,
            propertyStatus {
              name
            }
          }
        },
        shop {
          noteCategories { id, name },
          todoCategories { id, name },
          eventCategories { id, name },
          snippets { id, name },
          cancelationReasons { id, name },
          propertyStatuses { id, name },
        }
      }`)

  shopData.value = {
    ...shop,
    properties: todo.properties,
    clients: todo.clients,
  }

  items.value =
    todo.category &&
    todo.category.todoActivities.map(item => ({
      ...item,
      prefilledReservationReasonId: item.payload.reservationReasonId,
      prefilledNoteTypeId: item.payload.noteTypeId,
      prefilledSnippetId: item.payload.snippetId,
      execute: item.mandatory,
    }))

  loading.value = false
}

const quickviewMail = (taskId, snippetId) => {
  eventBus.$emit("quick-view", {
    type: "mail",
    mode: "edit",
    params: {
      source: {
        taskId,
        snippetId,
      },
    },
  })
}

const submit = () => {
  submitting.value = true
  const payload = items.value
    .filter(i => i.execute && !i.openForm)
    .map(item => ({
      id: item.id,
      payload: item.payload,
    }))

  axios
    .post(`/services/tasks/${todoId.value}/execute_todo_activities`, {
      todo_activities: payload,
    })
    .then(() => {
      const item = items.value.find(i => i.openForm)

      if (item) {
        quickviewMail(todoId.value, item.payload.snippetId)
        return
      }
    })
    .then(() => {
      window.TodosApp?.fetchData?.()
      visible.value = false
      App.flashy("Folge-Aktivitäten erfolgreich ausgeführt!")
    })
    .catch(axios.handleError)
    .then(() => (submitting.value = false))
}

const resetDialog = () => {
  todoId.value = null
  items.value = []
  visible.value = false
  window.TodosApp?.fetchData?.()
}

onMounted(() => {
  window.TaskApp = {}
  window.TaskApp.openTodoActivitesModal = todoIdParam => {
    todoId.value = todoIdParam
    fetchData()
    visible.value = true
  }
})
</script>

<template>
  <form-dialog
    title="Folge-Aktivitäten für Aufgabe"
    submitButtonTitle="Abschicken und ausführen"
    :visible="visible"
    :saving="submitting"
    @close="resetDialog"
    @submit="submit"
  >
    <div v-if="loading" class="d-flex align-items-center justify-content-center" :style="{ height: '150px' }">
      <div class="text-center">
        <spinner />
      </div>
    </div>
    <div v-else>
      <table class="mb-3">
        <tr v-if="shopData.clients && shopData.clients.length > 0">
          <td class="pr-3"><strong>Kontakt:</strong></td>
          <td>{{ shopData.clients.map(o => o.name).join(", ") }}</td>
        </tr>
        <tr v-if="shopData.properties && shopData.properties.length > 0">
          <td class="pr-3"><strong>Objekt:</strong></td>
          <td>{{ shopData.properties.map(o => o.name).join(", ") }}</td>
        </tr>
      </table>
      <div class="todoActivities">
        <todo-activity v-for="(item, idx) in items" :key="idx" :step="idx + 1" :ta="item">
          <component :is="componentMap[item.activityType]" :ta="item" :shop-data="shopData" />
        </todo-activity>
      </div>
    </div>
  </form-dialog>
</template>
