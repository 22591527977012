<template>
  <widget-container
    :widget="widget"
    :config="config"
    :loading="items.length == 0 && loading"
    :infinite-scroll="() => fetchData()"
    :infinite-scroll-disabled="loading || noMore"
  >
    <router-link
      v-for="item in groupedItems"
      :key="item.id"
      class="flex items-center rounded p-2 mx-1 text-black hover:bg-black/5"
      :to="
        item.type == 'broker'
          ? ''
          : $customFilters.clientPath(item.id, { clients_open_in_details: $db.broker.clientsOpenInDetails })
      "
    >
      <aside class="mr-2">
        <fa-icon name="birthday-cake" class="text-lg" />
      </aside>
      <main class="flex-grow pl-1 overflow-hidden">
        <p class="leading-snug whitespace-nowrap text-ellipsis" :title="item.name">{{ item.name }}</p>
      </main>
      <span v-if="item.phone" class="text-gray-600 text-sm ml-2 whitespace-nowrap">
        {{ item.phone }}
      </span>
      <nice-divider v-if="item.phone" direction="vertical" />
      <time class="text-gray-600 text-sm" style="min-width: 48px">
        {{ item.dob }}
      </time>
    </router-link>
    <div v-if="loading" class="popover-empty">
      <span class="spinner"></span>
    </div>
    <p v-if="!groupedItems.length" class="px-2 mx-1 text-sm text-gray-600 italic mt-2">
      {{ $t("widget.emptystates.birthdays") }}
    </p>
  </widget-container>
</template>

<script lang="ts">
import moment, { Moment } from "moment"
import _ from "lodash"
import WidgetContainer from "./WidgetContainer.vue"

type BirthdayWidgetQuery = {
  esQuery: any
  endOfEsIndex: Boolean
}

const per = 10

export default {
  components: { WidgetContainer },
  props: {
    widget: {} as any,
    config: {},
  },
  watch: {
    widget: {
      handler() {
        this.initialize()
        this.fetchData()
      },
    },
  },
  data() {
    return {
      items: [] as any[],
      loading: false,
      noMore: false,
      queries: [] as BirthdayWidgetQuery[],
    }
  },
  methods: {
    initialize() {
      const queries: BirthdayWidgetQuery[] = []
      // Range partially within last year
      if (this.rangeStartExceedsToPrevYear && !this.rangeEndExceedsToPrevYear) {
        const endOfPrevYear = moment(this.rangeStart).endOf("year").year(1972) // needed to not change referenced rangeStart value
        queries.push(this.buildQuery(moment(this.rangeStart).year(1972), endOfPrevYear))
      }

      // Range outside of current year
      if (
        (this.rangeStartExceedsToPrevYear && this.rangeEndExceedsToPrevYear) ||
        (this.rangeStartExceedsToNextYear && this.rangeEndExceedsToNextYear)
      ) {
        queries.push(this.buildQuery(moment(this.rangeStart).year(1972), moment(this.rangeEnd).year(1972)))
      } else {
        // Regular within-year range
        let rangeStartLimitedToCurrentYear =
          this.rangeStart.year() < 1972 ? moment(this.rangeStart).year(1972).startOf("year") : this.rangeStart
        let rangeEndLimitedToCurrentYear =
          this.rangeEnd.year() > 1972 ? moment(this.rangeEnd).year(1972).endOf("year") : this.rangeEnd
        queries.push(this.buildQuery(rangeStartLimitedToCurrentYear, rangeEndLimitedToCurrentYear))
      }

      // Range partially within next year
      if (this.rangeEndExceedsToNextYear && !this.rangeStartExceedsToNextYear) {
        const startOfNextYear = moment(this.rangeEnd).startOf("year").year(1972) // needed to not change referenced rangeEnd value
        queries.push(this.buildQuery(startOfNextYear, moment(this.rangeEnd).year(1972)))
      }

      this.items = []
      this.queries = queries
      this.noMore = false
    },
    buildQuery(startDate: Moment, endDate: Moment) {
      let filters: any = [
        {
          bool: {
            should: [{ term: { archived: false } }, { bool: { must_not: { exists: { field: "archived" } } } }],
          },
        },
      ]

      if (this.widget.brokerIds && this.widget.brokerIds.length) {
        filters.push({
          bool: {
            should: [
              { terms: { responsible_broker: this.widget.brokerIds } },
              { terms: { second_broker: this.widget.brokerIds } },
            ],
          },
        })
      }

      if (this.widget.categoryIds && this.widget.categoryIds.length) {
        filters.push({ terms: { status_id: this.widget.categoryIds } })
      }

      filters.push({
        bool: {
          should: [
            {
              range: {
                birthday: {
                  gte: startDate.format(),
                  lte: endDate.format(),
                },
              },
            },
          ],
        },
      })

      return {
        esQuery: {
          per,
          page: 1,
          sort_by: "birthday",
          order: "asc",
          filter_set: { must: filters },
          include_children: true,
          exclude_locked: true,
        },
        endOfEsIndex: false,
      }
    },
    async fetchData() {
      // Get next query in array that has not fully loaded, yet
      const query = this.queries.filter(query => !query.endOfEsIndex)?.[0]
      if (query) {
        if (this.loading) return
        this.loading = true
        const { data } = await this.$axios.post("/api/v1/contacts/search", query.esQuery)
        this.items = this.items.concat(data.data)
        this.loading = false
        query.endOfEsIndex = per * query.esQuery.page >= data.meta.total_count
        query.esQuery.page++
      } else {
        this.noMore = true
      }
    },
  },
  computed: {
    rangeStart() {
      return moment()
        .year(1972)
        .add(this.widget.daysStart || 0, "days")
        .startOf("day")
    },
    rangeEnd() {
      return moment().year(1972).add(this.widget.daysEnd, "days").endOf("day")
    },
    rangeStartExceedsToPrevYear() {
      return Boolean(this.widget.daysStart) && moment().add(this.widget.daysStart, "days").year() < moment().year()
    },
    rangeStartExceedsToNextYear() {
      return Boolean(this.widget.daysStart) && moment().add(this.widget.daysStart, "days").year() > moment().year()
    },
    rangeEndExceedsToPrevYear() {
      return Boolean(this.widget.daysEnd) && moment().add(this.widget.daysEnd, "days").year() < moment().year()
    },
    rangeEndExceedsToNextYear() {
      return Boolean(this.widget.daysEnd) && moment().add(this.widget.daysEnd, "days").year() > moment().year()
    },
    birthdayBrokers() {
      return this.$db.shopData.brokers
        .filter(b => {
          if (!b.dob || b.archived) return false
          const dob = moment(b.dob).year(1972)
          return dob >= this.rangeStart && (!Number.isInteger(this.widget.daysEnd) || dob <= this.rangeEnd)
        })
        .map(o => ({ ...o, type: "broker" }))
    },
    groupedItems() {
      const normalizedRangeStart = moment(this.rangeStart).year(1972)
      let sortedItems = this.items
        .concat(this.birthdayBrokers)
        // Set items before range start to normalized year plus 1, so they will be added at the end of the list instead of the front
        .map(item => ({
          ...item,
          ...{
            dob: moment(item.dob).year(1972).isAfter(normalizedRangeStart)
              ? moment(item.dob).year(1972).toDate()
              : moment(item.dob).year(1973).toDate(),
          },
        }))
        .sort((b1, b2) => (moment(b1.dob).isAfter(moment(b2.dob)) ? 1 : -1))
        .map(o => ({
          ...o,
          dob: moment(o.dob).format("DD. MMM"),
        }))
      return _.uniqBy(sortedItems, "id")
    },
  },
  mounted() {
    this.initialize()
    this.fetchData()
  },
}
</script>
