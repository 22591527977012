<template>
  <widget-container
    :widget="widget"
    :config="config"
    :loading="page == 1 && loading"
    :infinite-scroll="() => fetchData()"
    :infinite-scroll-disabled="loading || noMore"
  >
    <template v-slot:title>
      <div class="flex justify-between">
        <span>{{ total }} Followups</span>
        <router-link to="/contacts/followups" class="text-base">
          <nice-tooltip :content="$t('widget.link')">
            <fa-icon name="arrow-right" style="font-size: 1.2rem" />
          </nice-tooltip>
        </router-link>
      </div>
    </template>
    <router-link
      v-for="item in items"
      :key="item.id"
      class="flex items-center rounded p-2 mx-1 cursor-pointer text-black hover:bg-black/5"
      :to="$customFilters.clientPath(item.id, $db.broker)"
    >
      <aside class="mr-2">
        <followups-dropdown :clientId="item.id" :followupDate="item.followupDate" @followup="dateSet" />
      </aside>
      <main class="flex-grow pl-1 overflow-hidden">
        <p class="leading-snug whitespace-nowrap text-ellipsis" :title="item.name">{{ item.name }}</p>
      </main>
      <span v-if="item.phone" class="text-gray-600 text-sm ml-2 whitespace-nowrap">
        {{ item.phone }}
      </span>
    </router-link>
    <div v-if="loading" class="popover-empty">
      <span class="spinner"></span>
    </div>
    <p v-if="!items.length" class="px-2 mx-1 text-sm text-gray-600 italic mt-2">
      {{ $t("widget.emptystates.followups") }}
    </p>
  </widget-container>
</template>

<script>
import WidgetContainer from "./WidgetContainer.vue"
import FollowupsDropdown from "@/components/FollowupsDropdown.vue"

export default {
  components: { WidgetContainer, FollowupsDropdown },
  props: {
    widget: {},
    config: {},
  },
  watch: {
    widget: {
      handler() {
        this.fetchData()
      },
    },
  },
  data() {
    return {
      items: [],
      total: 0,
      loading: false,
      noMore: false,
      page: 1,
    }
  },
  methods: {
    async fetchData() {
      if (this.loading) return
      this.loading = true
      const { followupContacts } = await this.$graphql(
        `{
          followupContacts(per: 10, page: ${this.page}) {
            data { id name academicTitle phone company followupDate }
            meta { totalCount }
          }
        }`
      )

      this.items = this.items.concat(followupContacts.data.map(client => this.$util.objWithSnakeCaseComp(client)))
      this.total = followupContacts.meta.totalCount
      this.loading = false
      this.noMore = 10 * this.page >= this.total
      this.page++
    },
    dateSet(clientId) {
      this.items = this.items.filter(o => o.id !== clientId)
      this.total--
    },
  },
  mounted() {
    this.fetchData()
  },
}
</script>
