<template>
  <nav class="page-sidebar bg-gray-50">
    <div class="sidebar-menu">
      <sidebar-menu :menu="menu" :show-one-child="true" @pin-item="fetchPins" />
    </div>
  </nav>
</template>

<script>
import InboxBrokerSelect from "../Inbox/InboxBrokerSelect.vue"
import { useInboxMenu, getInboxMenu } from "@/features/inbox/use-inbox-menu.ts"
import { isEqual } from "lodash"

const lastVisitedPaths = {
  client: ({ id, db }) => `/contacts/clients/${id}` + (!db.broker.clientsOpenInDetails ? "/conversation" : ""),
  property: ({ id }) => `/portfolio/properties/${id}`,
  project: ({ id }) => `/portfolio/projects/${id}`,
}
const lastVisitedIcons = { client: "address-card", property: "home", project: "building" }

const renderLastVisited = ({ name, id, type }, db, sidebarPins) => ({
  href: lastVisitedPaths[type]({ id, db }),
  title: name,
  icon: `fal fa-${lastVisitedIcons[type]}`,
  lastVisited: true,
  sidebarPins: sidebarPins,
  id: id,
  type: type,
  class: "last-visited",
})

const getLastVisitedMenu = ({ lastVisited, db, sidebarPins }) => {
  return lastVisited ? lastVisited.map(e => renderLastVisited(e, db, sidebarPins)) : []
}

const getCrmMenu = ({ inboxBrokerIds, db, t, smartFolders, inboxCounters, sidebarPins, global, todosView }) => {
  const unreadMessagesCount = db.broker.unreadMessagesCount
  const uncompletedTodos = db.broker.uncompletedTodos
  const inboxes = db.broker.availableInboxes

  const inboxBrokerPath = global
    ? "?global=true"
    : inboxBrokerIds && (!Array.isArray(inboxBrokerIds) || inboxBrokerIds.length > 0)
    ? `?inboxBrokerId=${inboxBrokerIds}`
    : ""

  let inboxId
  if (!global && (!inboxBrokerIds || inboxBrokerIds.length < 2)) {
    inboxId = `${inboxBrokerIds?.length ? inboxBrokerIds[0] : db.broker.id}`
  }

  const todosPipelineId = sessionStorage.getItem("kanbanPipelineId") || db.shopData.taskPipelines[0]?.id
  const todosPath =
    todosView === "kanban" && todosPipelineId ? `/tasks/todos/kanban/${Number(todosPipelineId)}` : "/tasks/todos"

  const inboxMenuItems = getInboxMenu({
    db,
    t,
    inboxId,
    inboxBrokerPath,
    smartFolders,
    inboxCounters,
  })

  return [
    {
      header: true,
      title: t("sidebar.header"),
    },
    {
      href: "/dashboard",
      title: t("sidebar.dashboard"),
      icon: "fal fa-tachometer-alt-average",
      data: { dataTestid: "sidebar-item-dashboard" },
    },
    {
      href: "/mailbox/messages" + inboxBrokerPath,
      title: t("sidebar.mailbox"),
      icon: "fal fa-envelope",
      class: "keep-active-parent",
      data: { dataTestid: "sidebar-item-mailbox" },
      hiddenOnCollapse: false,
      child: (inboxes && inboxes.length > 1 ? [{ component: InboxBrokerSelect }] : []).concat(inboxMenuItems),
      badge: {
        attributes: {
          "data-count":
            inboxCounters.unreadInboxCount == undefined ? unreadMessagesCount : inboxCounters.unreadInboxCount,
        },
        class: "details",
      },
    },
    {
      href: "/contacts/clients",
      title: t("sidebar.clients"),
      icon: "fal fa-address-card",
      data: { dataTestid: "sidebar-item-clients" },
    },
    {
      href: "/calendar",
      title: t("sidebar.calendar"),
      icon: "fal fa-calendar-alt",
      data: { dataTestid: "sidebar-item-calendar" },
    },
    {
      href: "/opportunities",
      title: t("sidebar.opportunities"),
      icon: "fal fa-dollar-sign",
      data: { dataTestid: "sidebar-item-deals" },
    },
    ...(db.extensionEnabled("commissionsplit") && (db.broker.commissionBeneficiary || db.broker.admin)
      ? [
          {
            href: "/portfolio/commissions",
            title: t("sidebar.commissions"),
            icon: "fal fa-badge-percent",
          },
        ]
      : []),
    {
      href: "/portfolio/properties",
      title: t("sidebar.properties"),
      icon: "fal fa-home",
      data: { dataTestid: "sidebar-item-properties" },
    },
    ...(db.shopData.hiddenTabsInSidebar?.includes("projects")
      ? []
      : [
          {
            href: "/portfolio/projects",
            title: t("sidebar.projects"),
            icon: "fal fa-building",
            data: { dataTestid: "sidebar-item-projects" },
          },
        ]),
    ...(db.shopData.externalReports && db.shopData.externalReports.length
      ? [
          {
            href: "/external-reports",
            title: t("sidebar.reports"),
            icon: "fal fa-chart-bar",
          },
        ]
      : []),
    ...(db.shopData.hiddenTabsInSidebar?.includes("mailings")
      ? []
      : [
          {
            href: "/marketing/mailings",
            title: t("sidebar.mailings"),
            icon: "fal fa-bullhorn",
          },
        ]),

    {
      component: "SidebarSpacer",
    },
    {
      href: todosPath,
      title: t("sidebar.todos"),
      icon: "fal fa-check",
      data: { dataTestid: "sidebar-item-todos" },
      badge: {
        attributes: { "data-count": uncompletedTodos || 0, id: "todos-counter" },
        class: "details red",
      },
    },
    {
      href: "/contacts/feed",
      title: t("sidebar.activities"),
      icon: "fal fa-list-alt",
      data: { dataTestid: "sidebar-item-activities" },
    },
    {
      header: true,
      title: t("sidebar.lastSeen"),
    },
  ]
}

export default {
  data() {
    return {
      inboxCounters: {},
      sidebarPins: [],
      todosView: "todos",
    }
  },
  watch: {
    $route(to, from) {
      const { path } = this.$route
      if (path.startsWith("/tasks/todos")) {
        this.updateTodosCurrentView()
      }

      // this is the old behavior: we need to re-fetch the message counts after navigating, because we have no optimal solution yet
      // this might cause issues for large inboxes if they need to be recalculated (e.g. 1000s of outlook folders)
      // if this causes issues, we need to come up with a better solution to build up the inbox
      if (path.startsWith("/mailbox/messages")) {
        this.fetchMessagesCounter()
      }
    },
  },
  computed: {
    currentInbox() {
      return this.$db.broker.availableInboxes.find(i => i.id == this.inboxBrokerIds?.[0])
    },
    isGlobal() {
      return this.$route.query.global
    },
    smartFolders() {
      return !this.isGlobal && this.currentInbox ? this.currentInbox?.smartFolders || [] : []
    },
    menuInboxBrokerIds() {
      return this.inboxBrokerIds && (this.inboxBrokerIds.length > 1 || this.inboxBrokerIds[0] !== this.$db.broker.id)
        ? this.inboxBrokerIds
        : undefined
    },
    crmMenu() {
      return getCrmMenu({
        db: this.$db,
        inboxBrokerIds: this.menuInboxBrokerIds,
        inboxCounters: this.inboxCounters,
        smartFolders: this.smartFolders,
        global: this.isGlobal,
        todosView: this.todosView,
        t: this.$t,
      })
    },
    lastVisitedMenu() {
      return getLastVisitedMenu({
        lastVisited: this.lastVisited,
        db: this.$db,
        sidebarPins: this.sidebarPins,
      })
    },
    menu() {
      return this.crmMenu.concat(this.lastVisitedMenu)
    },
    inboxBrokerId() {
      return this.$route.query.inboxBrokerId
    },
    inboxBrokerIds() {
      return !!this.inboxBrokerId
        ? this.inboxBrokerId.split(",").filter(Boolean).map(Number)
        : [this.$db.broker.availableInboxes.map(o => o.id)[0]].filter(Boolean)
    },
    lastVisited() {
      const items = this.sidebarPins
        .concat(
          this.$store.state.lastVisited.items.filter(
            i => !this.sidebarPins.some(p => p.modelId == i.id && p.modelType == i.type)
          )
        )
        .slice(0, 5)
      return items.map(i => ({
        name: i.title || i.name,
        id: i.modelId ? Number(i.modelId) : i.id,
        type: i.modelType || i.type,
      }))
    },
  },
  mounted() {
    this.fetchPins()
    if (this.$route.path.startsWith("/mailbox/messages")) {
      this.fetchMessagesCounter()
    }
  },
  methods: {
    updateTodosCurrentView() {
      this.todosView = sessionStorage.getItem("todosView")
    },
    updateValues(data) {
      this.values = { ...this.values, ...data }
    },
    async fetchPins() {
      const { broker } = await this.$graphql(
        `{
          broker {
            sidebarPins { title modelId modelType }
          }
        }`
      )
      this.sidebarPins = broker.sidebarPins || []
    },
    async fetchMessagesCounter() {
      const { broker } = await this.$graphql(
        `{
          broker {
            messagesCounter(brokerIds: $brokerIds) {
              favoritesCount spamCount draftsCount assignedCount unreadInboxCount smartFolderCounts
            }
          }
        }`,
        {
          brokerIds: this.$route.query.global ? this.$db.broker.availableInboxes.map(o => o.id) : this.inboxBrokerIds,
        }
      )

      // as we refetch all the time the counters when the route changes, we need to compare the counters to avoid performance issues
      // because this state update triggers a rerender of the sidebar
      if (!isEqual(this.inboxCounters, broker.messagesCounter)) {
        this.inboxCounters = broker.messagesCounter
      }
    },
  },
}
</script>

<style>
.sidebar-menu {
  overflow-y: auto;
}
</style>
