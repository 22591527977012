import db from "@/config/db"
import { CRM_EXTENSIONS, SETTINGS_EXTENSIONS } from "@/config/extensions"
import routeState from "@/config/route-state"
import { RouteRecordRaw, createRouter, createWebHistory } from "vue-router"
import { routeGuard } from "./auth"
import TITLES from "./titles"

const BASE_PATH = "/app"
let requestedRoute: string = ""

const withFallback = (component: any) => {
  return component.catch(() => location.replace(`${BASE_PATH}${requestedRoute}`))
}

export const getRouterDetailExtensions = route => {
  return (
    CRM_EXTENSIONS[route]?.map(extensionName => ({
      path: extensionName,
      props: {
        extensionName,
      },
      component: () => withFallback(import("@/views/portfolio/Extension.vue")),
    })) ?? []
  )
}

export const getRouterSettingsExtensions = () => {
  return (Object.entries(SETTINGS_EXTENSIONS) as any).map(([extensionName, { route }]) => ({
    path: route,
    props: {
      extensionName,
    },
    component: () => withFallback(import("@/views/admin/extensions/Extension.vue")),
  }))
}

export const routes: RouteRecordRaw[] = [
  {
    path: "/login",
    component: () => withFallback(import("@/views/Login.vue")),
  },
  {
    path: "/reset-password/:id",
    component: () => withFallback(import("@/views/ResetPassword.vue")),
  },
  {
    path: "/account_setup",
    component: () => withFallback(import("@/views/AccountSetup.vue")),
  },
  {
    path: "/refresh",
    component: () => withFallback(import("@/components/layouts/DispatchAction.vue")),
    props: {
      dispatch: async (context: any) => {
        context.$db.reset()
        return true
      },
    },
  },
  {
    path: "",
    component: () => withFallback(import("@/components/layouts/CrmLayout.vue")),
    children: [
      {
        path: "/",
        component: () => withFallback(import("@/components/layouts/NullLayout.vue")),
      },
      {
        path: "/admin",
        component: () => withFallback(import("@/components/layouts/AdminLayout.vue")),
        children: [
          {
            path: "brokers",
            component: () => withFallback(import("@/components/layouts/NullLayout.vue")),
            children: [
              {
                path: "",
                component: () => withFallback(import("@/views/admin/Brokers.vue")),
              },
              {
                path: ":id",
                component: () => withFallback(import("@/views/admin/Broker.vue")),
              },
            ],
          },
          {
            path: "connections",
            name: "admin.connections",
            component: () => withFallback(import("@/views/admin/Connections.vue")),
            children: [
              {
                path: ":id",
                redirect: to => ({
                  name: "admin.connections",
                  query: { portals: to.params.id },
                }),
              },
            ],
          },
          {
            path: "connections/:id/overrides",
            name: "admin.connections.overrides",
            component: () => withFallback(import("@/views/admin/Connections/Overrides.vue")),
            props: true,
          },
          {
            path: "extensions",
            component: () => withFallback(import("@/components/layouts/NullLayout.vue")),
            children: [
              {
                path: "",
                component: () => withFallback(import("@/views/admin/IntegrationsPage.vue")),
              },
              {
                path: "mailchimp",
                component: () => withFallback(import("@/views/admin/extensions/Mailchimp.vue")),
              },
              {
                path: "brevo",
                component: () => withFallback(import("@/views/admin/extensions/Brevo.vue")),
              },
              {
                path: "docusign",
                component: () => withFallback(import("@/views/admin/extensions/Docusign.vue")),
              },
              {
                path: "paperless",
                component: () => withFallback(import("@/views/admin/extensions/Paperless.vue")),
              },
              {
                path: "placetel",
                component: () => withFallback(import("@/views/admin/extensions/Placetel.vue")),
              },
              {
                path: "openai",
                component: () => withFallback(import("@/views/admin/extensions/Openai.vue")),
              },
              {
                path: "energieausweis48",
                component: () => withFallback(import("@/views/admin/extensions/Energieausweis48.vue")),
              },
              {
                path: "bottimmo",
                component: () => withFallback(import("@/views/admin/extensions/Bottimmo.vue")),
              },
              {
                path: "commissionsplit",
                component: () => withFallback(import("@/views/admin/extensions/CommissionSplit.vue")),
              },
              {
                path: "local-hero",
                component: () => withFallback(import("@/views/admin/extensions/Localhero.vue")),
              },
              {
                path: "offmarket",
                component: () => withFallback(import("@/views/admin/extensions/Offmarket.vue")),
              },
              {
                path: "deepl",
                component: () => withFallback(import("@/views/admin/extensions/Deepl.vue")),
              },
              {
                path: "sipgate",
                component: () => withFallback(import("@/views/admin/extensions/Sipgate.vue")),
              },
              {
                path: "task-review",
                component: () => withFallback(import("@/views/admin/extensions/TaskReview.vue")),
              },
              {
                path: "frymo",
                component: () => withFallback(import("@/views/admin/extensions/Frymo.vue")),
              },
              {
                path: "sprengnetter",
                component: () => withFallback(import("@/views/admin/extensions/Sprengnetter.vue")),
              },
              {
                path: "areabutler",
                component: () => withFallback(import("@/views/admin/extensions/Areabutler.vue")),
              },
              {
                path: "wpimport",
                component: () => withFallback(import("@/views/admin/extensions/WPImport.vue")),
              },
              {
                path: "three-cx",
                component: () => withFallback(import("@/views/admin/extensions/3CX.vue")),
              },
              {
                path: "propnow",
                component: () => withFallback(import("@/views/admin/extensions/Propnow.vue")),
              },
              {
                path: "messagebird",
                component: () => withFallback(import("@/views/admin/Messagebird.vue")),
              },
              {
                path: "mailgun",
                component: () => withFallback(import("@/views/admin/extensions/Mailgun.vue")),
              },
              {
                path: "zapier",
                component: () => withFallback(import("@/views/admin/extensions/Zapier.vue")),
              },
              {
                path: "sample-contracts",
                component: () => withFallback(import("@/views/admin/extensions/SampleContracts.vue")),
              },
              {
                path: "justhome",
                component: () => withFallback(import("@/views/admin/extensions/Justhome.vue")),
              },
              {
                path: "findrive-tipster",
                component: () => withFallback(import("@/views/admin/extensions/FindriveTipster.vue")),
              },
              ...getRouterSettingsExtensions(),
              {
                path: ":id",
                component: () => withFallback(import("@/views/admin/IntegrationPage.vue")),
              },
            ],
          },
          {
            path: "api_keys",
            component: () => withFallback(import("@/views/admin/ApiKeys.vue")),
          },
          {
            path: "mail_accounts",
            component: () => withFallback(import("@/views/admin/MailAccounts.vue")),
          },
          {
            path: "franchise",
            component: () => withFallback(import("@/views/admin/Franchise.vue")),
          },
          {
            path: "teams",
            component: () => withFallback(import("@/views/admin/Teams.vue")),
          },
          {
            path: "departments",
            component: () => withFallback(import("@/views/admin/Departments.vue")),
          },
          {
            path: "settings/shop",
            component: () => withFallback(import("@/views/admin/Shop.vue")),
          },
          {
            path: "automations",
            component: () => withFallback(import("@/views/admin/Automations.vue")),
          },
          {
            path: "automations/:id",
            component: () => withFallback(import("@/views/admin/Automation.vue")),
          },
          {
            path: "rights",
            component: () => withFallback(import("@/views/admin/Roles.vue")),
          },
          {
            path: "settings/task_pipelines",
            component: () => withFallback(import("@/views/admin/TaskPipelines.vue")),
          },
          {
            path: "settings/tasks/:id?",
            component: () => withFallback(import("@/views/admin/ActivityTypes/Index.vue")),
          },
          {
            path: "shop/valuations",
            component: () => withFallback(import("@/views/admin/Valuations.vue")),
          },
          {
            path: "shop/valuations/sprengnetter",
            component: () => withFallback(import("@/views/admin/Sprengnetter.vue")),
          },
          {
            path: "locations",
            component: () => withFallback(import("@/views/admin/Locations.vue")),
          },
          {
            path: "letter_templates",
            component: () => withFallback(import("@/views/admin/LetterTemplates.vue")),
          },
          {
            path: "custom_fields/:id?",
            component: () => withFallback(import("@/views/admin/CustomFields.vue")),
          },
          {
            path: "settings/gdpr",
            component: () => withFallback(import("@/views/admin/Gdpr.vue")),
          },
          {
            path: "settings/lead_inquiries",
            component: () => withFallback(import("@/views/admin/LeadInquiries.vue")),
          },
          {
            path: "settings/messages",
            component: () => withFallback(import("@/views/admin/Messages.vue")),
          },
          {
            path: "settings/contacts",
            component: () => withFallback(import("@/views/admin/Contacts.vue")),
          },
          {
            path: "detail_view_groups",
            component: () => withFallback(import("@/views/admin/DetailViewGroups.vue")),
          },
          {
            path: "shop/micro_sites",
            component: () => withFallback(import("@/views/admin/MicroSites.vue")),
          },
          {
            path: "shop/lead_score",
            component: () => withFallback(import("@/views/admin/LeadScore.vue")),
          },
          {
            path: "shop/immo_valuation",
            component: () => withFallback(import("@/views/admin/ImmoValuation.vue")),
          },
          {
            path: "shop/tipster_portal",
            component: () => withFallback(import("@/views/admin/TipsterPortal.vue")),
          },
          {
            path: "settings/media",
            component: () => withFallback(import("@/views/admin/Media.vue")),
          },
          {
            path: "super_groups/:id?",
            component: () => withFallback(import("@/views/admin/Groups.vue")),
          },
          {
            path: "settings/properties",
            component: () => withFallback(import("@/views/admin/Properties.vue")),
          },
          {
            path: "settings/variables",
            component: () => withFallback(import("@/views/admin/Variables.vue")),
          },
          {
            path: "settings/text_snippets",
            component: () => withFallback(import("@/views/admin/TextSnippets.vue")),
          },
          {
            path: "settings/property_view",
            component: () => withFallback(import("@/views/admin/PropertyView.vue")),
          },
          {
            path: "settings/exposee_view",
            component: () => withFallback(import("@/views/admin/ExposeeView.vue")),
          },
          {
            path: "settings/project_view",
            component: () => withFallback(import("@/views/admin/ProjectView.vue")),
          },
          {
            path: "settings/custom_object_types",
            component: () => withFallback(import("@/views/admin/CustomObjectTypes.vue")),
          },
          {
            path: "settings/property_report",
            component: () => withFallback(import("@/views/admin/PropertyReport.vue")),
          },
          {
            path: "shop/pdf_exposees",
            component: () => withFallback(import("@/views/admin/PdfExposees.vue")),
          },
          {
            path: "settings/deals",
            component: () => withFallback(import("@/views/admin/Deals.vue")),
          },
          {
            path: "settings/deal_view",
            component: () => withFallback(import("@/views/admin/DealView.vue")),
          },
          {
            path: "settings/pipelines",
            component: () => withFallback(import("@/views/admin/Pipelines.vue")),
          },
          {
            path: "settings/reports",
            component: () => withFallback(import("@/views/admin/ExternalReports.vue")),
          },
          {
            path: "settings/saved_queries",
            component: () => withFallback(import("@/views/admin/SavedQueries.vue")),
          },
          {
            path: "settings/default_dashboard",
            component: () => withFallback(import("@/components/dashboard/DashboardConfiguration.vue")),
          },
          {
            path: "settings",
            component: () => withFallback(import("@/views/admin/General.vue")),
          },
          {
            path: "shopwindows",
            component: () => withFallback(import("@/views/admin/Shopwindow.vue")),
          },
          {
            path: "settings/events",
            component: () => withFallback(import("@/views/admin/Events.vue")),
          },
          {
            path: "booking_calendars",
            component: () => withFallback(import("@/views/admin/BookingCalendars.vue")),
          },
          {
            path: "snippet_categories",
            component: () => withFallback(import("@/views/admin/Snippets.vue")),
          },
          {
            path: "detail_views/saved_query",
            component: () => withFallback(import("@/views/admin/detail_views/SavedQuery.vue")),
          },
        ],
      },
      {
        path: "/export",
        component: () => withFallback(import("@/components/layouts/NullLayout.vue")),
        children: [
          {
            path: "cart",
            component: () => withFallback(import("@/views/export/Cart.vue")),
          },
        ],
      },
      {
        path: "",
        component: () => withFallback(import("@/components/layouts/MainLayout.vue")),
        children: [
          {
            path: "/dashboard",
            component: () => withFallback(import("@/views/Dashboard.vue")),
          },
          {
            path: "mailbox",
            component: () => withFallback(import("@/components/layouts/NullLayout.vue")),
            children: [
              // First route: Capture purely numeric paths as IDs "messages/123"
              {
                path: "messages/:id(\\d+)",
                component: () => withFallback(import("@/views/mailbox/Inbox.vue")),
                props: route => ({ id: Number(route.params.id) }),
              },
              // Second route: Handle type with optional ID, where type can contain multiple segments "messages/inbox/myfolder/123"
              {
                path: "messages/:type(.+?)/:id(\\d+)",
                component: () => withFallback(import("@/views/mailbox/Inbox.vue")),
                props: true,
              },
              // Third route: Handle just type with no ID, including multi-segment types "messages/inbox/myfolder"
              {
                path: "messages/:type(.*)?",
                component: () => withFallback(import("@/views/mailbox/Inbox.vue")),
                props: true,
              },
              {
                path: "/message/:id(\\d+)",
                component: () => withFallback(import("@/views/mailbox/Detail.vue")),
              },
              {
                path: "smart_folders/:smartFolder/:id?",
                component: () => withFallback(import("@/views/mailbox/Inbox.vue")),
              },
            ],
          },
          {
            path: "contacts/clients",
            component: () => withFallback(import("@/components/layouts/NullLayout.vue")),
            children: [
              {
                path: "",
                component: () => withFallback(import("@/views/contacts/Clients.vue")),
              },
              {
                path: "new",
                component: () => withFallback(import("@/views/contacts/ClientNew.vue")),
              },
              {
                path: ":id/copy",
                component: () => withFallback(import("@/views/contacts/ClientCopy.vue")),
              },
              {
                path: ":id",
                component: () => withFallback(import("@/views/contacts/Client.vue")),
                children: [
                  {
                    path: "",
                    component: () => withFallback(import("@/views/contacts/ClientForm.vue")),
                  },
                  {
                    path: "relations",
                    component: () => withFallback(import("@/views/contacts/ClientRelations.vue")),
                  },
                  {
                    path: "conversation",
                    component: () => withFallback(import("@/views/contacts/Activities.vue")),
                  },
                  {
                    path: "queries",
                    component: () => withFallback(import("@/components/layouts/NullLayout.vue")),
                    children: [
                      { path: "", component: () => withFallback(import("@/views/contacts/Queries.vue")) },
                      {
                        path: ":queryId",
                        component: () => withFallback(import("@/views/contacts/Query.vue")),
                      },
                      {
                        path: ":queryId/edit",
                        component: () => withFallback(import("@/components/layouts/DispatchAction.vue")),
                        props: {
                          pathBack: -2,
                          dispatch: async () => {
                            return true
                          },
                        },
                      },
                    ],
                  },
                  {
                    path: "properties",
                    component: () => withFallback(import("@/views/Deals.vue")),
                    props: {
                      context: "client",
                    },
                  },
                  {
                    path: "documents",
                    component: () => withFallback(import("@/views/portfolio/Media.vue")),
                  },
                  {
                    path: "audits",
                    component: () => withFallback(import("@/views/Audits.vue")),
                  },
                ],
              },
            ],
          },
          {
            path: "calendar",
            component: () => withFallback(import("@/views/Calendar.vue")),
          },
          {
            path: "opportunities",
            component: () => withFallback(import("@/components/layouts/NullLayout.vue")),
            props: {
              context: "overview",
            },
            children: [
              {
                path: "",
                component: () => withFallback(import("@/views/Deals.vue")),
              },
              {
                path: "kanban/:pipelineId?",
                component: () => withFallback(import("@/views/DealsKanban.vue")),
              },
              {
                path: ":id",
                component: () => withFallback(import("@/views/Deal.vue")),
                children: [
                  {
                    path: "",
                    component: () => withFallback(import("@/views/DealForm.vue")),
                  },
                  {
                    path: "conversation",
                    component: () => withFallback(import("@/views/contacts/Activities.vue")),
                  },
                  {
                    path: "documents",
                    component: () => withFallback(import("@/views/contacts/Documents.vue")),
                  },
                  {
                    path: "audits",
                    component: () => withFallback(import("@/views/Audits.vue")),
                  },
                ],
              },
            ],
          },
          {
            path: "portfolio/projects",
            component: () => withFallback(import("@/components/layouts/NullLayout.vue")),
            children: [
              { path: "", component: () => withFallback(import("@/views/portfolio/Projects.vue")) },
              {
                path: "new",
                component: () => withFallback(import("@/views/portfolio/ProjectNew.vue")),
              },
              {
                path: ":id/units/new",
                component: () => withFallback(import("@/views/portfolio/projects/UnitsNew.vue")),
              },
              {
                path: ":id",
                component: () => withFallback(import("@/views/portfolio/Project.vue")),
                children: [
                  {
                    path: "",
                    component: () => withFallback(import("@/views/portfolio/ProjectForm.vue")),
                  },
                  {
                    path: "units",
                    component: () => withFallback(import("@/views/portfolio/projects/Units.vue")),
                  },
                  {
                    path: "conversation",
                    component: () => withFallback(import("@/views/contacts/Activities.vue")),
                  },
                  {
                    path: "deals",
                    component: () => withFallback(import("@/views/Deals.vue")),
                    props: {
                      context: "project",
                    },
                  },
                  {
                    path: "clients",
                    component: () => withFallback(import("@/views/contacts/Clients.vue")),
                  },
                  {
                    path: "media",
                    component: () => withFallback(import("@/views/portfolio/Media.vue")),
                  },
                  {
                    path: "audits",
                    component: () => withFallback(import("@/views/Audits.vue")),
                  },
                ],
              },
            ],
          },
          {
            path: "immoscout24",
            component: () => withFallback(import("@/views/admin/extensions/Scout24.vue")),
          },
          {
            path: "external-reports",
            component: () => withFallback(import("@/views/ExternalReports.vue")),
          },
          {
            path: "marketing/mailings",
            component: () => withFallback(import("@/components/layouts/NullLayout.vue")),
            children: [
              { path: "", component: () => withFallback(import("@/views/marketing/Mailings.vue")) },
              { path: ":id", component: () => withFallback(import("@/views/marketing/MailingDetail.vue")) },
            ],
          },
          {
            path: "tasks/todos/kanban/:pipelineId",
            component: () => withFallback(import("@/views/TodosKanban.vue")),
          },
          {
            path: "tasks/todos/:id?",
            component: () => withFallback(import("@/views/tasks/Todos.vue")),
          },
          {
            path: "contacts/feed",
            component: () => withFallback(import("@/views/contacts/Activities.vue")),
          },
          {
            path: "contacts/followups",
            component: () => withFallback(import("@/views/contacts/Followups.vue")),
          },
          {
            path: "contacts/duplicates",
            component: () => withFallback(import("@/views/contacts/Duplicates.vue")),
          },
          {
            path: "contacts/trash",
            component: () => withFallback(import("@/views/contacts/Trash.vue")),
          },
          {
            path: "contacts/logs",
            component: () => withFallback(import("@/views/contacts/Logs.vue")),
          },
          {
            path: "/portfolio/commissions",
            component: () => withFallback(import("@/views/portfolio/Commissions.vue")),
          },
          {
            path: "portfolio/properties",
            component: () => withFallback(import("@/components/layouts/NullLayout.vue")),
            children: [
              {
                path: "",
                component: () => withFallback(import("@/views/portfolio/Properties.vue")),
              },
              {
                path: "new",
                component: () => withFallback(import("@/views/portfolio/PropertyNew.vue")),
              },
              {
                path: "trash",
                component: () => withFallback(import("@/views/portfolio/PropertyTrash.vue")),
              },
              {
                path: ":id/copy",
                component: () => withFallback(import("@/views/portfolio/PropertyCopy.vue")),
              },
              {
                path: ":id/new_variant",
                component: () => withFallback(import("@/views/portfolio/PropertyNewVariant.vue")),
              },
              {
                path: ":id",
                component: () => withFallback(import("@/views/portfolio/Property.vue")),
                children: [
                  {
                    path: "",
                    component: () => withFallback(import("@/views/portfolio/PropertyForm.vue")),
                  },
                  {
                    path: "conversation",
                    component: () => withFallback(import("@/views/contacts/Activities.vue")),
                  },
                  {
                    path: "active_clients",
                    component: () => withFallback(import("@/views/Deals.vue")),
                    props: {
                      context: "property",
                    },
                  },
                  {
                    path: "active_clients/kanban/:pipelineId",
                    component: () => withFallback(import("@/views/DealsKanban.vue")),
                  },
                  {
                    path: "suggested_clients",
                    component: () => withFallback(import("@/views/portfolio/SuggestedClients.vue")),
                  },
                  {
                    path: "extensions",
                    component: () => withFallback(import("@/views/portfolio/Extensions.vue")),
                  },
                  {
                    path: "media",
                    component: () => withFallback(import("@/views/portfolio/Media.vue")),
                  },
                  {
                    path: "valuations",
                    component: () => withFallback(import("@/views/portfolio/Valuation.vue")),
                  },
                  {
                    path: "propnow",
                    component: () => withFallback(import("@/views/portfolio/Propnow.vue")),
                  },
                  {
                    path: "justhome",
                    component: () => withFallback(import("@/views/portfolio/Justhome.vue")),
                    props: route => ({
                      propertyId: route.params.id,
                    }),
                  },
                  {
                    path: "findrive_tipster",
                    component: () => withFallback(import("@/views/portfolio/FindriveTipster.vue")),
                    props: route => ({
                      propertyId: route.params.id,
                    }),
                  },
                  {
                    path: "audits",
                    component: () => withFallback(import("@/views/Audits.vue")),
                  },
                  ...getRouterDetailExtensions("portfolio/properties/:id"),
                ],
              },
            ],
          },
          {
            path: "/partner-objekt/:id",
            component: () => withFallback(import("@/views/portfolio/PropertyShared.vue")),
          },
        ],
      },
      {
        path: "/portfolio/properties/:id/valuation",
        component: () => withFallback(import("@/views/portfolio/Valuation.vue")),
      },
      {
        path: "/404",
        component: () => withFallback(import("@/views/NotFound.vue")),
      },
    ],
  },
  {
    path: "/:path(.*)",
    redirect: "404",
  },
]

const router = createRouter({
  history: createWebHistory(BASE_PATH),
  routes,
})

const getCookies = () =>
  document.cookie
    .split(";")
    .map(entry => entry.trim().split("="))
    .reduce((prev, [key, value]) => ({ ...prev, [key]: value }), {})

const isAuthenticated = () => {
  const cookies = getCookies()
  return !!cookies["auth_token"]
}

export const validateRoute = (router, to) => {
  const isLogin = /^\/login|^\/reset-password/.test(to)
  if (!isLogin && !isAuthenticated()) {
    router.push("/login")
    return false
  } else if (isLogin && isAuthenticated()) {
    router.push("/")
    return false
  }
  if (db.broker && db.shopData && !routeGuard(to, db)) {
    App.alert("Du besitzt leider keine Berechtigung, um auf diese Seite zuzugreifen.")
    router.push("/").catch(() => {})
    return false
  }
  return true
}

export const initializeRoute = (router, to) => {
  if (to === "/" && db.shopData) {
    router.replace("/dashboard").catch(() => {})
    return false
  }
  return true
}

router.beforeEach((to, _from, next) => {
  if (!validateRoute(router, to.path)) return
  if (!initializeRoute(router, to.path)) return
  next()
})

router.beforeEach((to, from, next) => {
  requestedRoute = to.fullPath
  routeState.tick(to, from)

  next()
})

router.beforeEach((to, _from, next) => {
  if (TITLES[to.path]) document.title = TITLES[to.path]
  next()
})

const ALLOWED_QUERY_KEYS_TO_FORWARD = [
  "todo",
  "policy",
  "event",
  "query",
  "message",
  "sms",
  "letter",
  "task",
  "reason",
  "note",
  "inquiry",
  "reminder",
  "call",
  "cancelation",
  "mail",
  "locale",
  "decision",
  "translate",
  "publishing",
  "scoutPropertyId",
  "conversationId",
]

const EXCLUDE_ROUTES_FOR_FORWARD = ["/login", "/account_setup", "/refresh"]

router.beforeEach((to, from, next) => {
  if (
    !Object.keys(to.query).length &&
    to.path != from.path &&
    !EXCLUDE_ROUTES_FOR_FORWARD.includes(to.path) &&
    ALLOWED_QUERY_KEYS_TO_FORWARD.includes(Object.keys(from.query)[0])
  ) {
    next({ path: to.path, query: from.query })
  } else {
    next()
  }
})

export default router
