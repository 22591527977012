import config from "@/config/config"
import FroalaEditor from "froala-editor"

import "froala-editor/js/languages/de"
import "froala-editor/js/plugins/draggable.min"
import "froala-editor/js/plugins/font_family.min"
import "froala-editor/js/plugins/inline_style.min"
import "froala-editor/js/plugins/lists.min"
import "froala-editor/js/plugins/paragraph_format.min"
import "froala-editor/js/plugins/paragraph_style.min"
import "froala-editor/js/plugins/print.min"
import "froala-editor/js/plugins/quick_insert.min"
import "froala-editor/js/plugins/table.min"
import "froala-editor/js/plugins/url.min"
// import "froala-editor/js/plugins/special_characters.min"
import "froala-editor/js/plugins/align.min"
import "froala-editor/js/plugins/char_counter.min"
import "froala-editor/js/plugins/code_beautifier.min"
import "froala-editor/js/plugins/code_view.min"
import "froala-editor/js/plugins/colors.min"
import "froala-editor/js/plugins/emoticons.min"
import "froala-editor/js/plugins/entities.min"
import "froala-editor/js/plugins/file.min"
import "froala-editor/js/plugins/font_size.min"
import "froala-editor/js/plugins/forms.min"
import "froala-editor/js/plugins/fullscreen.min"
import "froala-editor/js/plugins/image.min"
import "froala-editor/js/plugins/image_manager.min"
import "froala-editor/js/plugins/line_breaker.min"
import "froala-editor/js/plugins/link.min"
import "froala-editor/js/plugins/quote.min"
import "froala-editor/js/plugins/save.min"
import "froala-editor/js/plugins/video.min"
import "froala-editor/js/plugins/word_paste.min"
import { ComponentPublicInstance } from "vue"

import VueFroala from "vue-froala-wysiwyg"

type RegisterCommandFn = (
  vueCtx: ComponentPublicInstance, // TODO: better inject API and necessary data directly instead of whole instance
  beforeCb: () => void,
  afterCb: (result: string) => void,
  catchCb: (e: Error) => void
) => void

const emptyFn = () => {}

export const registerAiCommand: RegisterCommandFn = (
  vueCtx,
  beforeCb = emptyFn,
  afterCb = emptyFn,
  catchCb = emptyFn
) => {
  FroalaEditor.DefineIcon("aiIcon", { NAME: "magic" })
  FroalaEditor.RegisterCommand("ai", {
    title: "AI",
    type: "dropdown",
    icon: "aiIcon",
    options: {
      reformulate: vueCtx.$t("toolbar.ai.reformulate"),
      rephrase: vueCtx.$t("toolbar.ai.rephrase"),
      summarize: vueCtx.$t("toolbar.ai.summarize"),
    },

    undo: true,
    focus: true, // Focus inside the editor before callback.
    refreshAfterCallback: true, // Refresh the button state after the callback.

    callback: function (cmd, val, params) {
      const selection = this.selection.get().toString()
      if (selection.length === 0) {
        alert("Bitte markiere den Text, den Du bearbeiten möchtest.")
        return
      }

      beforeCb()
      vueCtx.$api
        .mutation("reformulateUsingAi", { text: selection, action: val }, "text")
        .then(({ text }) => {
          if (text) this.html.insert(text)
          afterCb(text)
        })
        .catch(catchCb)
    },
  })
}

export const registerAiTranslateCommand: RegisterCommandFn = (
  vueCtx,
  beforeCb = emptyFn,
  afterCb = emptyFn,
  catchCb = emptyFn
) => {
  FroalaEditor.DefineIcon("translationIcon", { NAME: "language" })
  FroalaEditor.RegisterCommand("translate", {
    title: "Translate",
    type: "dropdown",
    icon: "translationIcon",
    options:
      vueCtx.$db.shopData.messageTranslatorSelections && vueCtx.$db.shopData.messageTranslatorSelections.length
        ? vueCtx.$db.shopData.messageTranslatorSelections.reduce(
            (a, v) => ({ ...a, [v]: vueCtx.$t(`admin.messages.translationOptions.${v}`) }),
            {}
          )
        : ["de", "en", "es"].reduce((a, v) => ({ ...a, [v]: vueCtx.$t(`admin.messages.translationOptions.${v}`) }), {}),

    undo: true,
    focus: true, // Focus inside the editor before callback.
    refreshAfterCallback: true, // Refresh the button state after the callback.
    callback: function (cmd, val, params) {
      const selection = this.selection.get().toString()
      if (selection.length === 0) {
        alert("Bitte markiere den Text, den Du übersetzen möchtest.")
        return
      }

      beforeCb()
      vueCtx.$api
        .mutation("reformulateUsingAi", { text: selection, action: "translate", targetLang: val }, "text")
        .then(({ text }) => {
          if (text) this.html.insert(text)
          afterCb(text)
        })
        .catch(catchCb)
    },
  })
}

const iOS = "ontouchstart" in window || (navigator as any).MaxTouchPoints > 0 || (navigator as any).msMaxTouchPoints > 0

const BASE_FROALA_OPTIONS = {
  key: config.FROALA_EDITOR_KEY,
  attribution: false,
  charCounterCount: false,
  iconsTemplate: "font_awesome_5l",
  shortcuts: false,
  inlineMode: false,
  language: "de",
  imageButtons: ["linkImage", "replaceImage", "removeImage"],
  imageTitle: false,
  imageResize: true,
  imageUploadParam: "image",
  imageUploadURL: "/services/images",
  // imageOutputSize: true
  imageDefaultAlign: "left",
  enter: (window as any).safari || iOS ? FroalaEditor.ENTER_P : FroalaEditor.ENTER_BR,
  useClasses: false,
  zIndex: 1001,
  htmlIgnoreCSSProperties: [
    "box-sizing",
    "border",
    "line-height",
    "clear",
    "border-style",
    "border-color",
    "border-width",
    "list-style",
    "list-style-type",
    "word-break",
    "word-wrap",
  ],
}

export const createDescriptionEditorConfig = options => {
  return {
    ...BASE_FROALA_OPTIONS,
    toolbarButtons: [
      "bold",
      "italic",
      "underline",
      "insertLink",
      "formatOL",
      "formatUL",
      "html",
      "clearFormatting",
      "fullscreen",
    ],
    enter: 0, // $.FE.ENTER_P
    useClasses: true,
    heightMax: 450,
    ...options,
  }
}

export const createHTMLEditorConfig = options => {
  return {
    ...BASE_FROALA_OPTIONS,
    toolbarButtons: [
      ...(options.preToolbarButtons || []),
      "bold",
      "italic",
      "underline",
      "strikeThrough",
      "insertLink",
      "align",
      "formatUL",
      "formatOL",
      "indent",
      "textColor",
      "fontSize",
      "insertImage",
      "insertTable",
      "html",
      "clearFormatting",
      "fullscreen",
    ],
    tableStyles: {
      "fr-dashed-borders": "Gestrichtelter Rahmen",
      "fr-alternate-rows": "Abwechselnde Zeilen",
      "fr-solid-borders": "Mit Rahmen",
    },
    ...options,
  }
}

export const initDescriptionEditorFor = (vueCtx, $selector, options = {} as any, initializedCallback = undefined) =>
  new FroalaEditor(
    $selector,
    createDescriptionEditorConfig({
      ...options,
      placeholderText: $selector.getAttribute("placeholder") || vueCtx.$t("richtextEditor.placeholder"),
    }),
    initializedCallback
  )

export const initHtmlEditorFor = (vueCtx, $selector, options = {} as any, initializedCallback = undefined) =>
  new FroalaEditor(
    $selector,
    createHTMLEditorConfig({
      ...options,
      placeholderText: $selector.getAttribute("placeholder") || vueCtx.$t("richtextEditor.placeholder"),
    }),
    initializedCallback
  )

export const modifiedSanitizeUrl = url => {
  // Remove on* attributes
  // sanitizedString = sanitizedString.replace(/\s*on\w+=(["'][^"']*["']|\S+)/gi, "")
  // Remove on* attributes, ensuring it matches only attributes starting with "on" (old variant did have false positives)
  url = url.replace(/\s+on\w+=(["'][^"']*["']|\S+)/gi, "")

  // Check for valid URL patterns
  if (/^(https?:|ftps?:|)\/\//i.test(url)) {
    return url
  }

  if (/^([A-Za-z]:(\\){1,2}|[A-Za-z]:((\\){1,2}[^\\]+)+)(\\)?$/i.test(url)) {
    return url
  }

  // Encode and replace specific patterns
  url = encodeURIComponent(url)
    .replace(/%23/g, "#")
    .replace(/%2F/g, "/")
    .replace(/%25/g, "%")
    .replace(/mailto%3A/gi, "mailto:")
    .replace(/file%3A/gi, "file:")
    .replace(/sms%3A/gi, "sms:")
    .replace(/tel%3A/gi, "tel:")
    .replace(/notes%3A/gi, "notes:")
    .replace(/data%3Aimage/gi, "data:image")
    .replace(/blob%3A/gi, "blob:")
    .replace(/%3A(\d)/gi, ":$1")
    .replace(/webkit-fake-url%3A/gi, "webkit-fake-url:")
    .replace(/%3F/g, "?")
    .replace(/%3D/g, "=")
    .replace(/%26/g, "&")
    .replace(/&amp;/g, "&")
    .replace(/%2C/g, ",")
    .replace(/%3B/g, ";")
    .replace(/%2B/g, "+")
    .replace(/%40/g, "@")
    .replace(/%5B/g, "[")
    .replace(/%5D/g, "]")
    .replace(/%7B/g, "{")
    .replace(/%7D/g, "}")
    .replace(/%[0-1]+[0-9]+|%[0-1]+[A-F]+/g, "")

  return url
}

export const monkeyPatchFroalaEditorHelpers = editor => {
  editor.helpers.sanitizeURL = modifiedSanitizeUrl
  return editor
}

export const insertAtCursorPosition = (
  editor: FroalaEditor,
  snippet: string,
  fallbackPosition: "prepend" | "append" = "prepend"
) => {
  try {
    // Focus the editor to ensure it's ready for operations
    editor.events.focus()

    // Check if we have a valid selection with actual text selected
    const selection = editor.selection.get()

    if (!selection || selection.rangeCount === 0 || !editor.selection.isCollapsed()) {
      // No valid selection or cursor position, place cursor at the beginning
      if (fallbackPosition === "append") {
        editor.selection.setAtEnd(editor.el)
      } else {
        // prepend is default when no position is given
        editor.selection.setAtStart(editor.el)
      }
    }
    // Now insert at the current cursor position
    editor.html.insert(snippet, true)
  } catch (error) {
    console.error("Froala insert error:", error)
    // Fallback - if all else fails, just set the content
    const content = editor?.html.get() || ""
    editor?.html.set(snippet + content)
  }
}

// install froala editor
export default {
  install(app) {
    app.use(VueFroala)
  },
}
