<template>
  <div ref="container" class="toastui-vue-calendar" />
</template>

<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount, watch } from "vue"
import Calendar, { Options } from "@toast-ui/calendar"
import "@toast-ui/calendar/dist/toastui-calendar.css"

const EventNames = [
  "selectDateTime",
  "beforeCreateEvent",
  "beforeUpdateEvent",
  "beforeDeleteEvent",
  "afterRenderEvent",
  "clickDayName",
  "clickEvent",
  "clickMoreEventsBtn",
  "clickTimezonesCollapseBtn",
] as const

type EventName = (typeof EventNames)[number]

type ViewType = Options["defaultView"]
type Calendars = Options["calendars"]

interface Props {
  view?: ViewType
  useFormPopup?: boolean
  useDetailPopup?: boolean
  isReadOnly?: boolean
  usageStatistics?: boolean
  eventFilter?: (event: any) => boolean
  week?: Record<string, any>
  month?: Record<string, any>
  gridSelection?: Record<string, any> | boolean
  timezone?: Record<string, any>
  theme?: Record<string, any>
  template?: Record<string, any>
  calendars?: Calendars
  events?: any[]
}

const props = withDefaults(defineProps<Props>(), {
  isReadOnly: false,
  usageStatistics: false,
})

type EmitEvents = {
  (event: EventName, ...args: any[]): void
}

const emit = defineEmits<EmitEvents>()

const container = ref<HTMLElement | null>(null)
const calendarInstance = ref<Calendar | null>(null)

const addEventListeners = () => {
  EventNames.forEach(eventName => {
    calendarInstance.value?.on(eventName, (...args) => {
      emit(eventName, ...args)
    })
  })
}

const getRootElement = () => container.value
const getInstance = () => calendarInstance.value

onMounted(() => {
  if (!container.value) return

  calendarInstance.value = new Calendar(container.value, {
    defaultView: props.view,
    useFormPopup: props.useFormPopup,
    useDetailPopup: props.useDetailPopup,
    isReadOnly: props.isReadOnly,
    usageStatistics: props.usageStatistics,
    eventFilter: props.eventFilter,
    week: props.week,
    month: props.month,
    gridSelection: {
      enableDblClick: true,
      enableClick: true,
    },
    timezone: props.timezone,
    theme: props.theme,
    template: props.template,
    calendars: props.calendars,
  })

  if (props.events) {
    calendarInstance.value.createEvents(props.events)
  }

  addEventListeners()
})

onBeforeUnmount(() => {
  calendarInstance.value?.off()
  calendarInstance.value?.destroy()
})

// Watchers
watch(
  () => props.view,
  value => {
    if (!value) return
    calendarInstance.value?.changeView(value)
  }
)

watch(
  () => props.useFormPopup,
  value => {
    calendarInstance.value?.setOptions({ useFormPopup: value })
  }
)

watch(
  () => props.useDetailPopup,
  value => {
    calendarInstance.value?.setOptions({ useDetailPopup: value })
  }
)

watch(
  () => props.isReadOnly,
  value => {
    calendarInstance.value?.setOptions({ isReadOnly: value })
  }
)

watch(
  () => props.eventFilter,
  value => {
    calendarInstance.value?.setOptions({ eventFilter: value })
  }
)

watch(
  () => props.week,
  value => {
    calendarInstance.value?.setOptions({ week: value })
  }
)

watch(
  () => props.month,
  value => {
    calendarInstance.value?.setOptions({ month: value })
  }
)

watch(
  () => props.gridSelection,
  value => {
    calendarInstance.value?.setOptions({ gridSelection: value })
  }
)

watch(
  () => props.timezone,
  value => {
    calendarInstance.value?.setOptions({ timezone: value })
  }
)

watch(
  () => props.theme,
  value => {
    calendarInstance.value?.setTheme(value)
  }
)

watch(
  () => props.template,
  value => {
    calendarInstance.value?.setOptions({ template: value })
  }
)

watch(
  () => props.calendars,
  value => {
    if (!value) return
    calendarInstance.value?.setCalendars(value)
  }
)

watch(
  () => props.events,
  value => {
    if (!value) return
    calendarInstance.value?.clear()
    calendarInstance.value?.createEvents(value)
  }
)

// Expose methods for external use
defineExpose({
  getRootElement,
  getInstance,
})
</script>

<style>
.calendar-wrapper {
  flex: 1;
  height: 100%;
  overflow: auto;
}

.toastui-calendar-day-name-item > span {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: initial;
}

.toastui-calendar-day-name__date {
  font-size: 18px;
}

.toastui-calendar-template-weekDayName {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.toastui-calendar-event-time .toastui-calendar-travel-time,
.toastui-calendar-event-time .toastui-calendar-event-time-content {
  overflow: hidden;
  padding: 0;
  font-size: 11px;
}

.toastui-calendar-day-name__name {
  font-weight: 400;
  font-size: 10px;
  order: -1;
  color: #777;
  margin: 3px 0;
  text-transform: uppercase;
}

.toastui-calendar-weekly-view {
  display: block;
  overflow: hidden;
  max-height: calc(100% - 4px);
}

.toastui-calendar-column .toastui-calendar-grid-selection,
.toastui-calendar-month .toastui-calendar-grid-selection {
  display: none;
}

.toastui-calendar-accumulated-grid-selection .toastui-calendar-grid-selection {
  display: none;
}

.toastui-calendar-grid-selection-label {
  display: none;
}

.toastui-calendar-event-time {
  overflow: visible;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
}

/* .toastui-calendar-template-goingDuration {}
.toastui-calendar-template-comingDuration {}
.toastui-calendar-travel-time {} */

.tui-custom-event-background {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.tui-full-calendar-weekly-view {
  z-index: 2;
  display: block;
  overflow: hidden;
  max-height: calc(100% - 4px);
}
.tui-full-calendar-monthly-view {
  font-size: 11px;
}

.toastui-calendar-weekday-event-title {
  font-weight: 300;
}

.toastui-calendar-day-view .tui-full-calendar-monthly-view {
  display: none;
}
.toastui-calendar-week-view .tui-full-calendar-monthly-view {
  display: none;
}
.toastui-calendar-month .tui-full-calendar-weekly-view {
  display: none;
}

.toastui-calendar-resize-handler-x {
  opacity: 0;
  bottom: 0;
  height: 3px;
}

.tui-full-calendar-weekly-view {
  .evt-description > .evt-initials {
    display: none;
  }
}

.toastui-calendar-layout .evt-description {
  text-wrap: balance;
}

.hide-weekday-event-times .tui-full-calendar-weekly-view {
  .tui-full-calendar-weekday-event-initials {
    display: none;
  }
  .evt-description > .evt-initials {
    display: inline-flex;
  }
}

.tui-full-calendar-weekly-view {
  .evt-description > .evt-initials {
    display: none;
  }
}

.toastui-calendar-see-more-container {
  .tui-full-calendar-weekday-event-initials {
    font-size: 10px;
  }
  .toastui-calendar-template-time {
    display: flex;
    .d-block {
      margin-left: 3px;
    }
  }
  .tui-full-calendar-weekly-view {
    display: none;
  }
}
</style>
