<template>
  <form-resource-dialog
    label="Widget"
    :submit-func="saveItem"
    @complete="$emit('widgetSaved', $event)"
    :editing="editingWidget"
    @update:editing="$emit('update:editingWidget', $event)"
    :autofocus="false"
  >
    <template v-slot:form="{ item }">
      <form-section>
        <form-row :title="$t('dashboard.widgetForm.category')" direction="column">
          <nice-select v-model="item.category" :options="categories" :fixed-position="true" />
        </form-row>
      </form-section>
      <form-section v-if="activeFiltersForCategory.length" :title="$t('dashboard.widgetForm.settings')">
        <form-row :title="$t('dashboard.widgetForm.newTitle')" :hint="$t('dashboard.widgetForm.newTitleHint')">
          <nice-input size="small" v-model="item.title" />
        </form-row>
        <form-row :title="$t('dashboard.widgetForm.newSubTitle')" v-if="item.category === 'charts'">
          <nice-input size="small" v-model="item.subTitle" />
        </form-row>
        <form-row
          :title="$t('dashboard.widgetForm.mailbox')"
          v-if="item.category === 'emails' && $db.broker.sharedInboxBrokerIds.length"
        >
          <db-select
            v-model="item.inboxBrokerIds"
            collection="unarchivedBrokers"
            :filter-func="b => allInboxBrokerIds.includes(b.id)"
            multiple
            :fixed-position="true"
          />
        </form-row>
        <form-row :title="$t('dashboard.widgetForm.source')" v-if="item.category === 'iframe'">
          <nice-input size="small" v-model="item.source" />
        </form-row>
        <form-row v-for="filter in activeFiltersForCategory" :key="filter.name" :title="filter.label">
          <color-picker v-if="filter.type === 'color'" v-model="item[filter.name]" :custom-colors="widgetColors" />
          <db-select
            key="filter-multiselect"
            v-else-if="filter.type === 'multiselect' && filter.dboptions"
            v-model="item[filter.name]"
            :collection="filter.dboptions"
            multiple
            :fixed-position="true"
          />
          <db-select
            key="filter-dropdown"
            v-else-if="filter.type === 'dropdown' && filter.dboptions"
            v-model="item[filter.name]"
            :collection="filter.dboptions"
            :fixed-position="true"
          />
          <nice-select
            key="filter-dropdown-2"
            v-else-if="filter.type === 'dropdown' && filter.options"
            v-model="item[filter.name]"
            :options="filter.options"
            :fixed-position="true"
          />
          <nice-checkbox v-else-if="filter.type == 'checkbox'" v-model="item[filter.name]" />
          <day-filter v-else-if="filter.type == 'days'" v-model="item[filter.name]" />
          <component v-else-if="filter.type == 'component'" :is="filter.component" v-model="item[filter.name]" />
        </form-row>
      </form-section>
    </template>
  </form-resource-dialog>
</template>

<script>
import BirthdaysWidget from "@/components/dashboard/BirthdaysWidget"
import EventsWidget from "@/components/dashboard/EventsWidget"
import FollowupsWidget from "@/components/dashboard/FollowupsWidget"
import TodosWidget from "@/components/dashboard/TodosWidget"
import AvailabilitiesWidget from "@/components/dashboard/AvailabilitiesWidget"
import ActivitiesWidget from "@/components/dashboard/ActivitiesWidget"
import EmailsWidget from "@/components/dashboard/EmailsWidget"
import ContactsWidget from "@/components/dashboard/ContactsWidget"
import PropertiesWidget from "@/components/dashboard/PropertiesWidget"
import ChartsWidget from "@/components/dashboard/ChartsWidget"
import IframeWidget from "@/components/dashboard/IframeWidget"
import DealsWidget from "@/components/dashboard/DealsWidget"
import DayFilter from "@/components/dashboard/DayFilter"
import EmailLocationDropdown from "@/components/dashboard/EmailLocationDropdown"
import ChartsWidgetConfig from "@/components/dashboard/ChartsWidgetConfig.vue"

export const formatWidget = widget => ({
  ...widget,
  i: widget.id,
  x: widget.positionX,
  y: widget.positionY,
  w: widget.width,
  h: widget.height,
  key: Date.now() - widget.id,
})

export default {
  props: {
    config: {
      type: Object,
      required: true,
    },
    editingWidget: {
      type: Object,
      required: true,
    },
  },
  methods: {
    saveItem() {
      if (this.editingWidget.id) {
        return this.$api.update(
          "DashboardWidget",
          this.editingWidget.id,
          _.omit(this.editingWidget, ["id", "x", "y", "w", "h", "i", "moved", "key"])
        )
      } else {
        return this.$api.create("DashboardWidget", this.editingWidget)
      }
    },
  },
  computed: {
    widgetColors() {
      return [
        "#fcdae1",
        "#f9d9d0",
        "#ffe8cc",
        "#fcf1d8",
        "#fbffa6",
        "#d5e8d2",
        "#e2f3f0",
        "#ccf2ff",
        "#d4dff9",
        "#e1e4f4",
        "#e7deec",
        "#ffe6ff",
      ]
    },
    allInboxBrokerIds() {
      return this.$db.broker.sharedInboxBrokerIds.concat([this.$db.broker.id])
    },
    categories() {
      return Object.keys(this.config)
        .filter(
          k =>
            k !== "charts" ||
            (this.$db.shopData.activeFeatures.includes("dashboard_charts") && this.$db.hasRight("rightToSeeMarketing"))
        )
        .map(key => ({
          id: key,
          name: this.config[key].label,
        }))
    },
    filtersForCategory() {
      if (!this.editingWidget || !this.editingWidget.category) return []
      return this.config[this.editingWidget.category].filters || []
    },
    activeFiltersForCategory() {
      return this.filtersForCategory
        .map(f => {
          if (f.depends && (!this.editingWidget[f.depends] || (f.cases && !f.cases[this.editingWidget[f.depends]])))
            return
          if (f.type === "dropdown") {
            let field = { ...f, ...(f.depends ? f.cases[this.editingWidget[f.depends]] : undefined) }
            const getOptions = f => f && ((f.dboptions && this.$db.shopData[f.dboptions]) || f.options)
            return { ...field, options: getOptions(field) }
          } else {
            return { ...f }
          }
        })
        .filter(Boolean)
    },
  },
  components: {
    BirthdaysWidget,
    EventsWidget,
    FollowupsWidget,
    AvailabilitiesWidget,
    ActivitiesWidget,
    TodosWidget,
    EmailsWidget,
    ContactsWidget,
    PropertiesWidget,
    ChartsWidget,
    IframeWidget,
    DealsWidget,
    DayFilter,
    EmailLocationDropdown,
    ChartsWidgetConfig,
  },
}
</script>
